import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {updateMaintenanceItem} from 'api/MaintenanceItemsApi';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import MaintenanceItemForm from './MaintenanceItemForm';

const DEFAULT_INSTANCE = {
    id: '',
    name: '',
    calendar_time_left: 'REMAINING TIME',
    calendar_time_in_operation: 'TIME ELAPSED',
    calendar_service_interval: 'SERVICE INTERVAL',
    calendar_time_value: 0,
    operation_time_left: 'REMAINING TIME',
    operation_time_in_operation: 'OPERATING HOURS',
    operation_service_interval: 'SERVICE INTERVAL',
    operation_time_value: 0,
};

export default function MaintenanceItemDetails({crane, customReport, maintenanceItem, onCloseClick}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isCalendarTime = customReport.params.includes('calendar_time_in_operation');
    const isOperationTime = customReport.params.includes('operation_time');
    const isDraft = customReport.status === 1;
    const isEditable = isAdmin();

    let validationRules = {
        name: Yup.string().required('Name is required'),
    };
    if (isCalendarTime) validationRules = {
        ...validationRules,
        calendar_time_left: Yup.string().required('Calendar time is required'),
        calendar_time_in_operation: Yup.string().required('Calendar time in operation is required'),
        calendar_service_interval: Yup.string().required('Recommended service interval is required'),
        calendar_time_value: Yup.number('It must be a number').positive().integer()
            .required('Recommended service interval is required')
            .min(1, 'Recommended service interval should be greater than 0')
    };
    if (isOperationTime) validationRules = {
        ...validationRules,
        operation_time_left: Yup.string().required('Operation time is required'),
        operation_time_in_operation: Yup.string().required('Time in operation is required'),
        operation_service_interval: Yup.string().required('Recommmended service interval is required'),
        operation_time_value: Yup.number('It must be a number').positive().integer()
            .required('Recommended service interval is required')
            .min(1, 'Recommended service interval should be greater than 0')
    };
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = maintenanceItem.hasOwnProperty(key) ? maintenanceItem[key] : value;
                }
                if (Array.isArray(maintenanceItem.labels)) {
                    maintenanceItem.labels.forEach(label => {
                        let prefix;
                        if (label.param === 'calendar_time_in_operation') prefix = 'calendar';
                        else if (label.param === 'operation_time') prefix = 'operation';
                        if (prefix) {
                            newData[`${prefix}_time_left`] = label.time_left;
                            newData[`${prefix}_time_in_operation`] = label.time_in_operation;
                            newData[`${prefix}_service_interval`] = label.service_interval;
                        }
                    });
                }
                if (Array.isArray(maintenanceItem.params)) {
                    maintenanceItem.params.forEach(param => {
                        if (param.param === 'calendar_time_in_operation') newData.calendar_time_value = param.value;
                        else if (param.param === 'operation_time') newData.operation_time_value = param.value;
                    });
                }
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            id: values.id,
            name: values.name,
            labels: [],
            params: [],
        };
        if (isCalendarTime) {
            payload.labels.push({
                param: 'calendar_time_in_operation',
                time_left: values.calendar_time_left,
                time_in_operation: values.calendar_time_in_operation,
                service_interval: values.calendar_service_interval,
            });
            payload.params.push({
                param: 'calendar_time_in_operation',
                value: parseInt(values.calendar_time_value),
            });
        }
        if (isOperationTime) {
            payload.labels.push({
                param: 'operation_time',
                time_left: values.operation_time_left,
                time_in_operation: values.operation_time_in_operation,
                service_interval: values.operation_service_interval,
            });
            payload.params.push({
                param: 'operation_time',
                value: parseInt(values.operation_time_value),
            });
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        updateMaintenanceItem(crane.id, customReport.id, maintenanceItem.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Maintenance item has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <>
            <div>
                <span className="text-card-title">{customReport.name} ({customReport.motion.name}) / Maintenance item</span>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
                {(props) => (
                    <MaintenanceItemForm
                      {...props}
                      isCalendarTime={isCalendarTime}
                      isOperationTime={isOperationTime}
                      isDraft={isDraft}
                      isEditable={isEditable}
                      isLoading={isLoading}
                      onCloseClick={onCloseClick}
                    />
                )}
            </Formik>
        </>
    );
}
