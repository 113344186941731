import ApiClient from './ApiClient';

const apiClient = ApiClient('/events');

export const getEventParams = (onSuccess, onError, onDone) => {
    apiClient.get('/params/motion/').then(onSuccess).catch(onError).finally(onDone);
};

export const createEvent = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateEvent = (craneId, eventId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${eventId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertEvent = (craneId, payload, onSuccess, onError, onDone) => {
    const eventId = payload.id;
    delete payload.id;
    if (eventId) updateEvent(craneId, eventId, payload, onSuccess, onError, onDone);
    else createEvent(craneId, payload, onSuccess, onError, onDone);
};

export const deleteEvent = (craneId, eventId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/${eventId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getEventData = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/data/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getEventNotifications = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/notifications/`, payload).then(onSuccess).catch(onError).finally(onDone);
};
