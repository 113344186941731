import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import moment from 'moment';
import {getMotionData} from 'api/MotionsApi';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {isAdmin} from 'utils/Auth';
import ServiceRecordDetails from './ServiceRecordDetails';
import ServiceRecordsList from './ServiceRecordsList';

export default function ServiceRecords({customReport, maintenanceItem, onCloseClick}) {
    const isMounted = useRef(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [serviceRecord, setServiceRecord] = useState({});
    const [motionOperationTime, setMotionOperationTime] = useState(0);
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        getMotionOperationTime(moment().format(DATE_FORMAT_ISO), setMotionOperationTime);
        return () => isMounted.current = false;
    }, []);

    const getMotionOperationTime = (service_date, handler) => {
        if (!service_date) return;
        const payload = {service_date: moment(service_date).utc().toISOString()};
        const onSuccess = response => {
            response.data.params.forEach(item => {
                if (item.param === 'operation_time') handler(item.value);
            });
        };
        getMotionData(
            customReport.crane_reference, customReport.motion_reference, payload, onSuccess
        );
    };

    const handleAddClick = () => {
        setServiceRecord({});
        setIsEditMode(true);
    };

    const handleBackClick = () => {
        if (isEditMode) {
            setIsEditMode(false);
            getMotionOperationTime();
        }
        else onCloseClick();
    }

    let pageContent;
    if (isEditMode) pageContent = (
        <ServiceRecordDetails
          customReport={customReport}
          maintenanceItem={maintenanceItem}
          serviceRecord={serviceRecord}
          motionOperationTime={motionOperationTime}
          getMotionOperationTime={getMotionOperationTime}
          onCloseClick={handleBackClick}
        />
    );
    else pageContent = (
        <ServiceRecordsList
          customReport={customReport}
          maintenanceItem={maintenanceItem}
        />
    );

    return (
        <>
            <Row>
                <Col>
                    <span className="text-title title-service-records">Service records for {maintenanceItem.name}</span>
                    {isEditable && !isEditMode && <button className="btn btn-add float-right" onClick={handleAddClick}>Add</button>}
                    <button className="btn btn-add float-right" onClick={handleBackClick}>Back</button>
                </Col>
            </Row>
            {pageContent}
        </>
    );
}
