export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_ISO = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DATETIME_SHORT_FORMAT = 'MM/DD/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const FAULTS = {
    BB: 'Drive Baseblock',
    BE1: 'Rollback Detected',
    BE2: 'No Current',
    BE3: 'Brake Release No Good',
    BE7: 'Brake Welded',
    BUS: 'Option Communication Error',
    CALL: 'Waiting for communications',
    CE: 'Modbus Communication Error',
    CF: 'Out of Control',
    DEV: 'Speed Deviation',
    'DEV-1': 'Speed Deviation',
    'DEV-2': 'CH2 Speed Deviation',
    E10: 'Option CPU Down',
    E15: 'Serial Communcation Error',
    EF: 'Simultaneous fwd/ rev commands',
    EF3: 'External Fault 3',
    EF4: 'External Fault 4',
    EF5: 'External Fault 5',
    EF6: 'External Fault 6',
    EF7: 'External Fault 7',
    EF8: 'External Fault 8',
    EFO: 'Communication Option External Fault',
    ERR: 'EEPROM R/W Error',
    FAN: 'Cooling Fan Error',
    FBL: 'PID Feedback Loss',
    GF: 'Ground Fault',
    LC: 'Load Check Error',
    LF: 'Output Phase Loss',
    OC: 'Over Current',
    OFO: 'Hardware fault',
    OH: 'Heatsink Overheat',
    OH1: 'Drive Overheat',
    OH2: 'Drive overheat pre-alarm',
    OH3: 'Motor Overheat',
    OH4: 'Motor Overheat 2',
    OL1: 'Motor Overload',
    OL2: 'Drive Overload',
    OL3: 'Overtorque 1',
    OL4: 'Overtorque 2',
    OL7: 'High Speed Slip Braking Overload',
    OPR: 'Operator Disconnect',
    OS: 'Overspeed',
    'OS-1': 'CH 1 Overspeed',
    'OS-2': 'CH2 Overspeed',
    OT1: 'Overtorque 1',
    OT2: 'Overtorque 2',
    OV: 'DC Bus Overvoltage',
    PF: 'Input Phase Loss',
    PGO: 'PG Disconnected',
    'PGO-1-H': 'PG CH 1 Open Hardware Detection',
    'PGO-1-S': 'PG CH 1 Open Software Detection',
    'PGO-2-H': 'PG CH2 Open Hardware Detection',
    'PGO-2-S': 'PG CH2 Open Software Detection',
    PUF: 'Fuse Blown',
    RH: 'Dynamic Braking Resistor Overheat',
    RR: 'Dynamic Braking Resistor',
    SNAP: 'Snapped Shaft',
    SVE: 'Zero Servo Fault',
    UL3: 'Undertorque 1 / Upper Limit 3',
    UL4: 'Undertorque 2',
    UT1: 'Undertorque 1',
    UT2: 'Undertorque 2',
    UV: 'DC Bus Undervoltage',
    UV1: 'DC Bus Undervoltage',
    UV2: 'CTL PS Undervoltage',
    UV3: 'MC Answerback',
};
export const MESSAGE_TIMEOUT = 5000;
export const hexRegex = '^(0[xX])?[A-Fa-f0-9]+$';
export const ipRegex = '^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])(\.(?!$)|$)){4}$';
export const DEFAULT_CUSTOMER_OPTION = {label: '-- Select customer --', value: ''};
export const DEFAULT_SITE_OPTION = {label: '-- Select site --', value: ''};
export const DEFAULT_LOCATION_OPTION = {label: '-- Select location --', value: ''};
export const DEFAULT_CRANE_SYSTEM_OPTION = {label: '-- Select crane system --', value: ''};
export const DEFAULT_ROLE_OPTION = {label: '-- Select role --', value: ''};
