import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import {upsertCrane} from 'api/CranesApi';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import {DEFAULT_INSTANCE, VALIDATION_SCHEMA} from 'views/cranes/CraneDetails'
import CraneConfigurationForm from './CraneConfigurationForm';

export default function CraneConfig({pageState, currentVersion, currentConfiguration, onDataUpdate}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [pageState, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.crane_details)) {
                const crane = currentConfiguration.crane_details;
                setInitialValues(oldData => {
                    const newData = {};
                    for (const [key, value] of Object.entries(oldData)) {
                        newData[key] = crane.hasOwnProperty(key) ? crane[key] : value;
                    }
                    newData.active = newData.active ? ['1'] : [];
                    return newData;
                });
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        payload.crane_comm_type = parseInt(payload.crane_comm_type);
        payload.active = Array.isArray(payload.active) && payload.active.length ? parseInt(payload.active[0]) === 1 : false;
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertCrane(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Crane configuration has been successfully saved');
            onDataUpdate();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <CraneConfigurationForm
                  {...props}
                  isEditable={isEditable}
                  isLoading={isLoading}
                />
            )}
        </Formik>
    );
}
