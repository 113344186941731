import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {
    createConfiguration,
    getConfigurationsDifference,
    getConfigurationsList,
    getConfigurationStatus,
    publishConfiguration
} from 'api/ConfigurationApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {configurationStatus} from 'constants/dataConstants';
import {DATETIME_SHORT_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';

export default function ConfigurationVersionsList({craneId, onConfigurationChange}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [versionsList, setVersionsList] = useState([]);
    const [versionsLimit, setVersionsLimit] = useState(3);
    const [differencesDialogBody, setDifferencesDialogBody] = useState(<></>);
    const [showDifferencesDialog, setShowDifferencesDialog] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [notes, setNotes] = useState('');
    const versionsCount = versionsList.length;
    const isEditable = isAdmin();
    const timeouts = {};

    useEffect(() => {
        isMounted.current = true;
        getVersionsList();
        return () => isMounted.current = false;
    }, [craneId, versionsLimit]);

    const clearAllTimeouts = () => {
        Object.values(timeouts).map(timeoutId => clearTimeout(timeoutId));
    };

    const getVersionsList = () => {
        if (!craneId) return;
        setIsLoading(true);
        clearAllTimeouts();
        const onSuccess = response => {
            if (!isMounted.current) return;
            setVersionsList([...response.data]);
            onConfigurationChange(response.data[0]);
            response.data.forEach(configuration => {
                if (configuration.status === 1) {
                    timeouts[configuration.version] = setTimeout(() => getVersionStatus(configuration.version), 1000);
                }
            });
        };
        const onDone = () => isMounted.current && setIsLoading(false);
        getConfigurationsList(craneId, versionsLimit, onSuccess, handleApiError, onDone);
    };

    const getVersionStatus = (version) => {
        const onSuccess = response => {
            if (!isMounted.current) return;
            if (response.data.status === 1) {
                timeouts[version] = setTimeout(() => getVersionStatus(version), 1000);
            }
            else {
                delete timeouts[version];
                setVersionsList(oldData => {
                    const newData = [];
                    oldData.forEach(configuration => {
                        if (configuration.version === version) newData.push({...configuration, status: response.data.status});
                        else newData.push({...configuration})
                    });
                    onConfigurationChange(newData[0]);
                    return newData;
                });
            }
        };
        getConfigurationStatus(craneId, version, onSuccess);
    };

    const handleAddVersionClick = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && getVersionsList();
        const onError = error => {
            handleApiError(error);
            isMounted.current && setIsLoading(false);
        };
        createConfiguration(craneId, onSuccess, onError);
    };

    const handleLoadOlderVersionsClick = () => {
        setVersionsLimit(versionsLimit => versionsLimit + 3);
    };

    const handleCompareVersionsClick = (versionOld, versionNew) => {
        setDifferencesDialogBody(<div className="text-center"><Spinner animation="border" /></div>);
        setShowDifferencesDialog(true);
        const onSuccess = response => {
            let differencesTable;
            if (response.data.diff.length === 0) differencesTable = <tr><td>No differences found</td></tr>;
            else differencesTable = response.data.diff.map((item, index) => <tr key={index}><td>{item}</td></tr>);
            setDifferencesDialogBody(
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">version {response.data.old.version} → {response.data.new.version}</th>
                            </tr>
                        </thead>
                        <tbody>{differencesTable}</tbody>
                    </Table>
                </>
            );
        }
        getConfigurationsDifference(craneId, versionOld, versionNew, onSuccess);
    };

    const handleNotesChange = (event) => setNotes(event.target.value);

    const handlePublishClick = () => {
        setNotes('');
        setShowPublishDialog(true);
    }

    const handleModalClosed = () => {
        setShowDifferencesDialog(false);
        setShowPublishDialog(false);
    };

    const handlePublishConfirmed = () => {
        handleModalClosed();
        setIsLoading(true);
        const onSuccess = response => isMounted.current && getVersionsList();
        const onError = error => {
            handleApiError(error);
            isMounted.current && setIsLoading(false);
        };
        const payload = {notes: notes};
        publishConfiguration(craneId, payload, onSuccess, onError);
    };

    let publishDialogBody = null;
    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan="6" className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else {
        publishDialogBody = (
            <Form.Group controlId="notes">
                <Form.Label>Notes</Form.Label>
                <Form.Control defaultValue={notes} maxLength="255" onChange={handleNotesChange} placeholder="Enter your notes to this configuration version" />
            </Form.Group>
        );
        tableContent = (
            <>
                {versionsList.map((item, index) => {
                    const createdAt = moment(item.created_at).utc(true).format(DATETIME_SHORT_FORMAT);
                    const compareToLatest = index > 0 ? (
                        <div
                          className="text-primary text-link"
                          onClick={() => handleCompareVersionsClick(item.version)}
                        >
                            Compare to latest version
                        </div>
                    ) : null;
                    const compareToPrevious = index > 0 && item.version > 1 ? (
                        <div
                          className="text-primary text-link"
                          onClick={() => handleCompareVersionsClick(item.version - 1, item.version)}
                        >
                            Compare to previous version
                        </div>
                    ) : null;
                    const loadOlderVersions = index === versionsCount - 1 && item.version > 1 ? (
                        <div className="text-primary text-link" onClick={handleLoadOlderVersionsClick}>Load older versions</div>
                    ) : null;
                    let action = '-';
                    if (index === 0) {
                        if (isEditable) {
                            if (item.status === 0) action = <Button variant="primary" onClick={handlePublishClick}>Publish</Button>;
                            else action = <Button variant="primary" onClick={handleAddVersionClick}>Add Version</Button>;
                        }
                    }
                    else {
                        action = <>{compareToLatest}{compareToPrevious}{loadOlderVersions}</>;
                    }
                    return (
                        <tr key={item.version}>
                            <td className="text-center">{item.version}</td>
                            <td className="text-center">{configurationStatus[item.status]}</td>
                            <td className="text-center">{action}</td>
                            <td className="text-center">{createdAt}</td>
                            <td className="text-center">{item.created_by && item.created_by.username}</td>
                            <td>{item.notes}</td>
                        </tr>
                    );
                })}
            </>
        );
    }

    return (
        <>
            <ModalDialog title="Configuration differences" body={differencesDialogBody} show={showDifferencesDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>Close</Button>
            </ModalDialog>
            <ModalDialog title="Publish current configuration" body={publishDialogBody} show={showPublishDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>Cancel</Button>
                <Button variant="primary" onClick={handlePublishConfirmed}>Publish</Button>
            </ModalDialog>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className="text-center">Version</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                        <th className="text-center">Created at</th>
                        <th className="text-center">Created by</th>
                        <th className="text-center">Notes</th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
