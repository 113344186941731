import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import moment from 'moment';
import {DATETIME_SHORT_FORMAT} from 'constants/uiConstants';

const colors = {
    motor_in_rpm_avg: '#ff883e',
    motor_in_rpm_max: '#7D3C98',
    run_time_cumulative: '#003082',
    run_time_hourly: '#004298',
    current_avg: '#1E8449',
    current_max: '#001234',
    loadcell_avg: '#F1DC23',
    loadcell_max: '#F1AF23',
    number_of_start_stop_cumulative: '#841E6B',
    number_of_start_stop_hourly: '#867E6B',
};
const axisId = {0: 'left', 1: 'right'};

export default function Chart({chartData, chartDate, parametersMap, selectedParameters}) {
    const isSecondParameter = selectedParameters.length > 1;
    const startTimestamp = moment(chartDate).valueOf();
    const endTimestamp = startTimestamp + (24 * 60 * 60 * 1000);
    const dataList = [];
    chartData.map(dataRow => {
        const data = {timestamp: Number(dataRow.timestamp)};
        selectedParameters.map(selectedParameter => {
            if (Object.hasOwn(dataRow.data, selectedParameter)) data[selectedParameter] = dataRow.data[selectedParameter];
        })
        dataList.push(data);
    })
    const xAxisInterval = [startTimestamp, startTimestamp + (8 * 60 * 60 * 1000), endTimestamp - (8 * 60 * 60 * 1000), endTimestamp];

    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart data={dataList} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  domain={[startTimestamp, endTimestamp]}
                  name="Time"
                  tickFormatter={unixTime => moment.unix(unixTime / 1000).format(DATETIME_SHORT_FORMAT)}
                  ticks={xAxisInterval}
                  type="number"
                  interval="preserveStartEnd"
                  allowDataOverflow={false}
                  axisLine={false}
                  tickLabelAngle={-90}
                />
                <YAxis
                  tick={{fill: colors[selectedParameters[0]]}}
                  yAxisId="left"
                  label={{value: parametersMap[selectedParameters[0]], angle: -90, position: "insideLeft"}}
                />
                {isSecondParameter &&
                    <YAxis
                      tick={{fill: colors[selectedParameters[1]]}}
                      yAxisId="right"
                      orientation="right"
                      label={{value: parametersMap[selectedParameters[1]], angle: -270, position: "insideRight"}}
                    />}
                <Tooltip labelFormatter={unixTime => moment.unix(unixTime/1000).format(DATETIME_SHORT_FORMAT)} />
                <Legend verticalAlign="top" height={50} />
                {selectedParameters.map((parameter, index) => {
                    let dispKey = parametersMap[parameter];
                    return (
                        <Line
                          yAxisId={axisId[index % 2]}
                          key={parameter}
                          name={dispKey}
                          type="monotone"
                          dataKey={parameter}
                          stroke={colors[parameter]}
                          fill={colors[parameter]}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};
