import React from 'react';
import {Form} from 'react-bootstrap'
import {useField} from 'formik';

export default function HiddenInput({...props}) {
    const [field, meta] = useField(props);
    const inputId = props.id || `input_${props.name}`;
    return (
        <Form.Control {...field} {...props} type="hidden" id={inputId} value={field.value || ''} />
    );
}
