import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {getDataFromLocalStorage, saveDataToLocalStorage} from 'utils/Ui';
import EventTemplatesList from './EventTemplatesList';
import EventTemplateDetails from './EventTemplateDetails';

export default function EventTemplates({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [eventTemplate, setEventTemplate] = useState({});

    let reportTemplate = location.state && location.state.reportTemplate;
    if (reportTemplate) saveDataToLocalStorage('reportTemplate', reportTemplate);
    else reportTemplate = getDataFromLocalStorage('reportTemplate');

    let maintenanceItemTemplate = location.state && location.state.maintenanceItemTemplate;
    if (maintenanceItemTemplate) saveDataToLocalStorage('maintenanceItemTemplate', maintenanceItemTemplate);
    else maintenanceItemTemplate = getDataFromLocalStorage('maintenanceItemTemplate');

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/report-templates');
        setPageTitle(`${reportTemplate.name} / ${maintenanceItemTemplate.name}`);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setEventTemplate({});
        setIsEditMode(true);
    };

    const handleEditClick = (eventTemplate) => {
        setEventTemplate(eventTemplate);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <EventTemplateDetails
          reportTemplate={reportTemplate}
          maintenanceItemTemplate={maintenanceItemTemplate}
          eventTemplate={eventTemplate}
          onCloseClick={handleCloseClick}
        />
    );
    return (
        <EventTemplatesList
          reportTemplate={reportTemplate}
          maintenanceItemTemplate={maintenanceItemTemplate}
          onAddClick={handleAddClick}
          onEditClick={handleEditClick}
        />
    );
}
