import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, Container, Nav, Navbar, NavbarBrand, NavItem} from 'react-bootstrap';
import {Collapse, NavbarToggler} from 'reactstrap';
import {USER_NAME} from 'constants/apiConstants';
import {userRoles} from 'constants/dataConstants';
import {getUserRole, isAuthenticated, removeUserData} from 'utils/Auth';

const roleStyle = {
    display: 'inline-block',
    textAlign: 'right',
    verticalAlign: 'middle',
    marginRight: '10px',
}

export default function MainNavbar({pageTitle}) {
    const isMounted = useRef(false);
    const sidebarToggle = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [color, setColor] = useState('transparent');
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        isMounted.current = true;
        window.addEventListener('resize', updateColor.bind(this));
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf('nav-open') !== -1
        ) {
            document.documentElement.classList.toggle('nav-open');
            sidebarToggle.current.classList.toggle('toggled');
        }
    }, [location]);

    const toggle = () => {
        if (isOpen) setColor('transparent');
        else setColor('dark');
        setIsOpen(!isOpen);
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle('nav-open');
        sidebarToggle.current.classList.toggle('toggled');
    };

    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) setColor('dark');
        else setColor('transparent');
    }

    const logoutUser = (e) => {
        removeUserData();
        history.push('/auth/login');
    };

    let navbarContent = null;
    if (isAuthenticated()) {
        navbarContent = (
            <>
                <span style={roleStyle}>
                    {window.localStorage.getItem(USER_NAME)}
                    <br />
                    {userRoles[getUserRole()]}
                </span>
                <Button color="primary" onClick={logoutUser}>Logout</Button>
            </>
        );
    }

    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
          color={location.pathname.indexOf('full-screen-maps') !== -1 ? 'dark' : color}
          expand="lg"
          className={
              location.pathname.indexOf('full-screen-maps') !== -1
              ? 'navbar-absolute fixed-top'
              : 'navbar-absolute fixed-top ' + (color === 'transparent' ? 'navbar-transparent ' : '')
          }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={openSidebar}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand>{pageTitle}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    <Nav navbar>
                        <NavItem>{navbarContent}</NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};
