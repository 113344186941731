import React from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {cmaaClassOptions} from 'constants/apiConstants';

export default function BearingForm({dirty, isValid, isSubmitting, handleSubmit, motionOptions, isEditable}) {
    const isDisabled = !isEditable || isSubmitting;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Select label="Motion" name="motion_id" options={motionOptions} required disabled={isDisabled} />
                <Input label="Name" type="text" name="name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Manufacturer" type="text" name="manufacturer" disabled={isDisabled} />
                <Input label="Part number" type="text" name="part_number" disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="In service date" type="date" name="service_date" required cssclass="input-large" disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="CMAA class" name="cmaa_crane_service_classification" options={cmaaClassOptions} required disabled={isDisabled} />
                <Input label="Speed multiplier" type="number" min="0" step="0.000001" name="speed_multiplier" required disabled={isDisabled} />
                <Input label="Dynamic load rating (lbf)" type="number" min="0" step="1" name="dynamic_load_rating" required disabled={isDisabled} />
            </Row>
            <Row className="mb-3">
                <Input label="Life exponent" type="number" min="0" step="0.01" name="life_exponent" required disabled={isDisabled} />
                <Input label="Minimum load (lbf)" type="number" min="0" step="1" name="minimum_load" required disabled={isDisabled} />
                <Input label="Variable load (lbf)" type="number" min="0" step="1" name="variable_load" required disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting}>Save</Button>}
        </Form>
    )
};
