import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {Bounce, toast, ToastContainer} from 'react-toastify';
import {getMaintenanceAlertsCount} from 'api/MaintenanceApi';

const AppContext = createContext(null);

export function AppContextProvider({children}) {
    const [pageTitle, setPageTitle] = useState('Dashboard');
    const [menuItem, setMenuItem] = useState('dashboard');
    const [maintenanceAlertsCount, setMaintenanceAlertsCount] = useState(0);

    const showMessage = useCallback((type, message) => {
        switch (type) {
            case 'error':
                toast.error(message);
                break;
            case 'info':
                toast.info(message);
                break;
            case 'success':
                toast.success(message);
                break;
            case 'warning':
                toast.warn(message);
                break;
            default:
                toast(message);
        }
    }, []);

    const handleApiError = useCallback(error => {
        if (error.response) showMessage('error', error.response.data.message);
    }, []);

    const loadMaintenanceAlertsCount = useCallback(mark_seen_alerts => {
        const payload = {};
        if (mark_seen_alerts) payload.mark_seen_alerts = true;
        const onSuccess = response => setMaintenanceAlertsCount(Number(response.data));
        getMaintenanceAlertsCount(payload, onSuccess);
    }, []);

    const appContextValue = useMemo(() => ({
        pageTitle,
        setPageTitle,
        menuItem,
        setMenuItem,
        showMessage,
        maintenanceAlertsCount,
        setMaintenanceAlertsCount,
        loadMaintenanceAlertsCount,
        handleApiError
    }), [pageTitle, menuItem, maintenanceAlertsCount]);

    return (
        <>
            <AppContext.Provider value={appContextValue}>
                {children}
            </AppContext.Provider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Bounce}
            />
        </>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
