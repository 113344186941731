import React, {useEffect, useRef, useState} from 'react';
import {Table, Spinner, Button} from 'react-bootstrap';
import {deleteEvent} from 'api/EventsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {eventActions} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';

let eventObject;

export default function EventsList({
    pageState, currentVersion, currentConfiguration, onDataUpdate, eventParams, onDetailsClick, onAddButtonClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [events, setEvents] = useState([]);
    const isEditable = isAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getEvents();
        return () => isMounted.current = false;
    }, [pageState, currentVersion, currentConfiguration]);

    const getEvents = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.event_details)) {
                const _events = [];
                currentConfiguration.event_details.map(event => {
                    if (event.event_type === 2) _events.push(event);
                });
                setEvents(_events);
            }
            setIsLoading(false);
        }
    };

    const handleDeleteClick = (event) => {
        eventObject = event;
        setDeleteDialogBody(<p>Do you really want to delete the event "<b>{event.event_name}</b>" and its related notifications?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteEvent(pageState.craneId, eventObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Event configuration has been successfully saved');
            onDataUpdate();
        }
    };

    let addButton;
    if (isEditable) addButton = <button className="btn btn-add" onClick={onAddButtonClick}>Add</button>;

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 6 : 5} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (events.length) {
        tableContent = events.map(event => {
            let formula = event.event_formula || {};
            formula = !isEmpty(formula) && !isEmpty(eventParams) ? `${formula.motion_name} (${eventParams[formula.key]} ${formula.condition} ${formula.value})` : '';
            let actions = event.event_actions || [];
            actions = actions.map(action => eventActions[action]);
            return (
                <tr key={event.id}>
                    <td>{event.event_name}</td>
                    <td>{formula}</td>
                    <td>{event.event_seconds}</td>
                    <td>{actions ? actions.join(', ') : '-'}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onDetailsClick(event)}>{isEditable ? 'Edit' : 'View'}</span>
                    </td>
                    {isEditable &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(event)}>Delete</span>
                        </td>}
                </tr>
            )
        });
    } else {
        tableContent = <tr><td colSpan={isEditable ? 6 : 5}>No events found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting event" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Event name</th>
                        <th>IF formula</th>
                        <th>For seconds</th>
                        <th>Event action</th>
                        <th colSpan={isEditable ? 2 : 1} className="text-center">{addButton}</th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
