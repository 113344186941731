import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteEventTemplate, getEventTemplatesList} from 'api/EventTemplatesApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {alertTypes, reportTemplateParameters} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';

let eventTemplateObject;

export default function EventTemplatesList({reportTemplate, maintenanceItemTemplate, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [eventTemplates, setEventTemplates] = useState([]);
    const isDraft = reportTemplate.status === 1;
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(`${reportTemplate.name} / ${maintenanceItemTemplate.name}`);
        loadEventTemplatesList();
        return () => isMounted.current = false;
    }, []);

    const loadEventTemplatesList = () => {
        setIsLoading(true);
        const onSuccess = response => setEventTemplates(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getEventTemplatesList(reportTemplate.id, maintenanceItemTemplate.id, onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (eventTemplate) => {
        eventTemplateObject = eventTemplate;
        setDeleteDialogBody(<p>Do you really want to delete the event template "<b>{eventTemplate.name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteEventTemplate(
            reportTemplate.id, maintenanceItemTemplate.id, eventTemplateObject.id,
            handleSuccessResponse, handleApiError, onDone
        );
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Event template has been successfully deleted');
        if (isMounted.current) loadEventTemplatesList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isDraft && isEditable ? 6 : 5} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (eventTemplates.length) {
        tableContent = eventTemplates.map(eventTemplate => {
            const parameters = eventTemplate.params.map(item => reportTemplateParameters[item]);
            return (
                <tr key={eventTemplate.id}>
                    <td>{eventTemplate.name}</td>
                    <td>{alertTypes[eventTemplate.alert_type]}</td>
                    <td>{eventTemplate.alert_text}</td>
                    <td>{parameters.join(', ')}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(eventTemplate)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                    {isEditable && isDraft &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(eventTemplate)}>Delete</span>
                        </td>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isDraft && isEditable ? 6 : 5}>No event templates found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting event template" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <div>
                <span className="text-card-title">Event templates</span>
                {isEditable && isDraft && <button className="btn btn-add float-right" onClick={onAddClick}>Add</button>}
                <button className="btn btn-add float-right" onClick={() => history.goBack()}>Back</button>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Alert type</th>
                        <th>Alert text</th>
                        <th>Condition parameters</th>
                        <th colSpan={isDraft && isEditable ? 2 : 1} />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
