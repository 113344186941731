import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {requestDataExport} from 'api/DataExportsApi';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, objectToOptions} from 'utils/Utils';
import DataExportForm from './DataExportForm';

const DEFAULT_INSTANCE = {
    data_export_type: '',
    motions: '',
    date_from: '',
    hour_from: '',
    date_to: '',
    hour_to: '',
    params: [],
};
const VALIDATION_SCHEMA = Yup.object().shape({
    data_export_type: Yup.string().required('Type is required'),
    motions: Yup.array().of(Yup.string()).min(1, 'At least one motion is required'),
    date_from: Yup.date().required('Start date is required'),
    hour_from: Yup.number().required('Start time is required'),
    date_to: Yup.date().required('End date is required'),
    hour_to: Yup.number().required('End time is required'),
    params: Yup.array().of(Yup.string()).min(1, 'At least one parameter is required'),
});

export default function DataExportRequest({crane, motionsList, motionParameters, odometerParameters, onDataSubmitted}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([]);
    const [motionParameterOptions, setMotionParameterOptions] = useState([]);
    const [odometerParameterOptions, setOdometerParameterOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});

    useEffect(() => {
        isMounted.current = true;
        getInitialValues()
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setMotionOptions(
            objectToOptions(
                arrayToObject(motionsList, 'id', 'motion_name')
            )
        );
    }, [motionsList]);

    useEffect(() => {
        setMotionParameterOptions(
            objectToOptions(
                arrayToObject(motionParameters, 'name', 'display')
            )
        );
    }, [motionParameters]);

    useEffect(() => {
        setOdometerParameterOptions(
            objectToOptions(
                arrayToObject(odometerParameters, 'name', 'display')
            )
        );
    }, [odometerParameters]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const now = moment();
                return {
                    ...oldData,
                    data_export_type: 'motion',
                    date_from: now.clone().subtract(1, 'days').format(DATE_FORMAT_ISO),
                    hour_from: '0',
                    date_to: now.format(DATE_FORMAT_ISO),
                    hour_to: now.clone().startOf('hour').format('H'),
                };
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setSubmitting(true);
        const payload = {
            data_export_type: values.data_export_type,
            motions: values.motions.map(motionId => parseInt(motionId)),
            date_from: moment(`${values.date_from} ${values.hour_from}:00`).utc().toISOString(),
            date_to: moment(`${values.date_to} ${values.hour_to}:00`).utc().toISOString(),
            params: values.params,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => isMounted.current && setSubmitting(false);
        requestDataExport(crane.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Data export has been successfully requested');
        onDataSubmitted();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Row>
            <Col>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={VALIDATION_SCHEMA}
                  onSubmit={handleSubmit}
                >
                    {(props) => (
                        <DataExportForm
                          {...props}
                          motionOptions={motionOptions}
                          motionParameterOptions={motionParameterOptions}
                          odometerParameterOptions={odometerParameterOptions}
                        />
                    )}
                </Formik>
            </Col>
        </Row>
    );
};
