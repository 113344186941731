import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {getMaintenanceReport} from 'api/MaintenanceApi';
import MaintenanceItem from './MaintenanceItem';
import ServiceRecords from './ServiceRecords';

export default function CustomReport({customReport}) {
    const isMounted = useRef(false);
    const [showServiceRecords, setShowServiceRecords] = useState(false);
    const [maintenanceItems, setMaintenanceItems] = useState([]);
    const [maintenanceItem, setMaintenanceItem] = useState({});

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setMaintenanceItems(customReport.maintenance_items);
    }, [customReport]);

    const refreshMaintenanceItems = () => {
        const onSuccess = response => isMounted.current && setMaintenanceItems(response.data.maintenance_items);
        getMaintenanceReport(customReport.crane_reference, customReport.id, onSuccess);
    };

    const handleRecordsClick = (maintenanceItem) => {
        setMaintenanceItem(maintenanceItem);
        setShowServiceRecords(true);
    };

    const handleCloseClick = () => {
        setShowServiceRecords(false);
        refreshMaintenanceItems();
    }

    let pageContent;
    if (showServiceRecords) {
        pageContent = (
            <Col md="12">
                <ServiceRecords customReport={customReport} maintenanceItem={maintenanceItem} onCloseClick={handleCloseClick} />
            </Col>
        );
    }
    else {
        pageContent = maintenanceItems.map(maintenanceItem => (
            <Col key={maintenanceItem.id} md="6">
                <MaintenanceItem maintenanceItem={maintenanceItem} onRecordsClick={handleRecordsClick} />
            </Col>
        ));
    }

    return (
        <div>
            <Row>
                <Col md="12">
                    <p className="text-title title-report">{customReport.name} ({customReport.motion_name})</p>
                </Col>
            </Row>
            <Row>{pageContent}</Row>
        </div>
    );
}
