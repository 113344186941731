import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertCustomReport} from 'api/CustomReportsApi';
import {getMotionsList} from 'api/MotionsApi';
import {getReportTemplatesList} from 'api/ReportTemplatesApi';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import CustomReportForm from './CustomReportForm';

const DEFAULT_INSTANCE = {
    id: '',
    name: '',
    report_template_reference: '',
    motion_reference: '',
    params: [],
};
const VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    report_template_reference: Yup.string().required('Template is required'),
    motion_reference: Yup.string().required('Motion is required')
});

export default function CustomReportDetails({crane, customReport, onCloseClick}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [templatesList, setTemplatesList] = useState([]);
    const [motionsList, setMotionsList] = useState([]);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isNew = isEmpty(customReport);
    const isDraft = isNew || customReport.status === 1;
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        loadReportTemplatesList();
        loadMotionsList();
        getInitialValues();
    }, [crane, customReport]);

    const loadReportTemplatesList = () => {
        const onSuccess = response => isMounted.current && setTemplatesList(response.data);
        getReportTemplatesList(2, onSuccess);
    };

    const loadMotionsList = () => {
        const onSuccess = response => isMounted.current && setMotionsList(response.data);
        if (crane) getMotionsList(crane.id, onSuccess);
    };

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = customReport.hasOwnProperty(key) ? customReport[key] : value;
                }
                newData.report_template_reference = customReport.report_template && customReport.report_template.id.toString() || '';
                newData.report_template_name = customReport.report_template && customReport.report_template.name || '';
                newData.motion_reference = customReport.motion && customReport.motion.id.toString() || '';
                newData.motion_name = customReport.motion && customReport.motion.name || '';
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {id: values.id, name: values.name};
        if (isNew) {
            payload.report_template_reference = parseInt(values.report_template_reference);
            payload.motion_reference = parseInt(values.motion_reference);
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertCustomReport(crane.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Custom report has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <>
            <Row style={{marginBottom: '15px'}}>
                <Col>
                    <span className="text-title title-header">{isEmpty(customReport) ? 'New custom report' : 'Custom report'}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={VALIDATION_SCHEMA}
                      onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <CustomReportForm
                              {...props}
                              templatesList={templatesList}
                              motionsList={motionsList}
                              isNew={isNew}
                              isDraft={isDraft}
                              isEditable={isEditable}
                              isLoading={isLoading}
                              onCloseClick={onCloseClick}
                            />
                        )}
                    </Formik>
                </Col>
            </Row>
        </>
    );
}
