import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {getDataFromLocalStorage, saveDataToLocalStorage} from 'utils/Ui';
import MaintenanceItemDetails from './MaintenanceItemDetails';
import MaintenanceItemsList from './MaintenanceItemsList';

export default function MaintenanceItems({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [maintenanceItem, setMaintenanceItem] = useState({});

    let crane = location.state && location.state.crane;
    if (crane) saveDataToLocalStorage('crane', crane);
    else crane = getDataFromLocalStorage('crane');

    let customReport = location.state && location.state.customReport;
    if (customReport) saveDataToLocalStorage('customReport', customReport);
    else customReport = getDataFromLocalStorage('customReport');

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/cranes');
        setPageTitle(crane ? crane.crane_name : 'Maintenance items');
        return () => isMounted.current = false;
    }, []);

    const handleEditClick = (maintenanceItem) => {
        setMaintenanceItem(maintenanceItem);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <MaintenanceItemDetails
          crane={crane}
          customReport={customReport}
          maintenanceItem={maintenanceItem}
          onCloseClick={handleCloseClick}
        />
    );
    return (
        <MaintenanceItemsList
          crane={crane}
          customReport={customReport}
          onEditClick={handleEditClick}
        />
    );
};
