import React, {useEffect, useRef, useState} from 'react';
import {getMaintenanceReportsList} from 'api/MaintenanceApi';
import Loader from 'components/Loader/Loader';
import CustomReport from 'components/Maintenance/CustomReport';
import {PROPERTY_WEB_PARAMS} from 'constants/apiConstants';
import {useAppContext} from 'contexts/AppContext';

export default function CraneMaintenance({pageState}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [customReports, setCustomReports] = useState([]);

    if (pageState) localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(pageState));
    else pageState = JSON.parse(window.localStorage.getItem(PROPERTY_WEB_PARAMS));

    useEffect(() => {
        isMounted.current = true;
        loadCustomReports();
        return () => isMounted.current = false;
    }, []);

    const loadCustomReports = () => {
        setIsLoading(true);
        const onSuccess = response => setCustomReports(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getMaintenanceReportsList(pageState.crane.id, onSuccess, handleApiError, onDone);
    };

    if (isLoading) return <Loader />;

    return (
        <div className="content">
            {customReports.map(customReport => <CustomReport key={customReport.id} customReport={customReport} />)}
        </div>
    );
}
