import ApiClient from './ApiClient';

const apiClient = ApiClient('/custom_reports');

export const updateCustomEvent = (craneId, customReportId, maintenanceItemId, eventTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${customReportId}/maintenance/${maintenanceItemId}/events/${eventTemplateId}/`, payload)
        .then(onSuccess).catch(onError).finally(onDone);
};

export const getCustomEventsList = (craneId, customReportId, maintenanceItemId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${customReportId}/maintenance/${maintenanceItemId}/events/`)
        .then(onSuccess).catch(onError).finally(onDone);
};
