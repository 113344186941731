import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {DEFAULT_CUSTOMER_OPTION} from 'constants/uiConstants';
import {getCustomerOptions} from 'utils/Ui';

export default function SiteForm({
    dirty, isValid, isSubmitting, handleSubmit,
    useCustomer, customers, isEditable, isLoading, onCloseClick
}) {
    const isMounted = useRef(false);
    const [customerOptions, setCustomerOptions] = useState([DEFAULT_CUSTOMER_OPTION]);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        if (useCustomer) setCustomerOptions(getCustomerOptions(customers));
        return () => isMounted.current = false;
    }, [customers]);

    return (
        <Form onSubmit={handleSubmit}>
            {useCustomer &&
                <Row>
                    <Select label="Customer" name="customer_uuid" options={customerOptions} required disabled={isDisabled} />
                </Row>}
            <Row>
                <Input label="Site ID" type="text" name="site_id" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Site name" type="text" name="site_name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Site address" type="text" name="site_address" required disabled={isDisabled} />
            </Row>
            <Row>
                <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
}
