import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertBearing} from 'api/BearingsApi';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, isEmpty, objectToOptions} from 'utils/Utils';
import BearingForm from './BearingForm';

const DEFAULT_INSTANCE = {
    id: '',
    motion_id: '',
    name: '',
    manufacturer: '',
    part_number: '',
    service_date: '',
    cmaa_crane_service_classification: '',
    speed_multiplier: '',
    dynamic_load_rating: '',
    life_exponent: '',
    minimum_load: '',
    variable_load: '',
};
const VALIDATION_RULES = {
    motion_id: Yup.number().required('Motion is required'),
    name: Yup.string().required('Name is required'),
    service_date: Yup.date().required('Service date is required'),
    cmaa_crane_service_classification: Yup.string().required('CMAA class is required'),
    speed_multiplier: Yup.number().required('Speed multiplier is required'),
    dynamic_load_rating: Yup.number().required('Dynamic load rating is required'),
    life_exponent: Yup.number().required('Life exponent is required'),
    minimum_load: Yup.number().required('Minimum load is required'),
    variable_load: Yup.number().required('Variable load is required'),
};
const DEFAULT_MOTION_OPTION = {label: '-- Select motion --', value: ''};

export default function BearingEdit({crane, bearingObject, motionsList, isEditable, onDataSubmitted}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([DEFAULT_MOTION_OPTION]);
    const validationSchema = Yup.object().shape(VALIDATION_RULES);
    const initialValues = isEmpty(bearingObject) ?
        {...DEFAULT_INSTANCE} :
        {...bearingObject, motion_id: bearingObject.motion.id};

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setMotionOptions([
            DEFAULT_MOTION_OPTION,
            ...objectToOptions(
                arrayToObject(motionsList, 'id', 'motion_name')
            )
        ]);
    }, [motionsList]);

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setSubmitting(true);
        const payload = {...values};
        if (payload.motion_id) payload.motion_id = Number(payload.motion_id);
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => isMounted.current && setSubmitting(false);
        upsertBearing(crane.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Bearing has been successfully saved');
            onDataSubmitted();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    }

    return (
        <Row>
            <Col>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                    {(props) => (
                        <BearingForm
                          {...props}
                          motionOptions={motionOptions}
                          isEditable={isEditable}
                        />
                    )}
                </Formik>
            </Col>
        </Row>
    );
}
