import axios from 'axios';
import {getValidAccessToken, removeUserData} from 'utils/Auth';

export default function ApiClient(baseUrl) {
    const axiosConfig = {
        baseURL: window.REST_API_URL + baseUrl,
        headers: {'Content-Type': 'application/json'}
    }
    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.interceptors.request.use(
        async config => {
            const accessToken = await getValidAccessToken();
            if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        },
        error => Promise.reject(error)
    );
    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                removeUserData();
                window.location = '#/auth/login';
            }
            return Promise.reject(error);
        }
    );
    return axiosInstance;
}
