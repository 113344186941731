import ApiClient from './ApiClient';

const apiClient = ApiClient('/auth');

export const authenticateUser = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/login/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getAuthenticatedUser = (onSuccess, onError, onDone) => {
    apiClient.get('/status/').then(onSuccess).catch(onError).finally(onDone);
};

export const refreshTokens = async refreshToken => {
    const payload = JSON.stringify({refresh_token: refreshToken});
    const request = new Request(window.REST_API_URL + '/auth/refresh/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Accept: 'application/json'},
        body: payload,
    });
    const response = await fetch(request);
    return await response.json();
};
