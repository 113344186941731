import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import FaultsList from './FaultsList';
import FaultsFilters from './FaultsFilters';

export default function CraneFaults({crane, motionsList}) {
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({limit: 25, offset: 0, order_by: 'created_date', order_asc: false});

    return (
        <div className="content">
            <Row>
                <Col md="12" className="col-header">
                    <span className="text-title title-header">Fault history</span>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FaultsFilters motionsList={motionsList} isLoading={isLoading} setFilters={setFilters} />
                </Col>
            </Row>
            <FaultsList crane={crane} filters={filters} isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>
    );
}
