import ApiClient from './ApiClient';

const apiClient = ApiClient('/odometer');

export const getOdometerData = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getOdometerParameters = (onSuccess, onError, onDone) => {
    apiClient.get('/params/').then(onSuccess).catch(onError).finally(onDone);
};
