import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Formik} from 'formik';
import moment from 'moment';
import {getCraneChartData} from 'api/CranesApi';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, objectToOptions} from 'utils/Utils';

const filterStyle = {display: 'inline-block', marginRight: 10};
const iconStyle = {display: 'inline-block', position: 'relative', top: '6px'};
const cursorActiveStyle = {cursor: 'pointer', color: '#fb9e20'};
const cursorInactiveStyle = {cursor: 'not-allowed', color: '#888'};

export default function ChartDataForm({crane, motionsList, setIsLoading, setChartData, setChartDate}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([]);
    const [dateFrom, setDateFrom] = useState(moment().format(DATE_FORMAT_ISO));
    const [motion, setMotion] = useState('');

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setMotion(motionsList.length ? motionsList[0].motion_uuid : '');
        setMotionOptions(
            objectToOptions(
                arrayToObject(motionsList, 'motion_uuid', 'motion_name')
            )
        );
    }, [motionsList]);

    useEffect(() => {
        loadCraneChartData();
    }, [motion, dateFrom]);

    const loadCraneChartData = () => {
        if (motion === '') return;
        setIsLoading(true);
        const payload = {
            motion_uuid: motion,
            date_from: moment(dateFrom).utc().toISOString(),
        }
        const onSuccess = response => {
            setChartData(response.data);
            setChartDate(dateFrom);
        };
        const onDone = () => isMounted.current && setIsLoading(false);
        getCraneChartData(crane.id, payload, onSuccess, handleApiError, onDone);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={{motion: motion, date_from: dateFrom}}
          onSubmit={() => {}}
        >
            {({values, isSubmitting, setFieldValue, handleChange, handleSubmit}) => {

                const [backwardCursorStyle, setBackwardCursorStyle] = useState(cursorActiveStyle);
                const [forwardCursorStyle, setForwardCursorStyle] = useState(cursorInactiveStyle);
                const maxDate = moment().startOf('day');

                useEffect(() => {
                    const selectedDate = moment(values.date_from);
                    if (selectedDate.isValid()) {
                        setBackwardCursorStyle(cursorActiveStyle);
                        setForwardCursorStyle(selectedDate >= maxDate ? cursorInactiveStyle : cursorActiveStyle);
                    }
                    else {
                        setBackwardCursorStyle(cursorInactiveStyle);
                        setForwardCursorStyle(cursorInactiveStyle);
                    }
                }, [values.date_from]);

                const handleMotionChange = e => {
                    handleChange(e);
                    setMotion(e.target.value);
                };

                const handleDateChange = e => {
                    handleChange(e);
                    const selectedDate = moment(e.target.value);
                    if (selectedDate.isValid()) setDateFrom(selectedDate.format(DATE_FORMAT_ISO));
                };

                const handleCursorClick = direction => {
                    const selectedDate = moment(values.date_from);
                    if (selectedDate.isValid()) {
                        const days = direction === 'backward' ? -1 : 1;
                        const newDate = selectedDate.add(days, 'days');
                        if (newDate <= maxDate) {
                            setDateFrom(newDate.format(DATE_FORMAT_ISO));
                            setFieldValue('date_from', newDate.format(DATE_FORMAT_ISO), true);
                        }
                    }
                };

                return (
                    <Form onSubmit={handleSubmit}>
                        <div style={filterStyle}>
                            <Select
                              label="Motion" name="motion" options={motionOptions} skipgroup="true"
                              onChange={handleMotionChange} required disabled={isSubmitting}
                            />
                        </div>
                        <div style={{...iconStyle, marginLeft: '10px'}}>
                            <FontAwesomeIcon
                              icon="fa-caret-left" size="2x" style={backwardCursorStyle}
                              onClick={() => handleCursorClick('backward')}
                            />
                        </div>
                        <div style={{...filterStyle, marginRight: 0}}>
                            <Input
                              label="Date" type="date" name="date_from" skipgroup="true"
                              max={moment().format(DATE_FORMAT_ISO)} onChange={handleDateChange}
                              required disabled={isSubmitting}
                            />
                        </div>
                        <div style={{...iconStyle, marginRight: '10px'}}>
                            <FontAwesomeIcon
                              icon="fa-caret-right" size="2x" style={forwardCursorStyle}
                              onClick={() => handleCursorClick('forward')}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
