import ApiClient from './ApiClient';

const apiClient = ApiClient('/custom_reports');

export const createCustomReport = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateCustomReport = (craneId, customReportId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${customReportId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertCustomReport = (craneId, payload, onSuccess, onError, onDone) => {
    const customReportId = payload.id;
    delete payload.id;
    if (customReportId) updateCustomReport(craneId, customReportId, payload, onSuccess, onError, onDone);
    else createCustomReport(craneId, payload, onSuccess, onError, onDone);
};

export const patchCustomReport = (craneId, customReportId, payload, onSuccess, onError, onDone) => {
    apiClient.patch(`/${craneId}/${customReportId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const deleteCustomReport = (craneId, customReportId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/${customReportId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCustomReportsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};
