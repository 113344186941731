import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteMaintenanceItemTemplate, getMaintenanceItemTemplatesList} from 'api/MaintenanceItemTemplatesApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';

let maintenanceItemTemplateObject;

export default function MaintenanceItemTemplatesList({reportTemplate, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [maintenanceItemTemplates, setMaintenanceItemTemplates] = useState([]);
    const isDraft = reportTemplate.status === 1;
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(reportTemplate.name);
        loadMaintenanceItemTemplatesList();
        return () => isMounted.current = false;
    }, []);

    const loadMaintenanceItemTemplatesList = () => {
        setIsLoading(true);
        const onSuccess = response => setMaintenanceItemTemplates(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getMaintenanceItemTemplatesList(reportTemplate.id, onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (maintenanceItemTemplate) => {
        maintenanceItemTemplateObject = maintenanceItemTemplate;
        setDeleteDialogBody(<p>Do you really want to delete the maintenance item template "<b>{maintenanceItemTemplate.name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteMaintenanceItemTemplate(
            reportTemplate.id, maintenanceItemTemplateObject.id,
            handleSuccessResponse, handleApiError, onDone
        );
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Maintenance item template has been successfully deleted');
        if (isMounted.current) loadMaintenanceItemTemplatesList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isDraft && isEditable ? 4 : 3} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (maintenanceItemTemplates.length) {
        tableContent = maintenanceItemTemplates.map(maintenanceItemTemplate => {
            return (
                <tr key={maintenanceItemTemplate.id}>
                    <td>{maintenanceItemTemplate.name}</td>
                    <td className="text-center" style={{fontWeight: 'bold'}}>
                        <Link
                          to={{pathname: '/app/event-templates', state: {reportTemplate: reportTemplate, maintenanceItemTemplate: maintenanceItemTemplate}}}
                        >
                            {maintenanceItemTemplate.report_events_count}
                        </Link>
                    </td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(maintenanceItemTemplate)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                    {isEditable && isDraft &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(maintenanceItemTemplate)}>Delete</span>
                        </td>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isDraft && isEditable ? 4 : 3}>No maintenance item templates found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting maintenance item template" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <div>
                <span className="text-card-title">Maintenance item templates</span>
                {isEditable && isDraft && <button className="btn btn-add float-right" onClick={onAddClick}>Add</button>}
                <button className="btn btn-add float-right" onClick={() => history.goBack()}>Back</button>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-center">Event templates</th>
                        <th colSpan={isDraft && isEditable ? 2 : 1} />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
