import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {getOdometerData} from 'api/OdometerApi';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {hourOptions} from 'constants/apiConstants';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, objectToOptions, zeroFill} from 'utils/Utils';

const buttonStyle = {
    marginTop: '25px',
    marginBottom: '0',
    width: 'fit-content',
};

export default function OdometerDataForm({crane, motionsList, odometerParameters, setIsLoading, setOdometerData}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([]);
    const [odometerStartDates, setOdometerStartDates] = useState({});
    const [isCustomDate, setIsCustomDate] = useState(false);
    const validationRules = {
        motion_id: Yup.number().required('Motion is required'),
        date_from: Yup.date().required('Start date is required'),
        hour_from: Yup.number().required('Start time is required'),
        date_to: Yup.date().required('End date is required'),
        hour_to: Yup.number().required('End time is required'),
    };

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setOdometerStartDates(arrayToObject(motionsList, 'id', 'odometer_data_start_date'));
        setMotionOptions(
            objectToOptions(
                arrayToObject(motionsList, 'id', 'motion_name')
            )
        );
    }, [motionsList]);

    const getInitialValues = () => {
        const now = moment();
        return {
            motion_id: 0,
            date_from: now.format(DATE_FORMAT_ISO),
            hour_from: '0',
            date_to: now.format(DATE_FORMAT_ISO),
            hour_to: now.clone().startOf('hour').format('H'),
        };
    };

    const getMotionDateTime = (motionId) => {
        const dateTime = moment(odometerStartDates[Number(motionId)]);
        return {
            date: dateTime.format(DATE_FORMAT_ISO),
            hour: dateTime.startOf('hour').format('H'),
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setSubmitting(true);
        setIsLoading(true);
        const payload = {
            motion_id: Number(values.motion_id),
            date_from: moment(`${values.date_from} ${zeroFill(values.hour_from, 2)}:00`).utc().toISOString(),
            date_to: moment(`${values.date_to} ${zeroFill(values.hour_to, 2)}:00`).utc().toISOString(),
            params: odometerParameters.map(p => p.name),
        }
        const onSuccess = response => setOdometerData(response.data);
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setIsLoading(false);
                setSubmitting(false);
            }
        }
        getOdometerData(crane.id, payload, onSuccess, onError, onDone);
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          initialValues={getInitialValues()}
          validationSchema={Yup.object().shape(validationRules)}
          onSubmit={handleSubmit}
        >
            {({values, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit}) => {

                useEffect(() => {
                    // a hack that sets initial values
                    if (motionsList.length && Object.keys(odometerStartDates).length) {
                        const motionId = motionsList[0].id;
                        const motionDateTime = getMotionDateTime(motionId);
                        setFieldValue('date_from', motionDateTime.date, false);
                        setFieldValue('hour_from', motionDateTime.hour, false);
                        setFieldValue('motion_id', motionId, false);
                    }
                }, [motionsList, odometerStartDates]);

                useEffect(() => {
                    if (isValid && Number(values.motion_id) > 0) handleSubmit();
                }, [values.motion_id]);

                const handleMotionChange = (e) => {
                    handleChange(e);
                    if (!isCustomDate) {
                        const motionDateTime = getMotionDateTime(e.target.value);
                        setFieldValue('date_from', motionDateTime.date, false);
                        setFieldValue('hour_from', motionDateTime.hour, false);
                    }
                };

                const handleDateTimeChange = (e) => {
                    handleChange(e);
                    setIsCustomDate(true);
                };

                return (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Select label="Motion" name="motion_id" options={motionOptions} onChange={handleMotionChange} required disabled={isSubmitting} />
                            <Input label="Start date" type="date" name="date_from" onChange={handleDateTimeChange} required disabled={isSubmitting} />
                            <Select label="Start time" name="hour_from" options={hourOptions} onChange={handleDateTimeChange} required disabled={isSubmitting} />
                            <Input label="End date" type="date" name="date_to" required disabled={isSubmitting} />
                            <Select label="End time" name="hour_to" options={hourOptions} required disabled={isSubmitting} />
                            <Form.Group as={Col} size="lg">
                                <Button type="submit" className="btn-form-submit" style={buttonStyle} disabled={!isValid || isSubmitting}>View</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};
