import Login from 'views/login/Login';

const authRoutes = [
    {
        path: '/auth/login',
        component: Login,
    }
];

export default authRoutes;
