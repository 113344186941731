import ApiClient from './ApiClient';

const apiClient = ApiClient('/sites');

export const createSite = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateSite = (siteId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${siteId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertSite = (payload, onSuccess, onError, onDone) => {
    const siteId = payload.id;
    delete payload.id;
    if (siteId) updateSite(siteId, payload, onSuccess, onError, onDone);
    else createSite(payload, onSuccess, onError, onDone);
};

export const deleteSite = (siteId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${siteId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getSitesList = (customerId, onSuccess, onError, onDone) => {
    let url = '/';
    if (customerId) url += `?customer_id=${customerId}`;
    apiClient.get(url).then(onSuccess).catch(onError).finally(onDone);
};
