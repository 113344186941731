import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertCustomerMapping} from 'api/CustomerMappingsApi';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isLocationAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';
import {loadCraneSystems, loadCustomers, loadLocations, loadSites} from 'utils/Ui';
import {isEmpty} from 'utils/Utils';
import CustomerConfigurationForm from './CustomerConfigurationForm';

export default function CustomerConfig({pageState, currentVersion, currentConfiguration, onDataUpdate}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState({});
    const [sites, setSites] = useState({});
    const [locations, setLocations] = useState({});
    const [craneSystems, setCraneSystems] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const useCustomer = isSystemAdmin();
    const useSite = isSystemAdmin() || isCustomerAdmin();
    const useLocation = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin();
    const useCraneSystem = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin() || isLocationAdmin();
    const isEditable = useCraneSystem && currentVersion.status === 0;
    const validationRules = {};
    if (useCustomer) validationRules.customer_uuid = Yup.string().required('Customer is required');
    if (useSite) validationRules.site_uuid = Yup.string().required('Site is required');
    if (useLocation) validationRules.location_uuid = Yup.string().required('Location is required');
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        if (useCustomer) loadCustomers(setCustomers, handleApiError);
        if (useSite) loadSites(setSites, handleApiError);
        if (useLocation) loadLocations(setLocations, handleApiError);
        if (useCraneSystem) loadCraneSystems(setCraneSystems, handleApiError);
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        getInitialValues();
        return () => {}
    }, [pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (
                !isEmpty(currentConfiguration) &&
                !isEmpty(currentConfiguration.customer_details)
            ) {
                const customerDetails = currentConfiguration.customer_details;
                const craneSystemDetails = currentConfiguration.crane_system_details;
                const newData = {};
                if (useCustomer) newData.customer_uuid = customerDetails.customer ? customerDetails.customer.customer_uuid : '';
                if (useSite) newData.site_uuid = customerDetails.site ? customerDetails.site.site_uuid : '';
                if (useLocation) newData.location_uuid = customerDetails.location ? customerDetails.location.location_uuid : '';
                if (useCraneSystem) newData.crane_system_uuid = craneSystemDetails.crane_system_uuid;
                setInitialValues(newData);
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        if (!useCustomer) delete payload.customer_uuid;
        if (!useSite) delete payload.site_uuid;
        if (!useLocation) delete payload.location_uuid;
        if (!useCraneSystem || payload.crane_system_uuid === '') delete payload.crane_system_uuid;
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertCustomerMapping(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Customer configuration has been successfully saved');
            onDataUpdate();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <CustomerConfigurationForm
                  {...props}
                  useCustomer={useCustomer}
                  useSite={useSite}
                  useLocation={useLocation}
                  useCraneSystem={useCraneSystem}
                  customers={customers}
                  sites={sites}
                  locations={locations}
                  craneSystems={craneSystems}
                  isEditable={isEditable}
                  isLoading={isLoading}
                />
            )}
        </Formik>
    );
}
