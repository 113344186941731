import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Select from 'components/Form/Select';
import {
    DEFAULT_CRANE_SYSTEM_OPTION,
    DEFAULT_CUSTOMER_OPTION,
    DEFAULT_LOCATION_OPTION,
    DEFAULT_SITE_OPTION
} from 'constants/uiConstants';
import {getCraneSystemOptions, getCustomerOptions, getLocationOptions, getSiteOptions} from 'utils/Ui';

export default function CustomerConfigurationForm({
    initialValues, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    useCustomer, useSite, useLocation, useCraneSystem, customers, sites, locations, craneSystems, isEditable, isLoading
}) {
    const isMounted= useRef(false);
    const isDisabled = !isEditable || isLoading;
    const [customerOptions, setCustomerOptions] = useState([DEFAULT_CUSTOMER_OPTION]);
    const [siteOptions, setSiteOptions] = useState([DEFAULT_SITE_OPTION]);
    const [locationOptions, setLocationOptions] = useState([DEFAULT_LOCATION_OPTION]);
    const [craneSystemOptions, setCraneSystemOptions] = useState([DEFAULT_CRANE_SYSTEM_OPTION]);

    useEffect(() => {
        isMounted.current = true;
        let options;
        if (useCustomer) setCustomerOptions(getCustomerOptions(customers));
        if (useSite) {
            options = getSiteOptions(
                sites, useCustomer, initialValues.customer_uuid,
                false, setFieldValue
            );
            setSiteOptions(options);
        }
        if (useLocation) {
            options = getLocationOptions(
                locations, useSite, initialValues.site_uuid,
                false, setFieldValue
            );
            setLocationOptions(options);
        }
        if (useCraneSystem) {
            options = getCraneSystemOptions(
                craneSystems, useLocation, initialValues.location_uuid,
                false, setFieldValue
            );
            setCraneSystemOptions(options);
        }
        return () => isMounted.current = false;
    }, [initialValues, customers, sites, locations, craneSystems]);

    const handleCustomerChange = (e) => {
        handleChange(e);
        setSiteOptions(
            getSiteOptions(sites, useCustomer, e.target.value, true, setFieldValue)
        );
        setLocationOptions(
            getLocationOptions(locations, useSite, '', true, setFieldValue)
        );
        setCraneSystemOptions(
            getCraneSystemOptions(craneSystems, useLocation, '', true, setFieldValue)
        );
    };

    const handleSiteChange = (e) => {
        handleChange(e);
        setLocationOptions(
            getLocationOptions(locations, useSite, e.target.value, true, setFieldValue)
        );
        setCraneSystemOptions(
            getCraneSystemOptions(craneSystems, useLocation,'', true, setFieldValue)
        );
    };

    const handleLocationChange = (e) => {
        handleChange(e);
        setCraneSystemOptions(
            getCraneSystemOptions(craneSystems, useLocation, e.target.value, true, setFieldValue)
        );
    };

    return (
        <Form onSubmit={handleSubmit}>
            {useCustomer &&
                <Row>
                    <Select label="Customer" name="customer_uuid" options={customerOptions} onChange={handleCustomerChange} disabled={isDisabled} />
                </Row>}
            {useSite &&
                <Row>
                    <Select label="Site" name="site_uuid" options={siteOptions} onChange={handleSiteChange} disabled={isDisabled} />
                </Row>}
            {useLocation &&
                <Row>
                    <Select label="Location" name="location_uuid" options={locationOptions} onChange={handleLocationChange} disabled={isDisabled} />
                </Row>}
            {useCraneSystem &&
                <Row>
                    <Select label="Crane system" name="crane_system_uuid" options={craneSystemOptions} disabled={isDisabled} />
                </Row>}
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
        </Form>
    )
};
