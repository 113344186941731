import ApiClient from './ApiClient';

const apiClient = ApiClient('/locations');

export const createLocation = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateLocation = (locationId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${locationId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertLocation = (payload, onSuccess, onError, onDone) => {
    const locationId = payload.id;
    delete payload.id;
    if (locationId) updateLocation(locationId, payload, onSuccess, onError, onDone);
    else createLocation(payload, onSuccess, onError, onDone);
};

export const deleteLocation = (locationId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${locationId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getLocationsList = (customerId, siteId, onSuccess, onError, onDone) => {
    let url = '/';
    const params = [];
    if (customerId) params.push(`customer_id=${customerId}`);
    if (siteId) params.push(`site_id=${siteId}`);
    if (params.length) url += '?' + params.join('&');
    apiClient.get(url).then(onSuccess).catch(onError).finally(onDone);
};
