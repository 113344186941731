import React from 'react';
import {Card, Col, ProgressBar, Row} from 'react-bootstrap';
import moment from 'moment';
import {DATE_FORMAT} from 'constants/uiConstants';

export default function MaintenanceItem({maintenanceItem, onRecordsClick}) {

    let isCalendarTime = false;
    let calendarTimeLabel = '';
    let calendarTimeProgressValue = 0;
    let calendarTimeProgressMaxValue = 0;
    let calendarTimePercentage = '';

    let isOperationTime = false;
    let operationTimeLabel = '';
    let operationTimeProgressValue = 0;
    let operationTimeProgressMaxValue = 0;
    let operationTimePercentage = '';

    maintenanceItem.labels.forEach(item => {
        if (item.param === 'operation_time') {
            isOperationTime = true;
            operationTimeLabel = item.time_in_operation;
        }
        else {
            isCalendarTime = true;
            calendarTimeLabel = item.time_in_operation;
        }
    });
    maintenanceItem.values.forEach(item => {
        if (item.param === 'operation_time') operationTimeProgressValue = item.value;
        else calendarTimeProgressValue = item.value;
    });
    maintenanceItem.params.forEach(item => {
        if (item.param === 'operation_time') {
            operationTimeProgressMaxValue = item.value;
            operationTimePercentage = Math.floor((operationTimeProgressValue / operationTimeProgressMaxValue) * 100);
        }
        else {
            calendarTimeProgressMaxValue = item.value;
            calendarTimePercentage = Math.floor((calendarTimeProgressValue / calendarTimeProgressMaxValue) * 100);
        }
    });
    const eventData = {};
    maintenanceItem.events.forEach(event => {
        event.params.forEach(param => {
            if (!Object.keys(eventData).includes(param.param)) eventData[param.param] = {};
            if (!Object.keys(eventData[param.param]).includes(event.alert_type)) eventData[param.param][event.alert_type] = [];
            eventData[param.param][event.alert_type].push(param.value);
        });
    });
    const progressData1 = {};
    const progressData2 = {};
    maintenanceItem.labels.forEach(label => {
        if (!Object.keys(progressData1).includes(label.param)) {
            progressData1[label.param] = [];
            progressData2[label.param] = [];
        }
        const alertTypes = Object.keys(eventData[label.param] || {}).sort();
        for (let j = 0; j < alertTypes.length; j++) {
            const alertType = alertTypes[j];
            const percentageList = eventData[label.param][alertType];
            if (j === 0) progressData1[label.param].push(percentageList[0]);
            progressData1[label.param].push(percentageList.pop());
            progressData2[label.param].push(alertType);
        }
    });
    const progressData = {};
    for (const [param, percentageList] of Object.entries(progressData1)) {
        let percentage;
        if (param === 'operation_time') percentage = parseInt(operationTimePercentage);
        else percentage = parseInt(calendarTimePercentage);
        if (!(param in Object.keys(progressData))) progressData[param] = [];
        let data;
        let className;
        for (let i = 0; i < percentageList.length; i++) {
            if (i === 0) {
                if (percentageList[i] > percentage) {
                    data = [percentage, 'danger'];
                    progressData[param].push(data);
                    break;
                }
                else {
                    data = [percentageList[i], 'danger'];
                    progressData[param].push(data);
                }
            }
            else {
                const alertType = progressData2[param][i-1];
                if (alertType === 'warning') className = 'red';
                else className = 'primary';
                if (i + 1 === percentageList.length) {
                    data = [percentage - [percentageList[i]], className];
                    progressData[param].push(data);
                }
                else {
                    if (percentageList[i] > percentage) {
                        data = [percentage - [percentageList[i]], className];
                        progressData[param].push(data);
                        break;
                    }
                    else {
                        data = [percentageList[i + 1] - percentageList[i], className];
                        progressData[param].push(data);
                    }
                }
            }
        }
    }

    return (
        <Card.Body className="card-stats" style={{marginBottom: '20px'}}>
            <Row>
                <Col>
                    <span className="title-maintenance-item">{maintenanceItem.name}</span>
                    <button
                      className="btn btn-primary btn-maintenance float-right"
                      style={{borderRadius: '25px'}}
                      onClick={() => onRecordsClick(maintenanceItem)}
                    >
                        Records
                    </button>
                </Col>
            </Row>
            {isCalendarTime &&
                <>
                    <Row>
                        <Col>
                            <p className="card-category" style={{color: '#212529'}}>
                                {calendarTimeLabel} {calendarTimeProgressValue} / {calendarTimeProgressMaxValue} Days ({calendarTimePercentage} %)
                            </p>
                            <ProgressBar>
                                {progressData['calendar_time_in_operation'].map((item, i) => {
                                    return <ProgressBar key={i} variant={item[1]} now={parseInt(item[0])} max={100} />;
                                })}
                            </ProgressBar>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                        <Col md="9">
                            <p className="card-category" style={{color: '#212529', fontSize: '14px'}}>Last service date</p>
                        </Col>
                        <Col md="3">
                            <p className="card-category" style={{textAlign: 'right', fontSize: '14px'}}>
                                {moment(maintenanceItem.last_service.service_date).format(DATE_FORMAT)}
                            </p>
                        </Col>
                    </Row>
                </>}
            {isOperationTime &&
                <>
                    <Row>
                        <Col>
                            <p className="card-category" style={{color: '#212529'}}>
                                {operationTimeLabel} {operationTimeProgressValue} / {operationTimeProgressMaxValue} Hrs ({operationTimePercentage} %)
                            </p>
                            <ProgressBar>
                                {progressData['operation_time'].map((item, i) => {
                                    return <ProgressBar key={i} variant={item[1]} now={parseInt(item[0])} max={100} />;
                                })}
                            </ProgressBar>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                        <Col md="9">
                            <p className="card-category" style={{color: '#212529', fontSize: '14px'}}>Operating hours at last service</p>
                        </Col>
                        <Col md="3">
                            <p className="card-category" style={{textAlign: 'right', fontSize: '14px'}}>
                                {maintenanceItem.last_service.operating_time}
                            </p>
                        </Col>
                    </Row>
                </>}
        </Card.Body>
    );
}
