import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteCustomer, getCustomersList} from 'api/CustomersApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';

let customerObject;

export default function CustomersList({onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [customers, setCustomers] = useState([]);
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Customers');
        loadCustomersList();
        return () => isMounted.current = false;
    }, []);

    const loadCustomersList = () => {
        setIsLoading(true);
        const onSuccess = response => setCustomers(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCustomersList(onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (customer) => {
        customerObject = customer;
        setDeleteDialogBody(<p>Do you really want to delete the customer "<b>{customer.customer_name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteCustomer(customerObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Customer has been successfully deleted');
        if (isMounted.current) loadCustomersList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 7 : 5} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (customers.length) {
        tableContent = customers.map(customer => {
            const sitesLink = (
                <Link to={{pathname: '/app/sites', state: {customer: customer}}}>{customer.total_sites}</Link>
            );
            const cranesLink = (
                <Link to={{pathname: '/app/cranes', state: {customer: customer}}}>{customer.total_cranes}</Link>
            );
            return (
                <tr key={customer.id}>
                    <td>{customer.customer_id}</td>
                    <td>{customer.customer_name}</td>
                    <td className="text-center">{customer.total_sites ? <b>{sitesLink}</b> : customer.total_sites}</td>
                    <td className="text-center">{customer.total_cranes ? <b>{cranesLink}</b> : customer.total_cranes}</td>
                    <td className="text-center">{customer.active ? 'Yes' : 'No'}</td>
                    {isEditable &&
                        <>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => onEditClick(customer)}>Edit</span>
                            </td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => handleDeleteClick(customer)}>Delete</span>
                            </td>
                        </>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isEditable ? 7 : 5}>No customers found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting customer" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th className="text-center">Total sites</th>
                        <th className="text-center">Total cranes</th>
                        <th className="text-center">Active</th>
                        {isEditable &&
                            <th colSpan="2" className="text-center">
                                <button className="btn btn-add" onClick={onAddClick}>Add</button>
                            </th>}
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
