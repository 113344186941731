import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertEdge} from 'api/EdgesApi';
import {ipRegex} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin, isSystemAdmin, isSystemRole} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import EdgeConfigurationForm from './EdgeConfigurationForm';

const DEFAULT_INSTANCE = {
    device_id: '',
    mode: '',
    ssid: '',
    security_type: '',
    passphrase: '',
    ip_address: '',
    ip_gateway: '',
    ip_subnetmask: '',
    recipients_email: '',
    recipients_sms: '',
    administrator_password: '',
    has_administrator_password: false,
};
const VALIDATION_RULES = {
    mode: Yup.string().required('Mode is required'),
    ssid: Yup.string().required('SSID is required'),
    security_type: Yup.string().required('Security type is required'),
    passphrase: Yup.string().nullable(),
    ip_address: Yup.string().when(['mode'], {
        is: (mode) => parseInt(mode) === 1 || false,
        then: (schema) => schema.required('IP address is required').matches(ipRegex, 'IP address is not valid')
    }),
    ip_gateway: Yup.string().when(['mode'], {
        is: (mode) => parseInt(mode) === 1 || false,
        then: (schema) => schema.required('Gateway IP address is required').matches(ipRegex, 'Gateway IP address is not valid')
    }),
    ip_subnetmask: Yup.string().when(['mode'], {
        is: (mode) => parseInt(mode) === 1 || false,
        then: (schema) => schema.required('Subnet mask is required').matches(ipRegex, 'Subnet mask is not valid')
    }),
    recipients_email: Yup.string().nullable(),
    recipients_sms: Yup.string().nullable(),
};

export default function EdgeConfig({pageState, currentVersion, currentConfiguration, onDataUpdate}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isAdmin() && currentVersion.status === 0;
    const showDeviceId = isAdmin() || isSystemRole();
    const editDeviceId = isSystemAdmin();
    const showAdminCredentials = isSystemRole();
    const editAdminCredentials = isSystemAdmin();
    const validationRules = {...VALIDATION_RULES};
    if (editDeviceId) validationRules.device_id = Yup.string().required('Device ID is required');
    if (editAdminCredentials) validationRules.administrator_password = Yup.string().nullable();
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.edge_details)) {
                const edgeDetails = currentConfiguration.edge_details;
                const wifiDetails = edgeDetails.wifi || {};
                const wanDetails = edgeDetails.wan_network || {};
                setInitialValues(oldData => {
                    const newData = {};
                    for (const [key, value] of Object.entries(oldData)) {
                        if (edgeDetails.hasOwnProperty(key)) newData[key] = edgeDetails[key];
                        else if (wifiDetails.hasOwnProperty(key)) newData[key] = wifiDetails[key];
                        else if (wanDetails.hasOwnProperty(key)) newData[key] = wanDetails[key];
                        else newData[key] = value;
                    }
                    if (Array.isArray(newData.recipients_email)) {
                        newData.recipients_email = newData.recipients_email.join(', ');
                    }
                    if (Array.isArray(newData.recipients_sms)) {
                        newData.recipients_sms = newData.recipients_sms.join(', ');
                    }
                    newData.passphrase = '';
                    newData.administrator_password = '';
                    newData.has_administrator_password = edgeDetails.administrator_password && edgeDetails.administrator_password !== '' || false;
                    return newData;
                });
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        let emailsList = [];
        let phonesList = [];
        if (typeof values.recipients_email === 'string') {
            emailsList = values.recipients_email.replace(/[\r\n\t ]+/g, ',');
            emailsList = emailsList.split(',');
        }
        if (typeof values.recipients_sms === 'string') {
            phonesList = values.recipients_sms.replace(/ +/g, '');
            phonesList = phonesList.replace(/[\r\n\t]+/g, ',');
            phonesList = phonesList.split(',');
        }
        const wanNetwork = {
            ip_address: values.ip_address,
            ip_gateway: values.ip_gateway,
            ip_subnetmask: values.ip_subnetmask,
        };
        const wifi = {
            ssid: values.ssid,
            passphrase: values.passphrase,
            mode: parseInt(values.mode),
            security_type: parseInt(values.security_type),
        };
        const payload = {
            device_id: values.device_id,
            wifi: wifi,
            wan_network: wanNetwork,
            recipients_email: emailsList,
            recipients_sms: phonesList,
            administrator_password: values.administrator_password,
        };
        if (!editDeviceId) delete payload.device_id;
        if (!editAdminCredentials) delete payload.administrator_password;
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertEdge(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'EDGE configuration has been successfully saved');
            onDataUpdate();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) {
            const errors = {};
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                errors[key.split('.').pop()] = value;
            }
            setErrors(errors);
        }
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <EdgeConfigurationForm
                  {...props}
                  showDeviceId={showDeviceId}
                  editDeviceId={editDeviceId}
                  showAdminCredentials={showAdminCredentials}
                  editAdminCredentials={editAdminCredentials}
                  isEditable={isEditable}
                  isLoading={isLoading}
                />
            )}
        </Formik>
    );
}
