import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {getEventNotifications} from 'api/EventsApi';
import {eventActions} from 'constants/dataConstants';
import {DATETIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, isEmpty, objectToOptions} from 'utils/Utils';
import NotificationsFilters from './NotificationsFilters';

const DEFAULT_MOTION_OPTION = {label: '-- Select a motion --', value: ''};

export default function NotificationsList({crane, edge, motionsList, filters, setFilters, onViewDataClick}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([DEFAULT_MOTION_OPTION]);
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        loadEventNotifications();
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        const options = [DEFAULT_MOTION_OPTION];
        if (!isEmpty(edge)) options.push({label: 'Edge Box', value: edge.edge_uuid});
        if (motionsList.length) {
            options.push(...objectToOptions(
                arrayToObject(motionsList, 'motion_uuid', 'motion_name')
            ))
        }
        setMotionOptions(options);
    }, [edge, motionsList]);

    useEffect(() => {
        loadEventNotifications();
    }, [crane, filters]);

    const loadEventNotifications = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setNotifications(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        const payload = {...filters};
        if (Object.hasOwn(payload, 'date_from') && payload.date_from) {
            payload.date_from = moment(payload.date_from).toISOString();
        }
        if (Object.hasOwn(payload, 'date_to') && payload.date_to) {
            payload.date_to = moment(payload.date_to).set({h: 23, m: 59, s: 59, ms: 999}).toISOString();
        }
        getEventNotifications(crane.id, payload, onSuccess, handleApiError, onDone);
    };

    let isBackwardDisabled = isLoading || filters.offset === 0;
    let isForwardDisabled = isLoading || notifications.length < filters.limit;

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="4" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (notifications.length === 0) tableContent = <tr><td colSpan="4">No notifications found</td></tr>;
    else tableContent = (
        <>
            {notifications.map(notification => {
                const actions = notification.event_actions.map(ea => eventActions[ea]).join(', ');
                const eventTime = moment(notification.created_date).utc(true).format(DATETIME_FORMAT);
                return (
                    <tr key={notification.id}>
                        <td className="text-center">{eventTime}</td>
                        <td>{notification.event_name}</td>
                        <td>{actions}</td>
                        <td className="text-center">
                            {notification.show_data &&
                                <span className="text-primary text-link" onClick={() => onViewDataClick(notification)}>
                                    View data
                                </span>}
                        </td>
                    </tr>
                );
            })}
        </>
    );

    return (
        <>
            <Row>
                <Col md="12" className="col-header">
                    <span className="text-title title-header">Notifications</span>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <NotificationsFilters
                      motionOptions={motionOptions}
                      isLoading={isLoading}
                      isBackwardDisabled={isBackwardDisabled}
                      isForwardDisabled={isForwardDisabled}
                      filters={filters}
                      setFilters={setFilters}
                    />
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="text-center">Time</th>
                        <th>Name</th>
                        <th>Actions</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>
        </>
    );
};
