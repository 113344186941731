import React, {useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {capacityUnitOptions, loadCellModeOptions, vfdClassOptions, vfdParityOptions} from 'constants/apiConstants';
import {formatCraneWeightFormula} from 'utils/Utils';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';

const DEFAULT_MOTION_OPTION = {label: '-- Select motion --', value: ''};

export default function VfdForm({
    values, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    motions, isEditable, isLoading, onCloseButtonClick
}) {
    const isMounted= useRef(false);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleMotionChange = (e) => {
        handleChange(e);
        setFieldValue('motion_type', e.target.value ? motions[parseInt(e.target.value)].motion_type : '', false);
    };

    const motionOptions = [
        DEFAULT_MOTION_OPTION,
        ...Object.values(motions).map(motion => ({label: motion.motion_name, value: motion.id}))
    ];
    const lcFormula = values.lc_mode ? (parseInt(values.lc_mode) === 2 ? 'ax^2+bx+c' : 'ax+b') : '-';
    const lcWeight = formatCraneWeightFormula(values.lc_mode, values.lc_calibration_a, values.lc_calibration_b, values.lc_calibration_c) || '-';
    const addressLabel = values.crane_communication_type === 'PLC' ? 'Address (e.g. 192.168.1.100)' : 'Address (e.g. 0x1F)';
    const showModbusSettings = values.crane_communication_type === 'MODBUSRTU';
    const showLoadcellSettings = parseInt(values.motion_type) === 1;
    const showCalibrationParams = showLoadcellSettings && !isNaN(parseInt(values.lc_mode));
    const showCalibrationC = showCalibrationParams && !isNaN(parseInt(values.lc_mode)) && parseInt(values.lc_mode) === 2;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="vfd_name" required disabled={isDisabled} />
                <Select label="Motion" name="motion_reference" options={motionOptions} onChange={handleMotionChange} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Manufacturer" type="text" name="vfd_manufacturer" required disabled={isDisabled} />
                <Select label="Class" name="vfd_class" options={vfdClassOptions} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Part number" type="text" name="vfd_part_no" required disabled={isDisabled} />
                <Input label="Serial number" type="text" name="vfd_serial" required disabled={isDisabled} />
                <Input label="Current rated" type="number" min="0" step="0.01" name="current_rated" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Crane communication type" type="text" name="crane_communication_type" disabled />
                <Input label={addressLabel} type="text" name="mb_address" required disabled={isDisabled} />
            </Row>
            {showModbusSettings &&
                <Row>
                    <Input label="Baud rate (e.g. 9600)" type="number" min="300" step="100" name="mb_baud_rate" required disabled={isDisabled} />
                    <Select label="Parity" name="mb_parity" options={vfdParityOptions} required disabled={isDisabled} />
                </Row>}
            {showLoadcellSettings &&
                <>
                    <Row className="form-section"><Col><Form.Label>Load Cell</Form.Label></Col></Row>
                    <Row>
                        <Select label="Mode" name="lc_mode" options={loadCellModeOptions} required disabled={isDisabled} />
                        <Input label="Calibration formula" type="text" name="lc_formula" value={lcFormula} disabled />
                        <Input label="Load cell output conversion equation" type="text" name="lc_weight" value={lcWeight} disabled />
                    </Row>
                    {showCalibrationParams &&
                        <Row>
                            <>
                                <Input label="a" type="number" name="lc_calibration_a" required disabled={isDisabled} />
                                <Input label="b" type="number" name="lc_calibration_b" required disabled={isDisabled} />
                                {showCalibrationC && <Input label="c" type="number" name="lc_calibration_c" required disabled={isDisabled} />}
                            </>
                        </Row>}
                    <Row>
                        <Input label="Register (e.g. 79)" type="number" min="0" step="1" name="lc_register" required disabled={isDisabled} />
                        <Select label="Unit" name="lc_unit" options={capacityUnitOptions} required disabled={isDisabled} />
                    </Row>
                </>}
            <Row>
                <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            <HiddenInput name="motion_type" />
            <HiddenInput name="ts" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseButtonClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
