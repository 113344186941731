import React, {useEffect, useRef, useState} from 'react';
import {arrayToObject, isEmpty} from 'utils/Utils';
import VfdDetails from './VfdDetails';
import VfdsList from './VfdsList';

export default function VfdsConfig({currentConfiguration, ...props}) {
    const isMounted = useRef(false);
    const [showDetails, setShowDetails] = useState(false);
    const [vfd, setVfd] = useState({});
    const [motions, setMotions] = useState({});
    const [motors, setMotors] = useState({});

    useEffect(() => {
        isMounted.current = true;
        getMotions();
        getMotors();
        return () => isMounted.current = false;
    }, [currentConfiguration]);

    const getMotions = () => {
        if (
            !isEmpty(currentConfiguration) &&
            !isEmpty(currentConfiguration.crane_details) &&
            !isEmpty(currentConfiguration.crane_details.motion_config)
        ) setMotions(arrayToObject(currentConfiguration.crane_details.motion_config));
        else setMotions({});
    };

    const getMotors = () => {
        if (
            !isEmpty(currentConfiguration) &&
            !isEmpty(currentConfiguration.crane_details) &&
            !isEmpty(currentConfiguration.crane_details.motor_config)
        ) setMotors(arrayToObject(currentConfiguration.crane_details.motor_config, 'uuid'));
        else setMotors({});
    };

    const handleAddButtonClick = () => {
        setVfd({});
        setShowDetails(true);
    };

    const handleDetailsClick = (vfd) => {
        setVfd(vfd);
        setShowDetails(true);
    };

    const handleCloseButtonClick = () => {
        setShowDetails(false);
    };

    if (showDetails) return (
        <VfdDetails
          {...props}
          currentConfiguration={currentConfiguration}
          motions={motions}
          vfd={vfd}
          onCloseButtonClick={handleCloseButtonClick}
        />
    );

    return (
        <VfdsList
          {...props}
          currentConfiguration={currentConfiguration}
          motions={motions}
          motors={motors}
          onDetailsClick={handleDetailsClick}
          onAddButtonClick={handleAddButtonClick}
        />
    );
}
