import ApiClient from './ApiClient';

const apiClient = ApiClient('/bearings');

export const createBearing = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateBearing = (craneId, bearingId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${bearingId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertBearing = (craneId, payload, onSuccess, onError, onDone) => {
    const bearingId = payload.id;
    delete payload.id;
    if (bearingId) updateBearing(craneId, bearingId, payload, onSuccess, onError, onDone);
    else createBearing(craneId, payload, onSuccess, onError, onDone);
};

export const deleteBearing = (craneId, bearingId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/${bearingId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getBearingsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};
