import ApiClient from './ApiClient';

const apiClient = ApiClient('/reports');

export const createMaintenanceItemTemplate = (reportTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${reportTemplateId}/maintenance/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateMaintenanceItemTemplate = (reportTemplateId, maintenanceItemTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertMaintenanceItemTemplate = (reportTemplateId, payload, onSuccess, onError, onDone) => {
    const maintenanceItemTemplateId = payload.id;
    delete payload.id;
    if (maintenanceItemTemplateId) updateMaintenanceItemTemplate(reportTemplateId, maintenanceItemTemplateId, payload, onSuccess, onError, onDone);
    else createMaintenanceItemTemplate(reportTemplateId, payload, onSuccess, onError, onDone);
};

export const deleteMaintenanceItemTemplate = (reportTemplateId, maintenanceItemTemplateId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceItemTemplatesList = (reportTemplateId, onSuccess, onError, onDone) => {
    apiClient.get(`/${reportTemplateId}/maintenance/`).then(onSuccess).catch(onError).finally(onDone);
};
