import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertEvent} from 'api/EventsApi';
import {eventActions} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import EventConfigurationForm from './EventConfigurationForm';

const DEFAULT_INSTANCE = {
    id: '',
    event_name: '',
    motion_reference: '',
    event_key: '',
    event_condition: '',
    event_value: '',
    event_seconds: '',
    event_actions: [],
    ts: 0,
};
const VALIDATION_SCHEMA = Yup.object().shape({
    event_name: Yup.string().required('Name is required'),
    motion_reference: Yup.string().required('Motion is required'),
    event_key: Yup.string().required('Motion parameter is required'),
    event_condition: Yup.string().required('Condition is required'),
    event_value: Yup.number().required('Value is required'),
    event_seconds: Yup.number().required('Choose the right value for seconds'),
});

export default function EventDetails({
    pageState, currentVersion, currentConfiguration, onDataUpdate, event, eventParams, onCloseButtonClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [event, pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(event)) {
                setInitialValues(oldData => {
                    const newData = {};
                    for (const [key, value] of Object.entries(oldData)) {
                        if (event.hasOwnProperty(key)) newData[key] = event[key];
                        else newData[key] = value;
                    }
                    newData.motion_reference = event.event_formula.motion_reference;
                    newData.event_key = event.event_formula.key;
                    newData.event_condition = event.event_formula.condition;
                    newData.event_value = event.event_formula.value;
                    newData.event_actions = event.event_actions.map(item => item.toString());
                    Object.keys(eventActions).map(key => {
                        newData[`event_action_${key}`] = event.event_actions.includes(parseInt(key));
                    });
                    newData.ts = Date.now();
                    return newData;
                });
            }
            else {
                setInitialValues({...DEFAULT_INSTANCE, ts: Date.now()});
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            id: values.id,
            event_name: values.event_name,
            event_formula: {
                type: 'motion',
                motion_reference: Number(values.motion_reference),
                key: values.event_key,
                condition: values.event_condition,
                value: parseFloat(values.event_value),
            },
            event_actions: values.event_actions.map(item => Number(item)),
            event_seconds: Number(values.event_seconds),
        };
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertEvent(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Event configuration has been successfully saved');
            onDataUpdate();
            onCloseButtonClick();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) {
            setErrors(error.response.data.errors);
        }
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <EventConfigurationForm
                  {...props}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  currentConfiguration={currentConfiguration}
                  eventParams={eventParams}
                  onCloseButtonClick={onCloseButtonClick}
                />
            )}
        </Formik>
    );
}
