import ApiClient from './ApiClient';

const apiClient = ApiClient('/export');

export const getDataExportsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const requestDataExport = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getDataExportDetails = (craneId, dataExportId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${dataExportId}/`).then(onSuccess).catch(onError).finally(onDone);
};
