import React, {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {isAdmin} from 'utils/Auth';
import DocumentEdit from './DocumentEdit';
import DocumentsList from './DocumentsList';

export default function CraneDocuments({crane}) {
    const isMounted= useRef(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [documentObject, setDocumentObject] = useState({});
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleAddButtonClick = () => {
        setDocumentObject({});
        setIsEditMode(true);
    };

    const handleCloseButtonClick = () => {
        setIsEditMode(false);
    };

    const handleEditClick = (document) => {
        setDocumentObject(document);
        setIsEditMode(true);
    };

    const handleDataSubmitted = () => {
        setIsEditMode(false);
    };

    let button;
    if (isEditMode) button = <button className="btn btn-close float-right" onClick={handleCloseButtonClick}>Back</button>;
    else if (isEditable) button = <button className="btn btn-add float-right" onClick={handleAddButtonClick}>Add</button>;

    let title = 'Documents';
    let content;
    if (isEditMode) {
        title = 'Document';
        content = (
            <DocumentEdit
              crane={crane}
              documentObject={documentObject}
              isEditable={isEditable}
              onDataSubmitted={handleDataSubmitted}
            />
        );
    }
    else content = <DocumentsList crane={crane} isEditable={isEditable} onEditClick={handleEditClick} />;

    return (
        <div className="content">
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">{title}</span>
                    {button}
                </Col>
            </Row>
            {content}
        </div>
    );
}
