import React from 'react';
import {Col, Form} from 'react-bootstrap';
import {useField} from 'formik';
import Checkbox from './Checkbox';

export default function MultiCheckboxes({label, name, options, ...props}) {
    const [field, meta] = useField(name);
    return (
        <Col>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Group as={Col} size="lg">
                {options.map((option, index) => {
                    const key = `option-${name}-${index}`;
                    return <Checkbox key={key} id={key} name={name} value={option.value} label={option.label} {...props} />
                })}
                {meta.error && <Form.Text className="text-muted text-danger">{meta.error}</Form.Text>}
            </Form.Group>
        </Col>
    );
}
