import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteLocation, getLocationsList} from 'api/LocationsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';

let locationObject;

export default function LocationsList({site, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [locations, setLocations] = useState([]);
    const isEditable = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Locations');
        loadLocationsList();
        return () => isMounted.current = false;
    }, [site]);

    const loadLocationsList = () => {
        setIsLoading(true);
        const onSuccess = response => setLocations(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getLocationsList(site && site.customer.id, site && site.id, onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (location) => {
        locationObject = location;
        setDeleteDialogBody(<p>Do you really want to delete the location "<b>{location.location_name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteLocation(locationObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Location has been successfully deleted');
        if (isMounted.current) loadLocationsList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (locations.length) {
        tableContent = locations.map(location => {
            const craneSystemsLink = (
                <Link to={{pathname: '/app/crane-systems', state: {location: location}}}>
                    {location.total_crane_systems}
                </Link>
            );
            const cranesLink = (
                <Link to={{pathname: '/app/cranes', state: {location: location}}}>{location.total_cranes}</Link>
            );
            return (
                <tr key={location.id}>
                    <td>{location.location_id}</td>
                    <td>{location.location_name}</td>
                    <td>{location.customer.customer_name}</td>
                    <td>{location.site.site_name}</td>
                    <td className="text-center">{location.total_crane_systems ? <b>{craneSystemsLink}</b> : location.total_crane_systems}</td>
                    <td className="text-center">{location.total_cranes ? <b>{cranesLink}</b> : location.total_cranes}</td>
                    <td className="text-center">{location.active ? 'Yes' : 'No'}</td>
                    {isEditable &&
                        <>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => onEditClick(location)}>Edit</span>
                            </td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => handleDeleteClick(location)}>Delete</span>
                            </td>
                        </>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7}>No locations found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting location" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Customer</th>
                        <th>Site</th>
                        <th className="text-center">Total crane systems</th>
                        <th className="text-center">Total cranes</th>
                        <th className="text-center">Active</th>
                        {isEditable &&
                            <th colSpan="2" className="text-center">
                                <button className="btn btn-add" onClick={onAddClick}>Add</button>
                            </th>}
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
