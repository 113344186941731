import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';

export default function MaintenanceItemForm({
    dirty, isValid, isSubmitting, handleSubmit,
    isCalendarTime, isOperationTime, isDraft, isEditable, isLoading, onCloseClick,
}) {
    const isMounted = useRef(false);
    const isDisabled = !isEditable || !isDraft || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="name" required disabled={isDisabled} />
            </Row>
            {isCalendarTime &&
                <>
                    <Row>
                        <Input label="Label for calendar time left (days)" type="text" name="calendar_time_left" required disabled={isDisabled} />
                        <Input label="Label for calendar time in operation (days)" type="text" name="calendar_time_in_operation" required disabled={isDisabled} />
                    </Row>
                    <Row>
                        <Input label="Label for recommended service interval (days)" type="text" name="calendar_service_interval" required disabled={isDisabled} />
                        <Input label="Value for recommended service interval (days)" type="number" mim="1" step="1" name="calendar_time_value" required disabled={isDisabled} />
                    </Row>
                </>}
            {isOperationTime &&
                <>
                    <Row>
                        <Input label="Label for operation time left (hours)" type="text" name="operation_time_left" required disabled={isDisabled} />
                        <Input label="Label for time in operation (hours)" type="text" name="operation_time_in_operation" required disabled={isDisabled} />
                    </Row>
                    <Row>
                        <Input label="Label for recommended service interval (hours)" type="text" name="operation_service_interval" required disabled={isDisabled} />
                        <Input label="Value for recommended service interval (hours)" type="number" mim="1" step="1" name="operation_time_value" required disabled={isDisabled} />
                    </Row>
                </>}
            <HiddenInput name="id" />
            {isEditable && isDraft &&
                <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
