import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {DEFAULT_CUSTOMER_OPTION, DEFAULT_LOCATION_OPTION, DEFAULT_SITE_OPTION} from 'constants/uiConstants';
import {getCustomerOptions, getLocationOptions, getSiteOptions} from 'utils/Ui';

export default function CraneSystemForm({
    initialValues, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    useCustomer, useSite, useLocation, customers, sites, locations, isEditable, isLoading, onCloseClick
}) {
    const isMounted = useRef(false);
    const [customerOptions, setCustomerOptions] = useState([DEFAULT_CUSTOMER_OPTION]);
    const [siteOptions, setSiteOptions] = useState([DEFAULT_SITE_OPTION]);
    const [locationOptions, setLocationOptions] = useState([DEFAULT_LOCATION_OPTION]);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        let options;
        if (useCustomer) setCustomerOptions(getCustomerOptions(customers));
        if (useSite) {
            options = getSiteOptions(
                sites, useCustomer, initialValues.customer_uuid,
                false, setFieldValue
            );
            setSiteOptions(options);
        }
        if (useLocation) {
            options = getLocationOptions(
                locations, useSite, initialValues.site_uuid,
                false, setFieldValue
            );
            setLocationOptions(options);
        }
        return () => isMounted.current = false;
    }, [initialValues, customers, sites, locations]);

    const handleCustomerChange = (e) => {
        handleChange(e);
        setSiteOptions(getSiteOptions(sites, useCustomer, e.target.value, true, setFieldValue));
    };

    const handleSiteChange = (e) => {
        handleChange(e);
        setLocationOptions(getLocationOptions(locations, useSite, e.target.value, true, setFieldValue));
    };

    return (
        <Form onSubmit={handleSubmit}>
            {useCustomer &&
                <Row>
                    <Select
                      label="Customer" name="customer_uuid"
                      options={customerOptions} onChange={handleCustomerChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {useSite &&
                <Row>
                    <Select
                      label="Site" name="site_uuid"
                      options={siteOptions} onChange={handleSiteChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {useLocation &&
                <Row>
                    <Select label="Location" name="location_uuid" options={locationOptions} required disabled={isDisabled} />
                </Row>}
            <Row>
                <Input label="Crane system ID" type="text" name="crane_system_id" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Crane system name" type="text" name="crane_system_name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
}
