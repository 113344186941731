import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Col, OverlayTrigger, Row, Spinner, Table, Tooltip} from 'react-bootstrap';
import {deleteCustomReport, getCustomReportsList, patchCustomReport} from 'api/CustomReportsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {reportTemplateStatuses} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';

let customReportObject;

export default function CustomReportsList({crane, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [customReports, setCustomReports] = useState([]);
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        loadCustomReportsList(crane);
        return () => isMounted.current = false;
    }, [crane]);

    const loadCustomReportsList = (crane) => {
        setIsLoading(true);
        const onSuccess = response => setCustomReports(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCustomReportsList(crane.id, onSuccess, handleApiError, onDone);
    };

    const handleStatusClick = (customReport) => {
        const payload = {status: customReport.status === 1 ? 2 : 1};
        const onSuccess = response => {
            setCustomReports(oldData => oldData.map(item => item.id === response.data.id ? response.data : item));
        };
        patchCustomReport(crane.id, customReport.id, payload, onSuccess, handleApiError);
    };

    const handleDeleteClick = (customReport) => {
        customReportObject = customReport;
        setDeleteDialogBody(<p>Do you really want to delete the custom report "<b>{customReport.name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteCustomReport(crane.id, customReportObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Custom report has been successfully deleted');
        if (isMounted.current) loadCustomReportsList(crane);
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan="7" className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (customReports.length) {
        tableContent = customReports.map(customReport => {
            const isDraft = customReport.status === 1;
            const status = isEditable ? (
                <OverlayTrigger
                  key={`overlay-${customReport.id}`}
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${customReport.id}`}>Change to {isDraft ? 'published' : 'draft'}</Tooltip>}
                >
                    <span className="text-primary text-link" onClick={() => handleStatusClick(customReport)}>
                        {reportTemplateStatuses[customReport.status]}
                    </span>
                </OverlayTrigger>
            ) : reportTemplateStatuses[customReport.status];
            return (
                <tr key={customReport.id}>
                    <td>{customReport.name}</td>
                    <td>{customReport.report_template.name}</td>
                    <td>{customReport.motion.name}</td>
                    <td className="text-center" style={{fontWeight: 'bold'}}>
                        <Link to={{pathname: '/app/cranes-maintenance-items', state: {crane: crane, customReport: customReport}}}>
                            {customReport.maintenance_items_count}
                        </Link>
                    </td>
                    <td className="text-center">{status}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(customReport)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                    {isEditable &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(customReport)}>Delete</span>
                        </td>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan="7">No custom reports found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting custom report" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Row>
                <Col>
                    <span className="text-title title-header">Custom reports</span>
                    {isEditable && <button className="btn btn-add float-right" onClick={onAddClick}>Add</button>}
                    <button className="btn btn-add float-right" onClick={() => history.goBack()}>Back</button>
                </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Template</th>
                        <th>Motion</th>
                        <th className="text-center">Maintenance items</th>
                        <th className="text-center">Status</th>
                        <th colSpan="2" />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
