import React, {useEffect, useState} from 'react';
import {Col, Form, Pagination, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import moment from 'moment';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {FAULTS} from 'constants/uiConstants';
import {arrayToObject, objectToOptions} from 'utils/Utils';

const filterStyle = {display: 'inline-block', marginRight: 10};
const faultCodeOptions = [
    {label: '-- Select a fault code --', value: ''},
    ...objectToOptions(FAULTS)
];
const DEFAULT_MOTION_OPTION = {label: '-- Select a motion --', value: ''};

export default function FaultsFilters({motionsList, isLoading, setFilters}) {
    const [motionOptions, setMotionOptions] = useState([DEFAULT_MOTION_OPTION]);
    const [page, setPage] = useState(1);
    const initialValues = {
        motion_uuid: '',
    };

    useEffect(() => {
        setMotionOptions([
            DEFAULT_MOTION_OPTION,
            ...objectToOptions(
                arrayToObject(motionsList, 'motion_uuid', 'motion_name')
            )
        ]);
    }, [motionsList]);

    const handlePageClick = (direction) => {
        setFilters(prevFilters => {
            let page = prevFilters.offset / prevFilters.limit + 1;
            if (direction === 'forward') page += 1;
            else page -= 1;
            setPage(page);
            const offset = (page - 1) * prevFilters.limit;
            return {...prevFilters, offset: offset};
        });
    };

    const form = (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
            {({handleChange}) => {
                const handleInputChange = (e) => {
                    handleChange(e);
                    const filterName = e.target.name;
                    let filterValue = e.target.value;
                    if (filterName === 'created_from' || filterName === 'created_to') {
                        if (filterValue) {
                            filterValue = moment(filterValue);
                            if (filterName === 'created_to') {
                                filterValue.set({h: 23, m: 59, s: 59, ms: 999});
                            }
                            filterValue = filterValue.toISOString();
                        }
                    }
                    setFilters(prevFilters => {
                        setPage(1);
                        if (filterValue) {
                            return {...prevFilters, [filterName]: filterValue, offset: 0};
                        }
                        else {
                            const newFilters = {...prevFilters, offset: 0};
                            delete newFilters[filterName];
                            return newFilters;
                        }
                    });
                };

                return (
                    <Form>
                        <div style={filterStyle}>
                            <Select
                              label="Motion" name="motion_uuid" options={motionOptions} skipgroup="true"
                              onChange={handleInputChange} disabled={isLoading}
                            />
                        </div>
                        <div style={filterStyle}>
                            <Input
                              label="Date from" type="date" name="created_from" skipgroup="true"
                              onChange={handleInputChange} style={{maxWidth: 150}} disabled={isLoading}
                            />
                        </div>
                        <div style={filterStyle}>
                            <Input
                              label="Date from" type="date" name="created_to" skipgroup="true"
                              onChange={handleInputChange} style={{maxWidth: 150}} disabled={isLoading}
                            />
                        </div>
                        {/*<div style={filterStyle}>*/}
                        {/*    <Select*/}
                        {/*      label="Fault code" name="fault_code" options={faultCodeOptions} skipgroup="true"*/}
                        {/*      onChange={handleInputChange} disabled={isLoading}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </Form>
                );
            }}
        </Formik>
    );

    return (
        <Row>
            <Col md="8">
                {form}
            </Col>
            <Col md="4">
                <Pagination className="pagination-sm ac-pagination">
                    <Pagination.Prev className="ac-page-link" onClick={() => handlePageClick('backward')} disabled={page<2} />
                    <Pagination.Item className="ac-page-item" active>{page}</Pagination.Item>
                    <Pagination.Next className="ac-page-link" onClick={() => handlePageClick('forward')} />
                </Pagination>
            </Col>
        </Row>
    );
}
