import React, {useEffect, useRef, useState} from 'react';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteUser, getUsersList} from 'api/UsersApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {userRoles} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {authenticatedUserId, isAdmin, isCustomerAdmin, isSystemAdmin, USER_ROLES} from 'utils/Auth';

let userObject;

export default function UsersList({onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Users');
        loadUsersList();
        return () => isMounted.current = false;
    }, []);

    const loadUsersList = () => {
        setIsLoading(true);
        const onSuccess = response => setUsers(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getUsersList(onSuccess, handleApiError, onDone);
    };

    const isUserEditable = user => USER_ROLES.includes(user.role) || isSystemAdmin() || isCustomerAdmin();

    const isUserDeletable = user => isUserEditable(user) && user.id !== authenticatedUserId();

    const handleDeleteClick = user => {
        userObject = user;
        setDeleteDialogBody(<p>Do you really want to delete the user "<b>{user.username} ({user.email})</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteUser(userObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = response => {
        showMessage('success', 'User has been successfully deleted');
        if (isMounted.current) loadUsersList();
    };

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan={isEditable ? 10 : 8} className="text-center"><Spinner animation="border" /></td></tr>;
    else if (users.length === 0) tableContent = <tr><td colSpan={isEditable ? 10 : 8}>No users found</td></tr>;
    else tableContent = users.map(user => (
        <tr key={user.id}>
            <td>{user.email}</td>
            <td>{user.username}</td>
            <td>{userRoles[user.role]}</td>
            <td>{user.customer && user.customer.customer_name}</td>
            <td>{user.site && user.site.site_name}</td>
            <td>{user.location && user.location.location_name}</td>
            <td>{user.crane_system && user.crane_system.crane_system_name}</td>
            <td className="text-center">{user.active ? 'Yes' : 'No'}</td>
            {isEditable &&
                <>
                    <td className="text-center">
                        {isUserEditable(user) ?
                            <span className="text-primary text-link" onClick={() => onEditClick(user)}>Edit</span> :
                            <span className="text-primary text-not-allowed">Edit</span>}
                    </td>
                    <td className="text-center">
                        {isUserDeletable(user) ?
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(user)}>Delete</span> :
                            <span className="text-primary text-not-allowed">Delete</span>}
                    </td>
                </>}
        </tr>
        ));

    return (
        <>
            <ModalDialog title="Deleting user" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Customer</th>
                        <th>Site</th>
                        <th>Location</th>
                        <th>Crane system</th>
                        <th className="text-center">Active</th>
                        {isEditable &&
                            <th colSpan="2" className="text-center">
                                <button className="btn btn-add" onClick={onAddClick}>Add</button>
                            </th>}
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
