import React, {useEffect, useState} from 'react';
import {Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import {arrayToObject, objectToOptions} from 'utils/Utils';

const DEFAULT_PARAMETERS = ['run_time', 'load_cycle_count'];

export default function OdometerChartForm({odometerParameters, setSelectedParameters}) {
    const [parameterOptions, setParameterOptions] = useState([]);
    const initialValues = {
        parameters: [...DEFAULT_PARAMETERS],
    };
    const validationRules = {
        parameters: Yup.array().of(Yup.string()).min(1, 'At least one parameter is required').max(2, 'At most two parameters are allowed'),
    };

    useEffect(() => {
        setParameterOptions(
            objectToOptions(
                arrayToObject(odometerParameters, 'name', 'display')
            )
        );
    }, [odometerParameters]);

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationRules)}
          onSubmit={() => {}}
        >
            {({values}) => {

                useEffect(() => {
                    setSelectedParameters(values.parameters);
                }, [values]);

                return (
                    <Form>
                        {parameterOptions.length > 0 &&
                            <Row>
                                <MultiCheckboxes label="Parameters" name="parameters" options={parameterOptions} />
                            </Row>}
                    </Form>
                );
            }}
        </Formik>
    );
}
