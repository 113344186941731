import React from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {alertTypeOptions} from 'constants/apiConstants';

export default function CustomEventForm({
    dirty, isValid, isSubmitting, handleSubmit,
    isCalendarTime, isOperationTime, isDraft, isEditable, isLoading, onCloseClick,
}) {
    const isDisabled = !isEditable || !isDraft || isLoading;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Alert type" name="alert_type" options={alertTypeOptions} disabled />
            </Row>
            <Row>
                <Input label="Alert text" type="text" name="alert_text" required disabled={isDisabled} />
            </Row>
            <Row>
                {isCalendarTime &&
                    <Input label="Service interval (days) percentage" type="number" min="1" max="100" step="1" name="calendar_time_value" required disabled={isDisabled} />}
                {isOperationTime &&
                    <Input label="Service interval (hours) percentage" type="number" min="1" max="100" step="1" name="operation_time_value" required disabled={isDisabled} />}
            </Row>
            <HiddenInput name="id" />
            {isEditable && isDraft &&
                <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
