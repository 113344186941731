import ApiClient from './ApiClient';

const apiClient = ApiClient('/cranes');

export const createConfiguration = (craneId, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/versions/`).then(onSuccess).catch(onError).finally(onDone);
};

export const publishConfiguration = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/publish/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getConfigurationsList = (craneId, limit, onSuccess, onError, onDone) => {
    limit = limit || 3;
    apiClient.get(`/${craneId}/versions/?limit=${limit}`).then(onSuccess).catch(onError).finally(onDone);
};

export const getConfigurationStatus = (craneId, version, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/versions/${version}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getLatestPublishedConfiguration = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/config/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getSpecifiedConfiguration = (craneId, version, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/config/${version}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getConfigurationsDifference = (craneId, versionOld, versionNew, onSuccess, onError, onDone) => {
    versionNew = versionNew !== undefined ? `/${versionNew}` : '';
    apiClient.get(`/${craneId}/diff/${versionOld}${versionNew}/`).then(onSuccess).catch(onError).finally(onDone);
};
