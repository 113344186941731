import React, {useEffect, useRef, useState} from 'react';
import {Table, Spinner} from 'react-bootstrap';
import {capacityUnits, motionTypes} from 'constants/dataConstants';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';

export default function MotionsList({pageState, currentVersion, currentConfiguration, onDetailsClick, onAddButtonClick}) {
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [motions, setMotions] = useState([]);
    const [motionNames, setMotionNames] = useState({});
    const [vfdNames, setVfdNames] = useState({});
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getMotions();
        return () => isMounted.current = false;
    }, [pageState, currentVersion, currentConfiguration]);

    const getMotions = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.crane_details)) {
                setMotions(currentConfiguration.crane_details.motion_config);
                let names = {};
                currentConfiguration.crane_details.motion_config.map(motion => {
                    names[motion.id] = motion.motion_name;
                });
                setMotionNames(names);
                names = {};
                currentConfiguration.crane_details.vfd_config.map(vfd => {
                    names[vfd.vfd_uuid] = vfd.vfd_name;
                });
                setVfdNames(names);
            }
            setIsLoading(false);
        }
    };

    let addButton;
    if (isEditable) addButton = <button className="btn btn-add" onClick={onAddButtonClick}>Add</button>;

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan="6" className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (motions.length) {
        tableContent = motions.map(motion => {
            let motionVfds = motion.motion_vfd_mapping ? motion.motion_vfd_mapping.map(item => vfdNames[item]).join(', ') : '-';
            return (
                <tr key={motion.id}>
                    <td>{motion.motion_name}</td>
                    <td>{motionTypes[motion.motion_type]}</td>
                    <td>{motion.motion_capacity + ' ' + capacityUnits[motion.motion_capacity_unit]}</td>
                    <td>{motionVfds}</td>
                    <td>{motionNames[motion.motion_reference]}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onDetailsClick(motion)}>{isEditable ? 'Edit' : 'View'}</span>
                    </td>
                </tr>
            )
        });
    }
    else {
        tableContent = <tr><td colSpan="6">No motions found</td></tr>;
    }

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Motion Type</th>
                    <th>Capacity</th>
                    <th>VFD</th>
                    <th>Installed On</th>
                    <th className="text-center">{addButton}</th>
                </tr>
            </thead>
            <tbody>{tableContent}</tbody>
        </Table>
    );
}
