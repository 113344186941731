import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import MaintenanceAlertDetails from './MaintenanceAlertDetails';
import MaintenanceAlertsList from './MaintenanceAlertsList';

export default function MaintenanceAlerts() {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [showDetails, setShowDetails] = useState(false);
    const [maintenanceAlert, setMaintenanceAlert] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/notifications');
        setPageTitle('Maintenance alerts');
        return () => isMounted.current = false;
    }, []);

    const handleDetailsClick = (maintenanceAlert) => {
        setMaintenanceAlert(maintenanceAlert);
        setShowDetails(true);
    };

    const handleCloseClick = () => setShowDetails(false);

    if (showDetails) return <MaintenanceAlertDetails maintenanceAlert={maintenanceAlert} onCloseClick={handleCloseClick} />;
    return <MaintenanceAlertsList onDetailsClick={handleDetailsClick} />
}
