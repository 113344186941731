import React, {useEffect, useRef, useState} from 'react';
import {Table} from 'react-bootstrap';
import moment from 'moment';
import {DATE_FORMAT, DATETIME_SHORT_FORMAT} from 'constants/uiConstants';
import {mdash} from 'utils/Ui';

export default function BearingLifeData({bearing}) {
    const isMounted= useRef(false);
    const [data, setData] = useState({});

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const bl = bearing.bearing_life;
    const serviceDate = moment(bearing.service_date).utc(true).format(DATE_FORMAT);
    const updatedDate = bl && moment(bl.updated_date).utc(true).format(DATETIME_SHORT_FORMAT) || mdash;
    const accumulatedOperatingTime = bl && bl.accumulated_operating_time.toFixed(1) || mdash;
    const averageOperatingSpeed = bl && Math.round(bl.average_operating_speed) || mdash;
    const averageOperatingLoad = bl && Math.round(bl.average_operating_load) || mdash;
    const lifeConsumed = bl && (bl.life_consumed * 100).toFixed(1) + '%' || mdash;
    const lifeRemaining = bl && (bl.life_remaining * 100).toFixed(1) + '%' || mdash;
    const remainingLifeAtRatedLoad = bl && Math.round(bl.remaining_life_at_rated_load) || mdash;
    const remainingLifeAtAvgOpLoad = bl && Math.round(bl.remaining_life_at_avg_op_load) || mdash;

    return (
        <Table striped bordered hover size="sm" id="bearing-life-data">
            <tbody>
                <tr>
                    <td>Date placed in service</td>
                    <td />
                    <td className="text-center">{serviceDate}</td>
                </tr>
                <tr>
                    <td>CMAA service class</td>
                    <td />
                    <td className="text-center">{bearing.cmaa_crane_service_classification}</td>
                </tr>
                <tr>
                    <td>CMAA minimum life expectancy requirement</td>
                    <td className="text-center">hours</td>
                    <td className="text-center">{bearing.bearing_life_expectancy}</td>
                </tr>
                <tr>
                    <td>CMAA accumulated bearing operating time</td>
                    <td className="text-center">hours</td>
                    <td className="text-center">{accumulatedOperatingTime}</td>
                </tr>
                <tr>
                    <td colSpan="3">
                        Note: The CMAA bearing life tracking is provided for reference purposes to track the actual
                        accumulated bearing operating hours relative to the CMAA minimum life expectancy requirements
                        for bearings as a function of CMAA service classification.
                    </td>
                </tr>
                <tr>
                    <td>Average bearing operating speed</td>
                    <td className="text-center">rpm</td>
                    <td className="text-center">{averageOperatingSpeed}</td>
                </tr>
                <tr>
                    <td>Average bearing operating load</td>
                    <td className="text-center">lbf</td>
                    <td className="text-center">{averageOperatingLoad}</td>
                </tr>
                <tr>
                    <td>Theoretical design bearing life consumed</td>
                    <td className="text-center">%</td>
                    <td className="text-center">{lifeConsumed}</td>
                </tr>
                <tr>
                    <td>Theoretical design bearing life remaining</td>
                    <td className="text-center">%</td>
                    <td className="text-center">{lifeRemaining}</td>
                </tr>
                <tr>
                    <td>Remaining design bearing life @ rated load</td>
                    <td className="text-center">hours</td>
                    <td className="text-center">{remainingLifeAtRatedLoad}</td>
                </tr>
                <tr>
                    <td>Remaining design bearing life @ avg op load</td>
                    <td className="text-center">hours</td>
                    <td className="text-center">{remainingLifeAtAvgOpLoad}</td>
                </tr>
                <tr>
                    <td colSpan="3">
                        Note: The L10 bearing theoretical design life tracking is provided for reference purposes only,
                        and is intended as an aid to maintenance planning. Actual operating bearing life can be less
                        than the theoretical design life and is dependent upon many factors, among them the operating
                        environment (temperature, cleanliness, humidity...) and more significantly, the presence
                        of an adequate amount and type of lubricant in the bearing over time, which is dependent upon
                        end users maintenance program.<br />
                        Bearing replacement interval should be determined by customer based on application and the
                        associated criticality / impact of any resulting down time in the event of a bearing failure.
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3" className="text-right">Last calculation performed at {updatedDate}</td>
                </tr>
            </tfoot>
        </Table>
    );
}
