import React, {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {isAdmin} from 'utils/Auth';
import BearingEdit from './BearingEdit';
import BearingsList from './BearingsList';

export default function CraneBearings({crane, motionsList}) {
    const isMounted= useRef(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [bearingObject, setBearingObject] = useState({});
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleAddButtonClick = () => {
        setBearingObject({});
        setIsEditMode(true);
    };

    const handleCloseButtonClick = () => {
        setIsEditMode(false);
    };

    const handleEditClick = (bearing) => {
        setBearingObject(bearing);
        setIsEditMode(true);
    };

    const handleDataSubmitted = () => {
        setIsEditMode(false);
    };

    let button;
    if (isEditMode) button = <button className="btn btn-close float-right" onClick={handleCloseButtonClick}>Back</button>;
    else if (isEditable) button = <button className="btn btn-add float-right" onClick={handleAddButtonClick}>Add</button>;

    let title = 'Bearings';
    let content;
    if (isEditMode) {
        title = 'Bearing';
        content = (
            <BearingEdit
              crane={crane}
              bearingObject={bearingObject}
              motionsList={motionsList}
              isEditable={isEditable}
              onDataSubmitted={handleDataSubmitted}
            />
        );
    }
    else content = <BearingsList crane={crane} isEditable={isEditable} onEditClick={handleEditClick} />;

    return (
        <div className="content">
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">{title}</span>
                    {button}
                </Col>
            </Row>
            {content}
        </div>
    );
}
