import React, {Component, useState} from 'react'
import axios from 'axios'
import { useHistory, Redirect } from 'react-router-dom'
import { Form, Button, Navbar, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from "react-toastify"

import GetPassword from './GetPassword'
import ForgotPassword from './ForgotPassword'

class GetOtp extends Component{
    constructor (props) {
        super(props)
        let emailObj = props.emailObj ? props.emailObj : ''
        this.state = {
            email: emailObj,
            otp : '',
            flag: false,
            emailflag: false,
            messageType: null,
            messageText: null
        }
    }

    createMessage = (type, text) => {
        this.setState({
            messageType: type,
            messageText: text,
        })
        setTimeout(() => {
            this.removeMessage()
        }, 3000)
    }

    createToast = (text) =>{
        toast.error(text, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true
        })
    }

    removeMessage = () => {
        this.setState({
            messageType: null,
            messageText: null,
        })
    }

    setFlag = () =>{
        this.setState({
            emailflag:true
        })
    }

    sendOtp = () =>{
         const postData = {}
         postData.email = this.state.email
         const url = `${window.REST_API_URL}/users/forgot/otp/`
         axios.post(url, postData)
            .then(function (response) {
                alert(response.data.message)
            })
            .catch(function (error) {
                if(error.response){
                    this.createMessage('warning', 'Incorrect email Id. Kindly check')
                }
            })
    }
    render () {
        if(this.state.emailflag){
          return (
            <div className='content'>
                <ForgotPassword emailObj={this.state.email} />
            </div>
          )
        }
        return (
            <div>
                <Formik
                  initialValues={{
                    otp: '',
                  }}
                  validationSchema={Yup.object().shape({
                    otp: Yup.string()
                    .required('OTP is required.')
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        const postData = {}
                        postData.email = this.state.email
                        postData.otp = values.otp
                        const url = `${window.REST_API_URL}/users/verify/otp/`
                        axios.post(url, postData)
                            .then((response) => {
                                    setSubmitting(true)
                                    this.setState({
                                        email:postData.email,
                                        otp:postData.otp,
                                        flag: true
                                    })
                                })
                            .catch((error)=> {
                                if(error.response){
                                    setSubmitting(false)
                                    this.createMessage('warning', 'Incorrect OTP.')
                                    this.createToast("Incorrect OTP")
                                }
                            })

                    }, 500)
                  }}
                >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                            handleBlur,
                            handleSubmit
                    } = props;
                    return (
                        <div className='content'>
                            {this.state.flag ?
                                <GetPassword emailObj={this.state.email} otpObj={this.state.otp} />
                            :
                            <Form onSubmit={handleSubmit}>
                                <Alert variant={this.state.messageType}>
                                    {this.state.messageText}
                                </Alert>
                                <p style={{textAlignVertical: "center",textAlign: "center",color: 'primary'}}>OTP will be sent to your email ID {this.state.email}. Kindly check your email for the OTP </p>
                                <Form.Group size='lg' controlId='otp'>
                                    <Form.Label>OTP</Form.Label>
                                    <Form.Control
                                      name='otp'
                                      id='input-otp'
                                      className={
                                        errors.otp && touched.otp ? 'input error' : 'input'
                                      }
                                      type='otp'
                                      placeholder='Enter OTP'
                                      value={values.otp}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.otp && touched.otp && (
                                      <Form.Text className="text-muted text-danger">{errors.otp}</Form.Text>
                                    )}
                                </Form.Group>
                                <Button block size='lg' type='submit' disabled={isSubmitting}>
                                    Submit
                                </Button>
                                <ToastContainer />
                                <p className='card-category' onClick={() => this.sendOtp()} style={{cursor:'pointer',textAlignVertical: "center",textAlign: "center",color: 'primary'}}>Resend OTP</p>
                                <p className='card-category' onClick={() => this.setFlag()} style={{cursor:'pointer',textAlignVertical: "center",textAlign: "center",color: 'primary'}}>Change Mail Id</p>

                            </Form>}
                        </div>
                    )
                }}
                </Formik>
            </div>
        )
    }
}

export default GetOtp
