import ApiClient from './ApiClient';

const apiClient = ApiClient('/reports');

export const createEventTemplate = (reportTemplateId, maintenanceItemTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/events/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateEventTemplate = (reportTemplateId, maintenanceItemTemplateId, eventTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/events/${eventTemplateId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertEventTemplate = (reportTemplateId, maintenanceItemTemplateId, payload, onSuccess, onError, onDone) => {
    const eventTemplateId = payload.id;
    delete payload.id;
    if (eventTemplateId) updateEventTemplate(reportTemplateId, maintenanceItemTemplateId, eventTemplateId, payload, onSuccess, onError, onDone);
    else createEventTemplate(reportTemplateId, maintenanceItemTemplateId, payload, onSuccess, onError, onDone);
};

export const deleteEventTemplate = (reportTemplateId, maintenanceItemTemplateId, eventTemplateId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/events/${eventTemplateId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getEventTemplatesList = (reportTemplateId, maintenanceItemTemplateId, onSuccess, onError, onDone) => {
    apiClient.get(`/${reportTemplateId}/maintenance/${maintenanceItemTemplateId}/events/`).then(onSuccess).catch(onError).finally(onDone);
};
