import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertMaintenanceItemTemplate} from 'api/MaintenanceItemTemplatesApi';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import MaintenanceItemTemplateForm from './MaintenanceItemTemplateForm';

const DEFAULT_INSTANCE = {
    id: '',
    name: '',
    calendar_time_left: 'REMAINING TIME',
    calendar_time_in_operation: 'TIME ELAPSED',
    calendar_service_interval: 'SERVICE INTERVAL',
    operation_time_left: 'REMAINING TIME',
    operation_time_in_operation: 'OPERATING HOURS',
    operation_service_interval: 'SERVICE INTERVAL',
};

export default function MaintenanceItemTemplateDetails({reportTemplate, maintenanceItemTemplate, onCloseClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});

    const isCalendarTime = reportTemplate.params.includes('calendar_time_in_operation');
    const isOperationTime = reportTemplate.params.includes('operation_time');
    const isDraft = reportTemplate.status === 1;
    const isEditable = isSystemAdmin();

    let validationRules = {
        name: Yup.string().required('Name is required'),
    };
    if (isCalendarTime) validationRules = {
        ...validationRules,
        calendar_time_left: Yup.string().required('Calendar time is required'),
        calendar_time_in_operation: Yup.string().required('Calendar time in operation is required'),
        calendar_service_interval: Yup.string().required('Recommended service interval is required'),
    };
    if (isOperationTime) validationRules = {
        ...validationRules,
        operation_time_left: Yup.string().required('Operation time is required'),
        operation_time_in_operation: Yup.string().required('Time in operation is required'),
        operation_service_interval: Yup.string().required('Recommmended service interval is required'),
    };
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(isEmpty(maintenanceItemTemplate) ? 'New maintenance item template' : 'Maintenance item template');
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = maintenanceItemTemplate.hasOwnProperty(key) ? maintenanceItemTemplate[key] : value;
                }
                if (!isEmpty(maintenanceItemTemplate.labels)) {
                    maintenanceItemTemplate.labels.forEach(label => {
                        let prefix;
                        if (label.param === 'calendar_time_in_operation') prefix = 'calendar';
                        else if (label.param === 'operation_time') prefix = 'operation';
                        if (prefix) {
                            newData[`${prefix}_time_left`] = label.time_left;
                            newData[`${prefix}_time_in_operation`] = label.time_in_operation;
                            newData[`${prefix}_service_interval`] = label.service_interval;
                        }
                    });
                }
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            id: values.id,
            name: values.name,
            labels: [],
        };
        if (isCalendarTime) {
            payload.labels.push({
                param: 'calendar_time_in_operation',
                time_left: values.calendar_time_left,
                time_in_operation: values.calendar_time_in_operation,
                service_interval: values.calendar_service_interval,
            });
        }
        if (isOperationTime) {
            payload.labels.push({
                param: 'operation_time',
                time_left: values.operation_time_left,
                time_in_operation: values.operation_time_in_operation,
                service_interval: values.operation_service_interval,
            });
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertMaintenanceItemTemplate(reportTemplate.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Maintenance item template has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <MaintenanceItemTemplateForm
                  {...props}
                  isCalendarTime={isCalendarTime}
                  isOperationTime={isOperationTime}
                  isDraft={isDraft}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
