import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import {getMaintenanceReport} from 'api/MaintenanceApi';
import CustomReport from 'components/Maintenance/CustomReport';
import {useAppContext} from 'contexts/AppContext';

export default function MaintenanceAlertDetails({maintenanceAlert, onCloseClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [customReport, setCustomReport] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Maintenance alert');
        loadCustomReport();
        return () => isMounted.current = false;
    }, [maintenanceAlert]);

    const loadCustomReport = () => {
        setIsLoading(true);
        const onSuccess = response => setCustomReport(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getMaintenanceReport(maintenanceAlert.crane.id, maintenanceAlert.report.id, onSuccess, handleApiError, onDone);
    }

    let pageContent;
    if (isLoading) pageContent = <div className="text-center"><Spinner animation="border" /></div>;
    else pageContent = <CustomReport customReport={customReport} />;

    const customerDetails = maintenanceAlert.customer;

    return (
        <>
            <Row>
                <Col>
                    <span className="text-title title-header">{maintenanceAlert.crane.crane_name}</span>
                    <button className="btn btn-primary float-right" style={{borderRadius: '25px'}} onClick={onCloseClick}>Back</button>
                </Col>
            </Row>
            <Row>
                <Col md="8">
                    <p className="card-category" style={{fontSize: '14px'}}>
                        {customerDetails.customer_name}
                        &nbsp;-&nbsp;{customerDetails.site_name}
                        &nbsp;-&nbsp;{customerDetails.location_name}
                        {customerDetails.crane_system_name && ` - ${customerDetails.crane_system_name}`}
                    </p>
                </Col>
                <Col md="4">
                    <p className="card-category" style={{fontSize: '14px', alignSelf: 'flex-start', textAlign: 'right'}}>
                        Report status as of today
                    </p>
                </Col>
            </Row>
            {pageContent}
        </>
    );
}
