import React, {useEffect, useRef, useState} from 'react';
import {Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {getDataExportDetails, getDataExportsList} from 'api/DataExportsApi';
import {dataExportStatuses, dataExportTypes} from 'constants/dataConstants';
import {DATETIME_SHORT_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';

export default function DataExportsList({crane}) {
    const isMounted= useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [dataExports, setDataExports] = useState([]);
    const timeouts = {};

    useEffect(() => {
        isMounted.current = true;
        loadDataExportsList();
        return () => isMounted.current = false;
    }, [crane]);

    const clearAllTimeouts = () => {
        Object.values(timeouts).map(timeoutId => clearTimeout(timeoutId));
    };

    const loadDataExportsList = () => {
        setIsLoading(true);
        clearAllTimeouts();
        const onSuccess = response => {
            if (!isMounted.current) return;
            setDataExports(response.data);
            response.data.forEach(dataExport => {
                if (dataExport.status === 1) {
                    timeouts[dataExport.id] = setTimeout(() => updateDataExportStatus(dataExport), 1000);
                }
            });
        }
        const onDone = () => isMounted.current && setIsLoading(false);
        getDataExportsList(crane.id, onSuccess, handleApiError, onDone);
    };

    const updateDataExportStatus = (dataExport) => {
        const onSuccess = response => {
            if (!isMounted.current) return;
            if (response.data.status === 1) {
                timeouts[dataExport.id] = setTimeout(() => updateDataExportStatus(dataExport), 1000);
            }
            else {
                delete timeouts[dataExport.id];
                setDataExports(oldData => oldData.map(item => item.id === response.data.id ? response.data : item));
            }
        };
        getDataExportDetails(crane.id, dataExport.id, onSuccess);
    }

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="7" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (dataExports.length) {
        tableContent = (
            <>
                {dataExports.map(dataExport => {
                    const requestTime = moment.utc(dataExport.created_date).local().format(DATETIME_SHORT_FORMAT);
                    const dateFrom = moment.utc(dataExport.date_from).local().format(DATETIME_SHORT_FORMAT);
                    const dateTo = moment.utc(dataExport.date_to).local().format(DATETIME_SHORT_FORMAT);
                    return (
                        <tr key={dataExport.id}>
                            <td className="text-center">{requestTime}</td>
                            <td>{dataExport.motions_str}</td>
                            <td className="text-center">{dataExportTypes[dataExport.data_export_type]}</td>
                            <td className="text-center">{dateFrom}</td>
                            <td className="text-center">{dateTo}</td>
                            <td className="text-center">{dataExportStatuses[dataExport.status]}</td>
                            <td className="text-center">
                                {dataExportStatuses[dataExport.status] === 'Created' &&
                                    <span
                                      className="text-primary text-link"
                                      onClick={() => saveAs(dataExport.file_download_url, dataExport.file_name)}
                                    >
                                        Download
                                    </span>}
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    }
    else tableContent = <tr><td colSpan="7">No data exports found</td></tr>;

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th className="text-center">Request time</th>
                    <th>Motions</th>
                    <th className="text-center">Type of data</th>
                    <th className="text-center">Start date</th>
                    <th className="text-center">End date</th>
                    <th className="text-center">Status</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {tableContent}
            </tbody>
        </Table>
    );
}
