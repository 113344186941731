import ApiClient from './ApiClient';

const apiClient = ApiClient('/customers');

export const createCustomer = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateCustomer = (customerId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${customerId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertCustomer = (payload, onSuccess, onError, onDone) => {
    const customerId = payload.id;
    delete payload.id;
    if (customerId) updateCustomer(customerId, payload, onSuccess, onError, onDone);
    else createCustomer(payload, onSuccess, onError, onDone);
};

export const deleteCustomer = (customerId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${customerId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCustomersList = (onSuccess, onError, onDone) => {
    apiClient.get('/').then(onSuccess).catch(onError).finally(onDone);
};
