import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import CraneDetails from './CraneDetails';
import CranesList from './CranesList';

export default function Cranes(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const filters = props.location.state;
    let customer;
    let site;
    let location;
    const craneSystem = filters && filters.craneSystem;
    if (craneSystem) {
        customer = craneSystem.customer;
        site = craneSystem.site;
        location = craneSystem.location;
    }
    else {
        location = filters && filters.location;
        if (location) {
            customer = location.customer;
            site = location.site;
        }
        else {
            site = filters && filters.site;
            if (site) customer = site.customer;
            else customer = filters && filters.customer;
        }
    }

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/cranes');
        setPageTitle('Cranes');
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return <CraneDetails onCloseClick={handleCloseClick} />;
    return (
        <CranesList
          customer={customer}
          site={site}
          location={location}
          craneSystem={craneSystem}
          onAddClick={handleAddClick}
        />
    );
}
