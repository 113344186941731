import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {getDataFromLocalStorage, saveDataToLocalStorage} from 'utils/Ui';
import MaintenanceItemTemplatesList from './MaintenanceItemTemplatesList';
import MaintenanceItemTemplateDetails from './MaintenanceItemTemplateDetails';

export default function MaintenanceItemTemplates({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [maintenanceItemTemplate, setMaintenanceItemTemplate] = useState({});

    let reportTemplate = location.state && location.state.reportTemplate;
    if (reportTemplate) saveDataToLocalStorage('reportTemplate', reportTemplate);
    else reportTemplate = getDataFromLocalStorage('reportTemplate');

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/report-templates');
        setPageTitle(reportTemplate.name);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setMaintenanceItemTemplate({});
        setIsEditMode(true);
    };

    const handleEditClick = (maintenanceItemTemplate) => {
        setMaintenanceItemTemplate(maintenanceItemTemplate);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <MaintenanceItemTemplateDetails
          reportTemplate={reportTemplate}
          maintenanceItemTemplate={maintenanceItemTemplate}
          onCloseClick={handleCloseClick}
        />
    );
    return (
        <MaintenanceItemTemplatesList
          reportTemplate={reportTemplate}
          onAddClick={handleAddClick}
          onEditClick={handleEditClick}
        />
    );
}
