import React from 'react';
import {Container, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

function Footer (props) {
  return (
    <footer className={'footer' + (props.default ? ' footer-default' : '')}>
      <Container>
        <Row>
          <nav className="footer-nav" />
          <div className="credits ml-auto">
            <div className='copyright' />
          </div>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  default: PropTypes.bool
}

export default Footer
