import ApiClient from './ApiClient';

const apiClient = ApiClient('/custom_reports');

export const updateMaintenanceItem = (craneId, customReportId, maintenanceItemId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${customReportId}/maintenance/${maintenanceItemId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceItemsList = (craneId, customReportId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${customReportId}/maintenance/`).then(onSuccess).catch(onError).finally(onDone);
};
