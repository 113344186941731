import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {getDataFromLocalStorage, saveDataToLocalStorage} from 'utils/Ui';
import CustomEventDetails from './CustomEventDetails';
import CustomEventsList from './CustomEventsList';

export default function CustomEvents({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [customEvent, setCustomEvent] = useState({});

    let crane = location.state && location.state.crane;
    if (crane) saveDataToLocalStorage('crane', crane);
    else crane = getDataFromLocalStorage('crane');

    let customReport = location.state && location.state.customReport;
    if (customReport) saveDataToLocalStorage('customReport', customReport);
    else customReport = getDataFromLocalStorage('customReport');

    let maintenanceItem = location.state && location.state.maintenanceItem;
    if (maintenanceItem) saveDataToLocalStorage('maintenanceItem', maintenanceItem);
    else maintenanceItem = getDataFromLocalStorage('maintenanceItem');

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/cranes');
        setPageTitle(crane ? crane.crane_name : 'Events');
        return () => isMounted.current = false;
    }, []);

    const handleEditClick = (customEvent) => {
        setCustomEvent(customEvent);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <CustomEventDetails
          crane={crane}
          customReport={customReport}
          maintenanceItem={maintenanceItem}
          customEvent={customEvent}
          onCloseClick={handleCloseClick}
        />
    );
    return (
        <CustomEventsList
          crane={crane}
          customReport={customReport}
          maintenanceItem={maintenanceItem}
          onEditClick={handleEditClick}
        />
    );
}
