import {
    ALL_ROLES, CRANE_SYSTEM_ADMIN,
    CUSTOMER_ADMIN,
    CUSTOMER_AND_SUPERIOR_ROLES, LOCATION_ADMIN,
    LOCATION_AND_SUPERIOR_ROLES, SITE_ADMIN,
    SITE_AND_SUPERIOR_ROLES,
    SYSTEM_ADMIN,
    SYSTEM_ROLES,
    SYSTEM_USER,
} from 'utils/Auth';
import Dashboard from 'views/dashboard/Dashboard';
import Customers from 'views/customers/Customers';
import Sites from 'views/sites/Sites';
import Locations from 'views/locations/Locations';
import CraneSystems from 'views/craneSystems/CraneSystems';
import Cranes from 'views/cranes/Cranes';
import CraneDetails from 'views/cranes/status/CraneDetails';
import ConfigurationPage from 'views/cranes/config/ConfigurationPage';
import CustomReports from 'views/cranes/custom_reports/reports/CustomReports';
import MaintenanceAlerts from 'views/maintenance/MaintenanceAlerts';
import MaintenanceItems from 'views/cranes/custom_reports/maintenanceItems/MaintenanceItems';
import CustomEvents from 'views/cranes/custom_reports/events/CustomEvents';
import ReportTemplates from 'views/templates/reports/ReportTemplates';
import MaintenanceItemTemplates from 'views/templates/maintenanceItems/MaintenanceItemTemplates';
import EventTemplates from 'views/templates/events/EventTemplates';
import Users from 'views/users/Users';

const appRoutes = [
    {
        path: '/app/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        icon: 'fa-home',
        roles: ALL_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/cranes',
        name: 'Cranes',
        component: Cranes,
        icon: 'fa-arrows-alt',
        roles: ALL_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/cranes-details',
        name: 'Crane details',
        component: CraneDetails,
        icon: null,
        roles: ALL_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/cranes-configure',
        name: 'Crane configuration',
        component: ConfigurationPage,
        icon: null,
        roles: ALL_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/customers',
        name: 'Customers',
        component: Customers,
        icon: 'fa-industry',
        roles: SYSTEM_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/sites',
        name: 'Sites',
        component: Sites,
        icon: 'fa-building',
        roles: CUSTOMER_AND_SUPERIOR_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/locations',
        name: 'Locations',
        component: Locations,
        icon: 'fa-building',
        roles: SITE_AND_SUPERIOR_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/crane-systems',
        name: 'Crane systems',
        component: CraneSystems,
        icon: 'fa-building',
        roles: LOCATION_AND_SUPERIOR_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/report-templates',
        name: 'Report templates',
        component: ReportTemplates,
        icon: 'fa-file',
        roles: SYSTEM_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/maintenance-item-templates',
        name: 'Maintenance item templates',
        component: MaintenanceItemTemplates,
        icon: null,
        roles: SYSTEM_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/event-templates',
        name: 'Event templates',
        component: EventTemplates,
        icon: null,
        roles: SYSTEM_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/cranes-custom-reports',
        name: 'Custom reports',
        component: CustomReports,
        icon: null,
        roles: ALL_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/cranes-maintenance-items',
        name: 'Maintenance items',
        component: MaintenanceItems,
        icon: null,
        roles: ALL_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/cranes-report-events',
        name: 'Report event',
        component: CustomEvents,
        icon: null,
        roles: ALL_ROLES,
        showInMenu: false,
    },
    {
        path: '/app/maintenance_alerts',
        name: 'Maintenance alerts',
        component: MaintenanceAlerts,
        icon: 'fa-bell',
        roles: ALL_ROLES,
        showInMenu: true,
    },
    {
        path: '/app/users',
        name: 'Users',
        component: Users,
        icon: 'fa-users',
        roles: [SYSTEM_ADMIN, SYSTEM_USER, CUSTOMER_ADMIN, SITE_ADMIN, LOCATION_ADMIN, CRANE_SYSTEM_ADMIN],
        showInMenu: true,
    },
];

export default appRoutes;
