import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Form, Button, Navbar, Alert} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {authenticateUser, getAuthenticatedUser} from 'api/AuthenticationApi';
import {CRANE_SYSTEM_REF, CUSTOMER_REF, LOCATION_REF, SITE_REF, USER_EMAIL, USER_ID, USER_NAME, USER_ROLE} from 'constants/apiConstants';
import {saveTokens} from 'utils/Auth';
import ForgotPassword from './ForgotPassword';
import logo from 'smart.png';

const LoginForms = (props) => {
    let stateData = props.location.state
    let history = useHistory();
    let crane_id = ''
    if(props.location !=null && props.location.state !=null){
        crane_id = props.location.state.crane_id ? props.location.state.crane_id : ''
    }
    let emailObj = props.emailObj ? props.emailObj : ''
    let passwordObj = props.passwordObj ? props.passwordObj : ''
    const [state, setState] = useState({
        stateData: stateData,
        email: '',
        password: '',
        crane_id: crane_id,
        flag: false,
        messageType: null,
        messageText: null,
    })

    const createMessage = (type, text) => {
        setState({
            ...state,
            messageType: type,
            messageText: text,
        })
        setTimeout(() => {
            removeMessage()
        }, 3000)
    }

    const redirectToHome = () => {
        if (state.stateData && state.stateData.url) history.push({pathname: state.stateData.url, state: state.stateData});
	    else if (state.crane_id === '') history.push({pathname: '/app/dashboard/'});
        else history.push({pathname: '/admin/cranes-documents', state: {passData: state.crane_id}});
    }

    const getUserStatus = () => {
        const onSuccess = response => {
            const data = response.data;
            window.localStorage.setItem(USER_EMAIL, data.email);
            window.localStorage.setItem(USER_ID, data.id);
            window.localStorage.setItem(USER_NAME, data.username);
            window.localStorage.setItem(USER_ROLE, data.role);
            if (data.customer) window.localStorage.setItem(CUSTOMER_REF, data.customer.id);
            if (data.site) window.localStorage.setItem(SITE_REF, data.site.id);
            if (data.location) window.localStorage.setItem(LOCATION_REF, data.location.id);
            if (data.crane_system) window.localStorage.setItem(CRANE_SYSTEM_REF, data.crane_system.id);
            redirectToHome();
        };
        const onError = error => console.error(error);
        getAuthenticatedUser(onSuccess, onError);
    }

    const removeMessage = () => {
        setState({
            ...state,
            messageType: null,
            messageText: null,
        })
    }

    const setFlag = () =>{
        setState({
            flag: true
        })
    }

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href='/'>
                    <img src={logo} alt="Smart Crane System" style={{height: '100px'}} />
                </Navbar.Brand>
            </Navbar>
            <Formik
              initialValues={{email: '', password: ''}}
              validationSchema={Yup.object().shape({
                  email: Yup.string().required('Email is required').email('Enter valid email Id'),
                  password: Yup.string().required('Password is required')
              })}
              onSubmit={(values, {setSubmitting}) => {
                  const onSuccess = response => {
                      setSubmitting(true);
                      saveTokens(response.data.access_token, response.data.refresh_token);
                      getUserStatus();
                  };
                  const onError = error => {
                      if (error.response) {
                          setSubmitting(false);
                          createMessage('danger', error.response.data.message);
                      }
                  };
                  authenticateUser(values, onSuccess, onError);
              }}
            >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;
                return (
                    <div className='Login'>
                        {state.flag ?
                            <ForgotPassword />
                        :
                        <Form onSubmit={handleSubmit}>
                            <Alert variant={state.messageType}>
                                {state.messageText}
                            </Alert>
                            <Form.Group size='lg'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  name='email'
                                  id='input-email'
                                  className={
                                    errors.email && touched.email ? 'input error' : 'input'
                                  }
                                  type='email'
                                  placeholder='Enter an email address'
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.email && touched.email && (
                                    <Form.Text className="text-muted text-danger">{errors.email}</Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group size='lg'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  name='password'
                                  id='input-password'
                                  className={
                                  errors.password && touched.password
                                    ? 'input error'
                                    : 'input'
                                  }
                                  type='password'
                                  placeholder='Enter password'
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.password && touched.password && (
                                    <Form.Text className="text-muted text-danger">{errors.password}</Form.Text>
                                )}
                            </Form.Group>
                            <p className='card-category' onClick={() => setFlag()} style={{cursor:'pointer'}}>Forgot Password?</p>
                            <Button block size='lg' type='submit' disabled={isSubmitting}>
                                Login
                            </Button>
                        </Form>}
                    </div>
                )
            }}
            </Formik>
        </div>
    )
}

export default LoginForms
