import moment from 'moment';
import {refreshTokens} from 'api/AuthenticationApi';
import {
    ACCESS_TOKEN_NAME,
    CRANE_SYSTEM_REF,
    CUSTOMER_REF,
    LOCATION_REF,
    PROPERTY_WEB_PARAMS,
    REFRESH_TOKEN_NAME,
    SITE_REF,
    USER_EMAIL,
    USER_ID,
    USER_ROLE,
} from 'constants/apiConstants';

export const SYSTEM_ADMIN = 1;
export const CUSTOMER_ADMIN = 2;
export const SITE_ADMIN = 3;
export const LOCATION_ADMIN = 4;
export const CRANE_SYSTEM_ADMIN = 5;
export const SYSTEM_USER = 101;
export const CUSTOMER_USER = 102;
export const SITE_USER = 103;
export const LOCATION_USER = 104;
export const CRANE_SYSTEM_USER = 105;
export const ADMIN_ROLES = [SYSTEM_ADMIN, CUSTOMER_ADMIN, SITE_ADMIN, LOCATION_ADMIN, CRANE_SYSTEM_ADMIN];
export const USER_ROLES = [SYSTEM_USER, CUSTOMER_USER, SITE_USER, LOCATION_USER, CRANE_SYSTEM_USER];
export const SYSTEM_ROLES = [SYSTEM_ADMIN, SYSTEM_USER];
export const CUSTOMER_ROLES = [CUSTOMER_ADMIN, CUSTOMER_USER];
export const CUSTOMER_AND_SUPERIOR_ROLES = CUSTOMER_ROLES.concat(SYSTEM_ROLES);
export const SITE_ROLES = [SITE_ADMIN, SITE_USER];
export const SITE_AND_SUPERIOR_ROLES = SITE_ROLES.concat(CUSTOMER_AND_SUPERIOR_ROLES);
export const LOCATION_ROLES = [LOCATION_ADMIN, LOCATION_USER];
export const LOCATION_AND_SUPERIOR_ROLES = LOCATION_ROLES.concat(SITE_AND_SUPERIOR_ROLES);
export const CRANE_SYSTEM_ROLES = [CRANE_SYSTEM_ADMIN, CRANE_SYSTEM_USER];
export const LOCATION_ROLES_AND_DESCENDANTS = LOCATION_ROLES.concat(CRANE_SYSTEM_ROLES);
export const SITE_ROLES_AND_DESCENDANTS = SITE_ROLES.concat(LOCATION_ROLES_AND_DESCENDANTS);
export const CUSTOMER_ROLES_AND_DESCENDANTS = CUSTOMER_ROLES.concat(SITE_ROLES_AND_DESCENDANTS);
export const ALL_ROLES = ADMIN_ROLES.concat(USER_ROLES);

export const parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const tokenPayload = decodeURIComponent(window.atob(base64).split('').map(
        c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(tokenPayload);
};

export const saveTokens = (accessToken, refreshToken) => {
    window.localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
};

export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_NAME);

export const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN_NAME);

export const getValidAccessToken = async () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
        console.error('No access token');
        removeUserData();
        return null;
    }
    const accessTokenData = parseJwt(accessToken);
    if (accessTokenData.exp - moment().unix() >= 60) return accessToken;
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        removeUserData();
        return null;
    }
    const refreshTokenData = parseJwt(refreshToken);
    if (refreshTokenData.exp - moment().unix() < 0) {
        removeUserData();
        return null;
    }
    const tokens = await refreshTokens(refreshToken);
    saveTokens(tokens.access_token, tokens.refresh_token);
    return tokens.access_token;
}

export const removeUserData = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_NAME);
    window.localStorage.removeItem(CRANE_SYSTEM_REF);
    window.localStorage.removeItem(CUSTOMER_REF);
    window.localStorage.removeItem(LOCATION_REF);
    window.localStorage.removeItem(PROPERTY_WEB_PARAMS);
    window.localStorage.removeItem(REFRESH_TOKEN_NAME);
    window.localStorage.removeItem(SITE_REF);
    window.localStorage.removeItem(USER_EMAIL);
    window.localStorage.removeItem(USER_ROLE);
};

export const getUserRole = () => Number(window.localStorage.getItem(USER_ROLE));

export const authenticatedUserId = () => Number(window.localStorage.getItem(USER_ID));
export const isAuthenticated = () => !!(getAccessToken());
export const isAdmin = () => ADMIN_ROLES.includes(getUserRole());
export const isSystemAdmin = () => getUserRole() === SYSTEM_ADMIN;
export const isSystemRole = () => SYSTEM_ROLES.includes(getUserRole());
export const isCustomerAdmin = () => getUserRole() === CUSTOMER_ADMIN;
export const isCustomerRole = () => CUSTOMER_ROLES.includes(getUserRole());
export const isSiteAdmin = () => getUserRole() === SITE_ADMIN;
export const isSiteRole = () => SITE_ROLES.includes(getUserRole());
export const isLocationAdmin = () => getUserRole() === LOCATION_ADMIN;
export const isLocationRole = () => LOCATION_ROLES.includes(getUserRole());
export const isCraneSystemAdmin = () => getUserRole() === CRANE_SYSTEM_ADMIN;
export const isCraneSystemRole = () => CRANE_SYSTEM_ROLES.includes(getUserRole());
