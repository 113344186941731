import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';
import {getSpecifiedConfiguration} from 'api/ConfigurationApi';
import {PROPERTY_WEB_PARAMS} from 'constants/apiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerRole, isLocationRole, isSiteRole, isSystemRole} from 'utils/Auth';
import ConfigurationVersionsList from './ConfigurationVersionsList';
import CraneConfig from './CraneConfig';
import CustomerConfig from './CustomerConfig';
import EdgeConfig from './EdgeConfig';
import EventsConfig from './events/EventsConfig';
import MotionsConfig from './motion/MotionsConfig';
import MotorsConfig from './motor/MotorsConfig';
import VfdsConfig from './vfd/VfdsConfig';

const buttonSelectClass = 'btn-primary';
const buttonUnSelectClass = 'btn-title';

export default function ConfigurationPage() {
    const isMounted= useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [currentVersion, setCurrentVersion] = useState({version: null, status: null});
    const [currentConfiguration, setCurrentConfiguration] = useState({});
    const [pageState, setPageState] = useState({crane: {}, craneId: null, menu: 'edge'});
    const showCustomerTab = isSystemRole() || isCustomerRole() || isSiteRole() || isLocationRole();
    const location = useLocation();

    useEffect(() => {
        isMounted.current = true;
        if (location && location.state) {
            setPageState(oldData => {
                const newData = {...oldData, ...location.state};
                newData.craneId = newData.crane.id;
                localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(newData));
                return newData;
            });
        }
        else {
            const storedPageState = JSON.parse(window.localStorage.getItem(PROPERTY_WEB_PARAMS)) || {};
            setPageState(oldData => ({...oldData, ...storedPageState}));
        }
        return () => isMounted.current = false;
    }, [location]);

    useEffect(() => {
        setMenuItem('/app/cranes');
        setPageTitle(pageState.crane ? pageState.crane.crane_name : 'Cranes');
    }, [pageState]);

    const getCurrentConfiguration = version => {
        getSpecifiedConfiguration(pageState.craneId, version, handleApiSuccess, handleApiError);
    };

    const handleApiSuccess = response => {
        if (isMounted.current) {
            setCurrentConfiguration(response.data);
            setPageState(oldData => {
                const newData = {
                    ...oldData,
                    crane: response.data.crane_details,
                };
                localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(newData));
                return newData;
            });
        }
    };

    const handleConfigurationChange = configuration => {
        getCurrentConfiguration(configuration.version);
        setCurrentVersion(oldData => ({...oldData, ...configuration}));
    };

    const handleDataUpdate = () => {
        getCurrentConfiguration(currentVersion.version);
    };

    const handleMenuClick = menuName => {
        setPageState(oldData => {
            const newData = {...oldData, menu: menuName};
            localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(newData));
            return newData;
        });
    };

    let pageContent = null;
    if (Number.isInteger(pageState.craneId)) {
        const formParameters = {
            pageState: pageState,
            currentVersion: currentVersion,
            currentConfiguration: currentConfiguration,
            onDataUpdate: handleDataUpdate,
        };
        switch (pageState.menu) {
            case 'crane':
                pageContent = <CraneConfig {...formParameters} />;
                break;
            case 'motion':
                pageContent = <MotionsConfig {...formParameters} />;
                break;
            case 'vfd':
                pageContent = <VfdsConfig {...formParameters} />;
                break;
            case 'motor':
                pageContent = <MotorsConfig {...formParameters} />;
                break;
            case 'customer':
                pageContent = <CustomerConfig {...formParameters} />;
                break;
            case 'event':
                pageContent = <EventsConfig {...formParameters} />;
                break;
            default:
                pageContent = <EdgeConfig {...formParameters} />;
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <ConfigurationVersionsList craneId={pageState.craneId} onConfigurationChange={handleConfigurationChange} />
                </Col>
            </Row>
            <Row>
                <ButtonGroup className="col-md-12 btn-sm" role="group">
                    <Button className={pageState.menu === 'edge' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('edge')}>Edge</Button>
                    <Button className={pageState.menu === 'crane' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('crane')}>Crane</Button>
                    <Button className={pageState.menu === 'motion' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('motion')}>Motions</Button>
                    <Button className={pageState.menu === 'vfd' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('vfd')}>VFDs</Button>
                    <Button className={pageState.menu === 'motor' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('motor')}>Motors</Button>
                    {showCustomerTab && <Button className={pageState.menu === 'customer' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('customer')}>Customer</Button>}
                    <Button className={pageState.menu === 'event' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('event')}>Events</Button>
                </ButtonGroup>
            </Row>
            {pageContent}
        </>
    );
}
