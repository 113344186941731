import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {loadCustomers} from 'utils/Ui';
import SiteDetails from './SiteDetails';
import SitesList from './SitesList';

export default function Sites(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [site, setSite] = useState({});
    const [customers, setCustomers] = useState({});
    const customer = props.location.state && props.location.state.customer;
    const useCustomer = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/sites');
        setPageTitle('Sites');
        if (useCustomer) loadCustomers(setCustomers, handleApiError);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setSite({});
        setIsEditMode(true);
    };

    const handleEditClick = (site) => {
        setSite(site);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <SiteDetails site={site} useCustomer={useCustomer} customers={customers} onCloseClick={handleCloseClick} />
    );
    return <SitesList customer={customer} onAddClick={handleAddClick} onEditClick={handleEditClick} />;
}
