import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getDashboard} from 'api/DashboardApi';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isCustomerRole, isLocationRole, isSiteRole, isSystemRole} from 'utils/Auth';

export default function Dashboard() {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [dashboardData, setDashboardData] = useState({});
    const showCustomers = isSystemRole();
    const showSites = isSystemRole() || isCustomerRole();
    const showLocations = isSystemRole() || isCustomerRole() || isSiteRole();
    const showCraneSystems = isSystemRole() || isCustomerRole() || isSiteRole() || isLocationRole();
    const showUsers = isSystemRole() || isCustomerAdmin();

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/dashboard');
        setPageTitle('Dashboard');
        const onSuccess = response => isMounted.current && setDashboardData(response.data);
        getDashboard(onSuccess, handleApiError);
        return () => isMounted.current = false;
    }, []);

    const getDashboardPanel = (title, icon, value, path) => {
        return (
            <Col lg="4" md="6" sm="8">
                <Card className="card-stats">
                    <Card.Body>
                        <Row>
                            <Col md="4" xs="5">
                                <div className="icon-big text-center icon-danger">
                                    <FontAwesomeIcon icon={icon} size="1x" className="text-title" />
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <Link to={{pathname: path}}>
                                    <div className="numbers">
                                        <p className="card-category">{title}</p>
                                        <Card.Title tag="p">{value}</Card.Title>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer><hr /></Card.Footer>
                </Card>
            </Col>
        );
    }

    return (
        <Row className="dashboard">
            {getDashboardPanel('Cranes', 'fa-arrows-alt', dashboardData.total_cranes, '/app/cranes')}
            {showCustomers && getDashboardPanel('Customers', 'fa-industry', dashboardData.total_customers, '/app/customers')}
            {showSites && getDashboardPanel('Sites', 'fa-building', dashboardData.total_sites, '/app/sites')}
            {showLocations && getDashboardPanel('Locations', 'fa-building', dashboardData.total_locations, '/app/locations')}
            {showCraneSystems && getDashboardPanel('Crane systems', 'fa-building', dashboardData.total_crane_systems, '/app/crane-systems')}
            {showUsers && getDashboardPanel('Users', 'fa-users', dashboardData.total_users, '/app/users')}
        </Row>
    );
}
