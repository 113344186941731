import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertReportTemplate} from 'api/ReportTemplatesApi';
import {motionTypes, reportTemplateParameters} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import ReportTemplateForm from './ReportTemplateForm';

const DEFAULT_INSTANCE = {
    id: '',
    name: '',
    component_type: 'motion',
    motion_types: [],
    params: [],
};
const VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    component_type: Yup.string().required('Component type is required'),
    motion_types: Yup.array().of(Yup.string()).min(1, 'At least one motion is required'),
    params: Yup.array().of(Yup.string()).min(1, 'At least one parameter is required'),
});

export default function ReportTemplateDetails({reportTemplate, onCloseClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const [motionTypeOptions, setMotionTypeOptions] = useState([]);
    const [parameterOptions, setParameterOptions] = useState([]);
    const isDraft = isEmpty(reportTemplate) || reportTemplate.status === 1;
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(isEmpty(reportTemplate) ? 'New report template' : 'Report template');
        getInitialValues();
        setMotionTypeOptions(Object.entries(motionTypes).map(([k, v]) => ({label: v, value: k.toString()})));
        setParameterOptions(Object.entries(reportTemplateParameters).map(([k, v]) => ({label: v, value: k})));
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = reportTemplate.hasOwnProperty(key) ? reportTemplate[key] : value;
                }
                newData.motion_types = newData.motion_types.map(item => item.toString());
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        payload.motion_types = payload.motion_types.map(item => parseInt(item));
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertReportTemplate(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Report template has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <ReportTemplateForm
                  {...props}
                  motionTypeOptions={motionTypeOptions}
                  parameterOptions={parameterOptions}
                  isDraft={isDraft}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
