import ApiClient from './ApiClient';

const apiClient = ApiClient('/motors');

export const createMotor = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateMotor = (craneId, motorId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${motorId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertMotor = (craneId, payload, onSuccess, onError, onDone) => {
    const motorId = payload.id;
    delete payload.id;
    if (motorId) updateMotor(craneId, motorId, payload, onSuccess, onError, onDone);
    else createMotor(craneId, payload, onSuccess, onError, onDone);
};
