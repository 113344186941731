import ApiClient from './ApiClient';

const apiClient = ApiClient('/documents');

export const createDocument = (craneId, payload, onSuccess, onError, onDone) => {
    const requestConfig = {headers: {'Content-Type': 'multipart/form-data'}};
    apiClient.post(`/${craneId}/`, payload, requestConfig).then(onSuccess).catch(onError).finally(onDone);
};

export const updateDocument = (craneId, documentId, payload, onSuccess, onError, onDone) => {
    const requestConfig = {headers: {'Content-Type': 'multipart/form-data'}};
    apiClient.put(`/${craneId}/${documentId}/`, payload, requestConfig).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertDocument = (craneId, payload, onSuccess, onError, onDone) => {
    const documentId = payload.get('id');
    payload.delete('id');
    if (documentId) updateDocument(craneId, documentId, payload, onSuccess, onError, onDone);
    else createDocument(craneId, payload, onSuccess, onError, onDone);
};

export const deleteDocument = (craneId, documentId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/${documentId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getDocumentsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};
