import React, {useEffect, useRef, useState} from 'react';
import MotionDetails from './MotionDetails';
import MotionsList from './MotionsList';

export default function MotionsConfig(props) {
    const isMounted = useRef(false);
    const [showDetails, setShowDetails] = useState(false);
    const [motionId, setMotionId] = useState('');

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleAddButtonClick = () => {
        setMotionId('');
        setShowDetails(true);
    };

    const handleDetailsClick = motion => {
        setMotionId(motion.id);
        setShowDetails(true);
    };

    const handleCloseButtonClick = () => {
        setShowDetails(false);
    };

    if (showDetails) return <MotionDetails {...props} motionId={motionId} onCloseButtonClick={handleCloseButtonClick} />;

    return <MotionsList {...props} onDetailsClick={handleDetailsClick} onAddButtonClick={handleAddButtonClick} />;
}
