import ApiClient from './ApiClient';

const apiClient = ApiClient('/faults');

export const getFaultsList = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getFaultData = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/data/dump/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const downloadFaultData = (craneId, payload, onSuccess, onError, onDone) => {
    const requestConfig = {responseType: 'blob'};
    apiClient.post(`/${craneId}/data/export/`, payload, requestConfig).then(onSuccess).catch(onError).finally(onDone);
};
