import React, {useEffect, useRef} from 'react';
import {Route, Switch} from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import authRoutes from 'routes/authRoutes';

let ps;

export default function AuthLayout(props) {
    const isMounted = useRef(false);
    const container = useRef(null);

    useEffect(() => {
        isMounted.current = true;
        if (navigator.platform.indexOf('Win') > -1) ps = new PerfectScrollbar(container.current);
        return () => {
            isMounted.current = false;
            if (navigator.platform.indexOf('Win') > -1) ps.destroy();
        };
    }, []);

    return (
        <div>
            <AuthNavbar />
            <div className="wrapper wrapper-full-page" ref={container}>
                <div className="full-page section-image">
                    <Switch>{authRoutes.map(route => <Route path={route.path} component={route.component} key={route.path.slice(1)} />)}</Switch>
                </div>
            </div>
        </div>
    );
};
