import React, {useEffect, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import Input from 'components/Form/Input';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import Select from 'components/Form/Select';
import {hourOptions} from 'constants/apiConstants';
import {dataExportTypes} from 'constants/dataConstants';
import {objectToOptions} from 'utils/Utils';

const dataExportTypeOptions = [
    {label: '-- Select type --', value: ''},
    ...objectToOptions(dataExportTypes)
];

export default function DataExportForm({
    initialValues, dirty, isValid, isSubmitting, handleChange, handleSubmit,
    motionOptions, motionParameterOptions, odometerParameterOptions,
}) {
    const [parameterOptions, setParameterOptions] = useState([]);

    useEffect(() => {
        changeParameterOptions(initialValues.data_export_type);
    }, [initialValues]);

    const changeParameterOptions = (dataExportType) => {
        if (dataExportType === 'motion') setParameterOptions(motionParameterOptions);
        else if (dataExportType === 'odometer') setParameterOptions(odometerParameterOptions);
        else setParameterOptions([]);
    };

    const handleDataExportTypeChange = (e) => {
        handleChange(e);
        changeParameterOptions(e.target.value);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Select label="Type" name="data_export_type" options={dataExportTypeOptions} onChange={handleDataExportTypeChange} required disabled={isSubmitting} />
            </Row>
            <Row>
                <MultiCheckboxes label="Motions" name="motions" options={motionOptions} disabled={isSubmitting} />
            </Row>
            <Row>
                <Input label="Start date" type="date" name="date_from" required disabled={isSubmitting} />
                <Select label="Start time" name="hour_from" options={hourOptions} required disabled={isSubmitting} />
                <Input label="End date" type="date" name="date_to" required disabled={isSubmitting} />
                <Select label="End time" name="hour_to" options={hourOptions} required disabled={isSubmitting} />
            </Row>
            {parameterOptions.length > 0 &&
                <Row>
                    <MultiCheckboxes label="Parameters" name="params" options={parameterOptions} disabled={isSubmitting} />
                </Row>}
            <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting}>Export</Button>
        </Form>
    )
};
