import React, {useEffect, useRef, useState} from 'react';
import {Button, OverlayTrigger, Spinner, Table, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {deleteBearing, getBearingsList} from 'api/BearingsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {DATE_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import BearingLifeData from './BearingLifeData';

let bearingObject;
const aotTooltip = (
    <Tooltip id="aot">
        Accumulated Operating Time is a measure of the sum of the bearing operation at varying speeds and loads
        over its life, converted to a normalized number of hours of operation at full rated load and full rated
        speed for life tracking purposes
    </Tooltip>
);

export default function BearingsList({crane, isEditable, onEditClick}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [deleteDialogTitle, setDeleteDialogTitle] = useState('');
    const [detailsDialogTitle, setDetailsDialogTitle] = useState('');
    const [detailsDialogBody, setDetailsDialogBody] = useState(<></>);
    const [bearingsList, setBearingsList] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        loadBearingsList();
        return () => isMounted.current = false;
    }, [crane]);

    const loadBearingsList = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setBearingsList(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getBearingsList(crane.id, onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (bearing) => {
        bearingObject = bearing;
        setDeleteDialogTitle(
            `Do you really want to delete bearing "${bearing.name}" mounted on the crane "${crane.crane_name}"?`
        )
        setShowDeleteDialog(true);
    };

    const handleDetailsClick = (bearing) => {
        setDetailsDialogTitle(`${bearing.name}: ${bearing.manufacturer} ${bearing.part_number}`);
        setDetailsDialogBody(<BearingLifeData bearing={bearing} />);
        setShowDetailsDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
        setShowDetailsDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteBearing(crane.id, bearingObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Bearing has been successfully deleted');
        if (isMounted.current) loadBearingsList();
    };

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan={isEditable ? 10 : 9} className="text-center"><Spinner animation="border" /></td></tr>;
    else if (bearingsList.length) {
        tableContent = (
            <>
                {bearingsList.map((item) => {
                    let serviceDate = moment(item.service_date).utc(true).format(DATE_FORMAT);
                    let accumulatedOperatingTime = '-';
                    let lifeConsumed = '-';
                    let lifeRemaining = '-';
                    if (item.bearing_life) {
                        accumulatedOperatingTime = item.bearing_life.accumulated_operating_time.toFixed(2);
                        lifeConsumed = (item.bearing_life.life_consumed * 100).toFixed(2) + '%';
                        lifeRemaining = (item.bearing_life.life_remaining * 100).toFixed(2) + '%';
                    }
                    return (
                        <tr key={item.id}>
                            <td><span className="text-primary text-link" onClick={() => handleDetailsClick(item)}>{item.name}</span></td>
                            <td>{item.motion.name}</td>
                            <td>{item.part_number}</td>
                            <td className="text-center">{serviceDate}</td>
                            <td className="text-center">{accumulatedOperatingTime}</td>
                            <td className="text-center">{lifeConsumed}</td>
                            <td className="text-center">{lifeRemaining}</td>
                            <td className="text-center"><span className="text-primary text-link" onClick={() => handleDetailsClick(item)}>Details</span></td>
                            <td className="text-center"><span className="text-primary text-link" onClick={() => onEditClick(item)}>{isEditable ? 'Edit' : 'View'}</span></td>
                            {isEditable && <td className="text-center"><span className="text-primary text-link" onClick={() => handleDeleteClick(item)}>Delete</span></td>}
                        </tr>
                    );
                })}
            </>
        );
    }
    else tableContent = <tr><td colSpan={isEditable ? 10 : 9}>No bearings found</td></tr>;

    return (
        <>
            <ModalDialog title={deleteDialogTitle} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <ModalDialog title={detailsDialogTitle} body={detailsDialogBody} show={showDetailsDialog} onHide={handleModalClosed} size="lg">
                <Button variant="primary" onClick={handleModalClosed}>Close</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Motion</th>
                        <th>Part number</th>
                        <th className="text-center">In service date</th>
                        <th className="text-center">
                            Accumulated operating time [h]
                            <OverlayTrigger placement="top" overlay={aotTooltip}>
                                <FontAwesomeIcon icon="fa-circle-info" size="1x" style={{marginLeft: '5px'}} />
                            </OverlayTrigger>
                        </th>
                        <th className="text-center">Design life consumed</th>
                        <th className="text-center">Design life remaining</th>
                        <th colSpan={isEditable ? 3 : 2} />
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>
        </>
    );
}
