import React from 'react';
import {Form} from 'react-bootstrap';
import {useField} from 'formik';

export default function Checkbox({value, ...props}) {
    const [field, meta] = useField(props);
    const inputValue = value !== undefined ? value : (field.value !== undefined && field.value !== null ? field.value : '');
    const isChecked = Array.isArray(field.value) && field.value.includes(value);
    let inputClass = meta.touched && meta.error ? 'input error' : 'input';
    return (
        <Form.Check {...field} {...props} value={inputValue} className={inputClass} custom inline checked={isChecked} />
    );
}
