import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {userRoles} from 'constants/dataConstants';
import {
    DEFAULT_CRANE_SYSTEM_OPTION,
    DEFAULT_CUSTOMER_OPTION,
    DEFAULT_LOCATION_OPTION,
    DEFAULT_ROLE_OPTION,
    DEFAULT_SITE_OPTION
} from 'constants/uiConstants';
import {
    ALL_ROLES,
    CRANE_SYSTEM_ADMIN,
    CRANE_SYSTEM_ROLES,
    CRANE_SYSTEM_USER,
    CUSTOMER_ADMIN,
    CUSTOMER_ROLES_AND_DESCENDANTS,
    LOCATION_ADMIN,
    LOCATION_ROLES_AND_DESCENDANTS,
    LOCATION_USER,
    SITE_ADMIN,
    SITE_ROLES_AND_DESCENDANTS,
    SITE_USER,
    SYSTEM_ADMIN,
    SYSTEM_USER,
    authenticatedUserId,
    getUserRole,
} from 'utils/Auth';
import {getCraneSystemOptions, getCustomerOptions, getLocationOptions, getSiteOptions} from 'utils/Ui';

export default function UserForm({
    initialValues, values, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    useCustomer, useSite, useLocation, useCraneSystem, customers, sites, locations, craneSystems,
    isEditable, isLoading, onCloseClick
}) {
    const isMounted = useRef(false);
    const [roleOptions, setRoleOptions] = useState([DEFAULT_ROLE_OPTION]);
    const [customerOptions, setCustomerOptions] = useState([DEFAULT_CUSTOMER_OPTION]);
    const [siteOptions, setSiteOptions] = useState([DEFAULT_SITE_OPTION]);
    const [locationOptions, setLocationOptions] = useState([DEFAULT_LOCATION_OPTION]);
    const [craneSystemOptions, setCraneSystemOptions] = useState([DEFAULT_CRANE_SYSTEM_OPTION]);
    const isDisabled = !isEditable || isLoading;
    const showActive = !values.id || values.id !== authenticatedUserId();
    const showCustomer = useCustomer && CUSTOMER_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showSite = useSite && SITE_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showLocation = useLocation && LOCATION_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showCraneSystem = useCraneSystem && CRANE_SYSTEM_ROLES.includes(Number(values.role));

    useEffect(() => {
        isMounted.current = true;
        let options;
        getRoleOptions();
        if (useCustomer) setCustomerOptions(getCustomerOptions(customers));
        if (useSite) {
            options = getSiteOptions(
                sites, useCustomer, initialValues.customer_uuid,
                false, setFieldValue
            );
            setSiteOptions(options);
        }
        if (useLocation) {
            options = getLocationOptions(
                locations, useSite, initialValues.site_uuid,
                false, setFieldValue
            );
            setLocationOptions(options);
        }
        if (useCraneSystem) {
            options = getCraneSystemOptions(
                craneSystems, useLocation, initialValues.location_uuid,
                false, setFieldValue
            );
            setCraneSystemOptions(options);
        }
        return () => isMounted.current = false;
    }, [initialValues, customers, sites, locations, craneSystems]);

    const getRoleOptions = () => {
        const userRole = getUserRole();
        let roleIds;
        switch (userRole) {
            case SYSTEM_ADMIN:
                roleIds = ALL_ROLES; break;
            case SYSTEM_USER:
                roleIds = ALL_ROLES; break;
            case CUSTOMER_ADMIN:
                roleIds = CUSTOMER_ROLES_AND_DESCENDANTS; break;
            case SITE_ADMIN:
                roleIds = [SITE_USER, LOCATION_USER, CRANE_SYSTEM_USER]; break;
            case LOCATION_ADMIN:
                roleIds = [LOCATION_USER, CRANE_SYSTEM_USER]; break;
            case CRANE_SYSTEM_ADMIN:
                roleIds = [CRANE_SYSTEM_USER]; break;
        }
        if (isMounted.current) {
            setRoleOptions([
                DEFAULT_ROLE_OPTION,
                ...roleIds.map(roleId => ({label: userRoles[roleId], value: roleId}))
            ]);
        }
    };

    const handleCustomerChange = e => {
        handleChange(e);
        setSiteOptions(getSiteOptions(sites, useCustomer, e.target.value, true, setFieldValue));
        setLocationOptions(getLocationOptions(locations, useSite, '', true, setFieldValue));
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, '', true, setFieldValue));
    };

    const handleSiteChange = e => {
        handleChange(e);
        setLocationOptions(getLocationOptions(locations, useSite, e.target.value, true, setFieldValue));
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, '', true, setFieldValue));
    };

    const handleLocationChange = e => {
        handleChange(e);
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, e.target.value, true, setFieldValue));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Email" type="email" name="email" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Name" type="text" name="username" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input
                  label="Password" type="password" name="password"
                  info={!isNaN(Number(initialValues.id)) ? 'Enter password only if you want to change it' : null}
                  required={isNaN(Number(initialValues.id))}
                  disabled={isDisabled}
                />
            </Row>
            <Row>
                <Select label="Role" name="role" options={roleOptions} required disabled={isDisabled} />
            </Row>
            {showCustomer &&
                <Row>
                    <Select
                      label="Customer" name="customer_uuid"
                      options={customerOptions} onChange={handleCustomerChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showSite &&
                <Row>
                    <Select
                      label="Site" name="site_uuid"
                      options={siteOptions} onChange={handleSiteChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showLocation &&
                <Row>
                    <Select
                      label="Location" name="location_uuid"
                      options={locationOptions} onChange={handleLocationChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showCraneSystem &&
                <Row>
                    <Select
                      label="Crane system" name="crane_system_uuid"
                      options={craneSystemOptions}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showActive &&
                <Row>
                    <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
                </Row>}
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
}
