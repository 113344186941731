import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertDocument} from 'api/DocumentsApi';
import {useAppContext} from 'contexts/AppContext';
import {isEmpty} from 'utils/Utils';
import DocumentForm from './DocumentForm';

const DEFAULT_INSTANCE = {
    id: '',
    title: '',
    description: '',
};
const VALIDATION_RULES = {
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
};

export default function DocumentEdit({crane, documentObject, isEditable, onDataSubmitted}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [selectedFile, setSelectedFile] = useState();
    const initialValues = isEmpty(documentObject) ? {...DEFAULT_INSTANCE} : {...documentObject};
    let isFileRequired = true;
    let existingFileName;
    if (!isEmpty(documentObject)) {
        isFileRequired = false;
        existingFileName = documentObject.file_name;
    }
    const validationRules = {...VALIDATION_RULES};
    if (isFileRequired) validationRules.file = Yup.mixed().required('File is required');
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setSubmitting(true);
        let payload = new FormData();
        payload.append('id', values.id);
        payload.append('title', values.title);
        payload.append('description', values.description);
        if (selectedFile) payload.append('file', selectedFile);
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => isMounted.current && setSubmitting(false);
        upsertDocument(crane.id, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = response => {
        if (isMounted.current) {
            showMessage('success', 'Document has been successfully saved');
            onDataSubmitted();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    }

    return (
        <Row>
            <Col>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                    {(props) => (
                        <DocumentForm
                          {...props}
                          isEditable={isEditable}
                          isFileRequired={isFileRequired}
                          existingFileName={existingFileName}
                          setSelectedFile={setSelectedFile}
                        />
                    )}
                </Formik>
            </Col>
        </Row>
    );
}
