import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {Formik} from 'formik';
import moment from 'moment';
import {getEventData} from 'api/EventsApi';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import ReactTable from 'components/ReactTable/ReactTable';
import {DATETIME_SHORT_FORMAT, TIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {zeroFill} from 'utils/Utils';

const alertStyle = {
    marginTop: '20px',
    color: 'black',
    fontWeight: 'bold',
};
const spinnerStyle = {
    marginTop: '20px',
    marginBottom: '20px',
};

export default function NotificationDetails({crane, motionOptions, eventParams, notification, onBackClick}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMotions, setSelectedMotions] = useState([]);
    const [eventData, setEventData] = useState({keys: {}, values: []});

    const isSystemEvent = notification.event_type === 1;
    const isCustomEvent = notification.event_type === 2;

    useEffect(() => {
        isMounted.current = true;
        loadEventData();
        return () => isMounted.current = false;
    }, [crane, notification]);

    const loadEventData = () => {
        setIsLoading(true);
        const payload = {
            date_to: notification.created_date,
            interval: 3
        };
        if (isCustomEvent) payload.motion_uuids = [notification.motion_uuid];
        const onSuccess = response => isMounted.current && setEventData(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getEventData(crane.id, payload, onSuccess, handleApiError, onDone);
    };

    const eventTime = moment(notification.created_date).utc(true).format(DATETIME_SHORT_FORMAT);
    const eventFormula = notification.event?.event_formula;
    const eventValues = isCustomEvent ? eventData.values : eventData.values.filter(value => selectedMotions.includes(value.motion_uuid));

    let filterForm;
    if (isSystemEvent) {
        filterForm = (
            <Formik
              enableReinitialize
              initialValues={{motions: motionOptions.map(m => m.value)}}
              onSubmit={() => {}}
            >
                {({values}) => {

                    useEffect(() => {
                        setSelectedMotions(values.motions);
                    }, [values]);

                    return (
                        <Form>
                            <MultiCheckboxes name="motions" options={motionOptions} disabled={isLoading} />
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    const tableColumns = Object.entries(eventData.keys).map(([key, text]) => ({
        Header: text,
        accessor: key,
        filterable: false,
        sortable: true,
        tipText: text
    }));
    const tableData = eventValues.map(value => {
        const tableRow = {id: value['timestamp']};
        Object.keys(eventData.keys).forEach(key => {
            if (key === 'timestamp') tableRow[key] = moment(value[key]).format(TIME_FORMAT);
            else if (key === 'run_time') {
                const runTime = value[key] || {hours: 0, seconds: 0};
                const runHr = runTime.hours;
                const runMin = Math.floor(runTime.seconds / 60);
                const runSec = runTime.seconds - runMin * 60;
                tableRow[key] = zeroFill(runHr, 2) + ':' + zeroFill(runMin, 2) + ':' + zeroFill(runSec, 2);
            }
            else tableRow[key] = value[key];
        })
        return tableRow;
    });

    let content;
    if (isLoading) content = <div className="text-center" style={spinnerStyle}><Spinner animation="border" /></div>;
    else if (eventValues.length === 0) content = <Alert variant="dark" style={alertStyle}>No data</Alert>;
    else content = (
        <ReactTable
          columns={tableColumns}
          data={tableData}
          className="-striped -highlight primary-pagination"
        />
    );

    return (
        <>
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">{notification.event_name} <span style={{fontWeight: 'normal'}}>({eventTime})</span></span>
                    <Button className="btn-close float-right" onClick={onBackClick}>Back</Button>
                </Col>
            </Row>
            {filterForm &&
                <Row style={{marginBottom: '10px'}}>
                    {filterForm}
                </Row>}
            {isCustomEvent &&
                <Row style={{marginBottom: '10px', fontWeight: 'bold'}}>
                    <Col>
                        {eventFormula.motion_name}&nbsp;
                        ({eventParams[eventFormula.key]} {eventFormula.condition} {eventFormula.value}&nbsp;
                        for {notification.event?.event_seconds} seconds)
                    </Col>
                </Row>}
            <Row>
                <Col>{content}</Col>
            </Row>
        </>
    );
};
