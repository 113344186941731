import ApiClient from './ApiClient';

const apiClient = ApiClient('/users');

export const createUser = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateUser = (userId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${userId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertUser = (payload, onSuccess, onError, onDone) => {
    const userId = payload.id;
    delete payload.id;
    if (userId) updateUser(userId, payload, onSuccess, onError, onDone);
    else createUser(payload, onSuccess, onError, onDone);
};

export const deleteUser = (userId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${userId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getUsersList = (onSuccess, onError, onDone) => {
    apiClient.get('/').then(onSuccess).catch(onError).finally(onDone);
};
