import React, {useEffect, useRef, useState} from 'react';
import {Row, Button, ButtonGroup} from 'react-bootstrap';
import {getCraneParameters} from 'api/CranesApi';
import {getMotionsList} from 'api/MotionsApi';
import {getOdometerParameters} from 'api/OdometerApi';
import {PROPERTY_WEB_PARAMS} from 'constants/apiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isEmpty} from 'utils/Utils';
import CraneBearings from './bearings/CraneBearings';
import CraneCharts from './charts/CraneCharts';
import CraneDataExport from './data_export/CraneDataExport';
import CraneDocuments from './documents/CraneDocuments';
import CraneFaults from './faults/CraneFaults';
import CraneMaintenance from './CraneMaintenance';
import CraneNotifications from './notifications/CraneNotifications';
import CraneOdometer from './odometer/CraneOdometer';
import CraneStatus from './CraneStatus';

const buttonSelectClass = 'btn-primary';
const buttonUnSelectClass = 'btn-title';

export default function CraneDetails({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [pageState, setPageState] = useState({crane: {}, craneId: null, menu: 'status'});
    const [crane, setCrane] = useState({});
    const [motionsList, setMotionsList] = useState([]);
    const [motionParameters, setMotionParameters] = useState([]);
    const [odometerParameters, setOdometerParameters] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        if (location && location.state) {
            setPageState(oldData => {
                const newData = {...oldData, ...location.state};
                newData.craneId = newData.crane.id;
                localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(newData));
                return newData;
            });
            setCrane(location.state.crane);
        }
        else {
            const storedPageState = JSON.parse(window.localStorage.getItem(PROPERTY_WEB_PARAMS)) || {};
            setPageState(oldData => ({...oldData, ...storedPageState}));
            setCrane(storedPageState.crane);
        }
        return () => isMounted.current = false;
    }, [location]);

    useEffect(() => {
        setMenuItem('/app/cranes');
        setPageTitle(!isEmpty(crane) ? crane.crane_name : 'Crane details');
        loadMotionsList();
        loadMotionParameters();
        loadOdometerParameters();
    }, [crane]);

    const loadMotionsList = () => {
        const onSuccess = response => isMounted.current && setMotionsList(response.data);
        if (!isEmpty(crane)) getMotionsList(crane.id, onSuccess);
        else setMotionsList([]);
    };

    const loadMotionParameters = () => {
        const onSuccess = response => isMounted.current && setMotionParameters(response.data);
        if (!isEmpty(crane)) getCraneParameters(crane.id, onSuccess);
        else setMotionParameters([]);
    };

    const loadOdometerParameters = () => {
        const onSuccess = response => isMounted.current && setOdometerParameters(response.data);
        if (!isEmpty(crane)) getOdometerParameters(onSuccess);
        else setOdometerParameters([]);
    };

    const handleMenuClick = (menuName) => {
        setPageState(oldData => {
            const newData = {...oldData, menu: menuName};
            localStorage.setItem(PROPERTY_WEB_PARAMS, JSON.stringify(newData));
            return newData;
        });
    };

    let pageContent = null;
    if (Number.isInteger(pageState.craneId)) {
        switch (pageState.menu) {
            case 'odometer':
                pageContent = <CraneOdometer crane={crane} motionsList={motionsList} odometerParameters={odometerParameters} />;
                break;
            case 'charts':
                pageContent = <CraneCharts crane={crane} motionsList={motionsList} motionParameters={motionParameters} />;
                break;
            case 'maintenance':
                pageContent = <CraneMaintenance pageState={pageState} />;
                break;
            case 'documents':
                pageContent = <CraneDocuments crane={crane} />;
                break;
            case 'notifications':
                pageContent = <CraneNotifications crane={crane} motionsList={motionsList} />;
                break;
            case 'faults':
                pageContent = <CraneFaults crane={crane} motionsList={motionsList} />;
                break;
            case 'bearings':
                pageContent = <CraneBearings crane={crane} motionsList={motionsList} />;
                break;
            case 'data-export':
                pageContent = <CraneDataExport crane={crane} motionsList={motionsList} motionParameters={motionParameters} odometerParameters={odometerParameters} />;
                break;
            default:
                pageContent = <CraneStatus crane={crane} />;
        }
    }

    return (
        <>
            <Row>
                <ButtonGroup className="col-md-12 btn-sm" role="group">
                    <Button className={pageState.menu === 'status' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('status')}>Status</Button>
                    <Button className={pageState.menu === 'odometer' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('odometer')}>Odometer</Button>
                    <Button className={pageState.menu === 'charts' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('charts')}>Charts</Button>
                    <Button className={pageState.menu === 'maintenance' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('maintenance')}>Maintenance</Button>
                    <Button className={pageState.menu === 'documents' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('documents')}>Documents</Button>
                    <Button className={pageState.menu === 'notifications' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('notifications')}>Notifications</Button>
                    <Button className={pageState.menu === 'faults' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('faults')}>Fault history</Button>
                    <Button className={pageState.menu === 'bearings' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('bearings')}>Bearing life tracker</Button>
                    <Button className={pageState.menu === 'data-export' ? buttonSelectClass : buttonUnSelectClass} onClick={() => handleMenuClick('data-export')}>Data export</Button>
                </ButtonGroup>
            </Row>
            {pageContent}
        </>
    );
}
