import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {createServiceRecord} from 'api/MaintenanceApi';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import ServiceRecordForm from './ServiceRecordForm';

const VALIDATION_SCHEMA = Yup.object().shape({
    service_date: Yup.date().required('Service date is required'),
    operating_time: Yup.number().required('Cumulative operating hours at last service is required'),
});

export default function ServiceRecordDetails({
    customReport, maintenanceItem, serviceRecord, motionOperationTime, getMotionOperationTime, onCloseClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError, loadMaintenanceAlertsCount} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        id: '',
        service_date: moment().format(DATE_FORMAT_ISO),
        operating_time: motionOperationTime
    });
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = serviceRecord.hasOwnProperty(key) ? serviceRecord[key] : value;
                }
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            service_date: values.service_date,
            operating_time: Number(values.operating_time),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            loadMaintenanceAlertsCount();
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        createServiceRecord(
            customReport.crane_reference, customReport.id, maintenanceItem.id, payload,
            handleSuccessResponse, onError, onDone
        );
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Service record has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <ServiceRecordForm
                  {...props}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  getMotionOperationTime={getMotionOperationTime}
                />
            )}
        </Formik>
    );
}
