import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {isAdmin} from 'utils/Auth';
import DataExportRequest from './DataExportRequest';
import DataExportsList from './DataExportsList';

export default function CraneDataExport({crane, motionsList, motionParameters, odometerParameters}) {
    const isMounted = useRef(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const handleExportButtonClick = () => {
        setIsEditMode(true);
    };

    const handleBackButtonClick = () => {
        setIsEditMode(false);
    };

    const handleDataSubmitted = () => {
        setIsEditMode(false);
    };

    let button;
    if (isEditable) {
        if (isEditMode) button = <Button className="btn-close float-right" onClick={handleBackButtonClick}>Back</Button>;
        else button = <Button className="btn-add float-right" onClick={handleExportButtonClick}>Export</Button>;
    }

    let content;
    if (isEditMode) content = (
        <DataExportRequest
          crane={crane}
          motionsList={motionsList}
          motionParameters={motionParameters}
          odometerParameters={odometerParameters}
          onDataSubmitted={handleDataSubmitted}
        />
    );
    else content = <DataExportsList crane={crane} />;

    return (
        <div className="content">
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">Crane data export</span>
                    {button}
                </Col>
            </Row>
            {content}
        </div>
    );
};
