import React, {useState} from 'react';
import {Alert, Col, Row, Spinner} from 'react-bootstrap';
import OdometerChart from './OdometerChart';
import OdometerChartForm from './OdometerChartForm';
import OdometerDataForm from './OdometerDataForm';
import OdometerTable from './OdometerTable';

const alertStyle = {
    marginTop: '20px',
    color: 'black',
    fontWeight: 'bold',
};
const spinnerStyle = {
    marginTop: '20px',
    marginBottom: '20px',
};

export default function CraneOdometer({crane, motionsList, odometerParameters}) {
    const [isLoading, setIsLoading] = useState(false);
    const [odometerData, setOdometerData] = useState({list: [], cmaa: {}, total: {}});
    const [selectedParameters, setSelectedParameters] = useState([]);

    let content;
    if (isLoading) content = <div className="text-center" style={spinnerStyle}><Spinner animation="border" /></div>;
    else if (odometerData.list.length === 0) content = <Alert variant="dark" style={alertStyle}>No data</Alert>;
    else content = (
        <>
            <Row>
                <Col>
                    <OdometerChartForm
                      odometerParameters={odometerParameters}
                      setSelectedParameters={setSelectedParameters}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6">
                    <OdometerChart
                      odometerData={odometerData}
                      odometerParameters={odometerParameters}
                      selectedParameters={selectedParameters}
                    />
                </Col>
                <Col sm="12" md="6">
                    <OdometerTable
                      odometerData={odometerData}
                    />
                </Col>
            </Row>
        </>
    );

    return (
        <div className="content">
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">Odometer</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OdometerDataForm
                      crane={crane}
                      motionsList={motionsList}
                      odometerParameters={odometerParameters}
                      setIsLoading={setIsLoading}
                      setOdometerData={setOdometerData}
                    />
                </Col>
            </Row>
            {content}
        </div>
    );
};
