import React from 'react';
import {createRoot} from 'react-dom/client';
import SmartCraneApp from './SmartCraneApp';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-toastify/dist/ReactToastify.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import 'assets/demo/login.css';
import 'assets/demo/demo.css';
import 'assets/demo/loading.css';
import 'assets/demo/button.css';
import 'assets/demo/reacttable.css';
import 'assets/demo/custom_form.css';

library.add(far, fas);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<SmartCraneApp />);
