import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteSite, getSitesList} from 'api/SitesApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isSystemAdmin} from 'utils/Auth';

let siteObject;

export default function SitesList({customer, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [sites, setSites] = useState([]);
    const isEditable = isSystemAdmin() || isCustomerAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Sites');
        loadSitesList();
        return () => isMounted.current = false;
    }, [customer]);

    const loadSitesList = () => {
        setIsLoading(true);
        const onSuccess = response => setSites(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getSitesList(customer && customer.id, onSuccess, handleApiError, onDone);
    };

    const handleDeleteClick = (site) => {
        siteObject = site;
        setDeleteDialogBody(<p>Do you really want to delete the site "<b>{site.site_name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteSite(siteObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Site has been successfully deleted');
        if (isMounted.current) loadSitesList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (sites.length) {
        tableContent = sites.map(site => {
            const locationsLink = (
                <Link to={{pathname: '/app/locations', state: {site: site}}}>{site.total_locations}</Link>
            );
            const cranesLink = (
                <Link to={{pathname: '/app/cranes', state: {site: site}}}>{site.total_cranes}</Link>
            );
            return (
                <tr key={site.id}>
                    <td>{site.site_id}</td>
                    <td>{site.site_name}</td>
                    <td>{site.customer.customer_name}</td>
                    <td>{site.site_address}</td>
                    <td className="text-center">{site.total_locations ? <b>{locationsLink}</b> : site.total_locations}</td>
                    <td className="text-center">{site.total_cranes ? <b>{cranesLink}</b> : site.total_cranes}</td>
                    <td className="text-center">{site.active ? 'Yes' : 'No'}</td>
                    {isEditable &&
                        <>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => onEditClick(site)}>Edit</span>
                            </td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => handleDeleteClick(site)}>Delete</span>
                            </td>
                        </>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7}>No sites found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting site" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th className="text-center">Total locations</th>
                        <th className="text-center">Total cranes</th>
                        <th className="text-center">Active</th>
                        {isEditable &&
                            <th colSpan="2" className="text-center">
                                <button className="btn btn-add" onClick={onAddClick}>Add</button>
                            </th>}
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
