import React from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {zeroFill} from 'utils/Utils';


export default function HealthCheckIndicator({data, size}) {
    size = size || '2xs';
    let alpha = 0;
    let color = '#ccc';
    let title = 'Never seen';
    const maxTimestamp = Math.max(0, ...Object.values(data || {}).map(moduleData => moment(moduleData.timestamp).unix()));
    if (maxTimestamp > 0) {
        const difference = moment().unix() - maxTimestamp;  // seconds
        if (difference <= 86400) {  // 24 hours
            alpha = 55 + Math.round(200 * (1 - difference / 86400));
            color = '#00ff00' + zeroFill(alpha.toString(16), 2);
        }
        title = 'Last seen ' + moment.duration(-difference, 'seconds').humanize(true);
    }
    return <FontAwesomeIcon icon="fa-circle" size={size} color={color} style={{marginRight: '6px'}} title={title} />
};
