import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Spinner, Table} from 'react-bootstrap';
import {getCustomEventsList} from 'api/CustomEventsApi';
import {alertTypes} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';

export default function CustomEventsList({crane, customReport, maintenanceItem, onEditClick}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [customEvents, setCustomEvents] = useState([]);
    const isDraft = customReport.status === 1;
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        loadCustomEventsList();
        return () => isMounted.current = false;
    }, []);

    const loadCustomEventsList = () => {
        setIsLoading(true);
        const onSuccess = response => setCustomEvents(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCustomEventsList(crane.id, customReport.id, maintenanceItem.id, onSuccess, handleApiError, onDone);
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan="6" className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (customEvents.length) {
        tableContent = customEvents.map(customEvent => {
            let serviceIntervalDaysPercentage = '-';
            let serviceIntervalHoursPercentage = '-';
            customEvent.params.forEach(param => {
                if (param.param === 'calendar_time_in_operation') serviceIntervalDaysPercentage = param.value;
                else if (param.param === 'operation_time') serviceIntervalHoursPercentage = param.value;
            });
            return (
                <tr key={customEvent.id}>
                    <td>{customEvent.name}</td>
                    <td className="text-center">{alertTypes[customEvent.alert_type]}</td>
                    <td>{customEvent.alert_text}</td>
                    <td className="text-center">{serviceIntervalDaysPercentage}</td>
                    <td className="text-center">{serviceIntervalHoursPercentage}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(customEvent)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan="6">No events found</td></tr>;
    }

    return (
        <>
            <div>
                <span className="text-card-title">{customReport.name} ({customReport.motion.name}) / {maintenanceItem.name} / Report events</span>
                <button className="btn btn-add float-right" onClick={() => history.goBack()}>Back</button>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-center">Alert type</th>
                        <th>Alert text</th>
                        <th className="text-center">Service interval (days) percentage</th>
                        <th className="text-center">Service interval (hours) percentage</th>
                        <th />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
