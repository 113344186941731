import React, {useEffect, useRef, useState} from 'react';
import {getEdgeDetails} from 'api/EdgesApi';
import {getEventParams} from 'api/EventsApi';
import {arrayToObject, isEmpty} from 'utils/Utils';
import NotificationDetails from './NotificationDetails';
import NotificationsList from './NotificationsList';

const DEFAULT_LIMIT = 15;

export default function CraneNotifications({crane, motionsList}) {
    const isMounted = useRef(false);
    const [edge, setEdge] = useState({});
    const [eventParams, setEventParams] = useState({});
    const [filters, setFilters] = useState({limit: DEFAULT_LIMIT, offset: 0});
    const [notification, setNotification] = useState({});

    const motionOptions = motionsList.map(motion => ({label: motion.motion_name, value: motion.motion_uuid}));

    useEffect(() => {
        isMounted.current = true;
        loadEdgeDetails();
        loadEventParams();
        return () => isMounted.current = false;
    }, []);

    const loadEdgeDetails = () => {
        const onSuccess = response => isMounted.current && setEdge(response.data);
        getEdgeDetails(crane.id, onSuccess);
    };

    const loadEventParams = () => {
        const onSuccess = response => isMounted.current && setEventParams(
            arrayToObject(response.data, 'name', 'display')
        );
        getEventParams(onSuccess);
    };

    const handleViewDataClick = notification => setNotification(notification);

    const handleBackClick = () => setNotification({});

    let content;
    if (!isEmpty(notification)) content = (
        <NotificationDetails
          crane={crane}
          motionOptions={motionOptions}
          eventParams={eventParams}
          notification={notification}
          onBackClick={handleBackClick}
        />
    );
    else content = (
        <NotificationsList
          crane={crane}
          edge={edge}
          motionsList={motionsList}
          filters={filters}
          setFilters={setFilters}
          onViewDataClick={handleViewDataClick}
        />
    );

    return <div className="content">{content}</div>;
};
