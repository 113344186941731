import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {arrayToObject, isEmpty, objectToOptions} from 'utils/Utils';

const DEFAULT_MOTION_OPTION = {label: '-- Select motion --', value: ''};
const DEFAULT_PARAMETER_OPTION = {label: '-- Select parameter --', value: ''};
const conditionOptions = [
    {label: '-- Select condition --', value: ''},
    {label: '<', value: '<'},
    {label: '≤', value: '<='},
    {label: '>', value: '>'},
    {label: '≥', value: '>='},
    {label: '==', value: '=='},
];

export default function EventConfigurationForm({
    dirty, isValid, isSubmitting, handleSubmit,
    isEditable, isLoading, currentConfiguration, eventParams, onCloseButtonClick
}) {
    const isMounted= useRef(false);
    const [motionOptions, setMotionOptions] = useState([DEFAULT_MOTION_OPTION]);
    const [parameterOptions, setParameterOptions] = useState([DEFAULT_PARAMETER_OPTION]);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        getMotionOptions();
        getParameterOptions();
        return () => isMounted.current = false;
    }, [currentConfiguration, eventParams]);

    const getMotionOptions = () => {
        if (isEmpty(currentConfiguration)) setMotionOptions([DEFAULT_MOTION_OPTION]);
        else setMotionOptions([
            DEFAULT_MOTION_OPTION,
            ...objectToOptions(arrayToObject(currentConfiguration.crane_details.motion_config, 'id', 'motion_name')),
        ]);
    };

    const getParameterOptions = () => {
        setParameterOptions([DEFAULT_PARAMETER_OPTION, ...objectToOptions(eventParams)]);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Event name" type="text" name="event_name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Motion" name="motion_reference" options={motionOptions} required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Motion parameter" name="event_key" options={parameterOptions} required disabled={isDisabled} />
                <Select label="Condition" name="event_condition" options={conditionOptions} required disabled={isDisabled} />
                <Input label="Value" type="number" step="0.01" name="event_value" required disabled={isDisabled} />
                <Input label="For seconds" type="number" min="0" step="1" name="event_seconds" required disabled={isDisabled} />
            </Row>
            <Row><Col><label>Action in addition to notification</label></Col></Row>
            <Row>
                <Switch label="Send data to cloud" id="event_actions_1" name="event_actions" value="1" disabled={isDisabled} />
            </Row>
            <Row>
                <Switch label="Send email" id="event_actions_2" name="event_actions" value="2" disabled={isDisabled} />
            </Row>
            <Row>
                <Switch label="Send SMS" id="event_actions_3" name="event_actions" value="3" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            <HiddenInput name="ts" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseButtonClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
