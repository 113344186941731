import ApiClient from './ApiClient';

const apiClient = ApiClient('/vfd');

export const createVfd = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateVfd = (craneId, vfdId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${vfdId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertVfd = (craneId, payload, onSuccess, onError, onDone) => {
    const vfdId = payload.id;
    delete payload.id;
    if (vfdId) updateVfd(craneId, vfdId, payload, onSuccess, onError, onDone);
    else createVfd(craneId, payload, onSuccess, onError, onDone);
};
