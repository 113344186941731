import {alertTypes, capacityUnits} from './dataConstants';

export const ACCESS_TOKEN_NAME = 'accessToken';
export const APP_PARAMETERS = 'app_parameters';
export const CRANE_SYSTEM_REF = 'crane_system';
export const CUSTOMER_REF = 'customer';
export const LOCATION_REF = 'location';
export const PROPERTY_WEB_PARAMS = 'web_params';
export const REFRESH_TOKEN_NAME = 'refreshToken';
export const SITE_REF = 'site';
export const USER_EMAIL = 'user_email';
export const USER_ID = 'user_id';
export const USER_NAME = 'user_name';
export const USER_ROLE = 'user_role';
export const alertTypeOptions = [
    {label: '-- Select alert type --', value: ''},
    ...Object.entries(alertTypes).map(([k, v]) => ({label: v, value: k}))
];

export const capacityUnitOptions = [
    {label: '-- Select unit --', value: ''},
    ...Object.entries(capacityUnits).map(([k, v]) => ({label: v, value: k}))
];
export const cmaaClassOptions = [
    {label: '', value: ''},
    {label: 'A', value: 'A'},
    {label: 'B', value: 'B'},
    {label: 'C', value: 'C'},
    {label: 'D', value: 'D'},
    {label: 'E', value: 'E'},
    {label: 'F', value: 'F'},
];
export const communicationTypeOptions = [
    {label: 'Select type', value: ''},
    {label: 'PLC', value: '0'},
    {label: 'MODBUSRTU', value: '1'},
];
export const loadCellModeOptions = [
    {label: 'Select mode', value: ''},
    {label: '2 points', value: '1'},
    {label: '3 points', value: '2'},
];
export const motionTypeOptions = [
    {label: 'Select type', value: ''},
    {label: 'Hoist', value: '1'},
    {label: 'Miscellaneous', value: '2'},
    {label: 'Rotate', value: '3'},
    {label: 'Traverse', value: '4'},
];
export const hourOptions = [
    {label: '00:00', value: '0'},
    {label: '01:00', value: '1'},
    {label: '02:00', value: '2'},
    {label: '03:00', value: '3'},
    {label: '04:00', value: '4'},
    {label: '05:00', value: '5'},
    {label: '06:00', value: '6'},
    {label: '07:00', value: '7'},
    {label: '08:00', value: '8'},
    {label: '09:00', value: '9'},
    {label: '10:00', value: '10'},
    {label: '11:00', value: '11'},
    {label: '12:00', value: '12'},
    {label: '13:00', value: '13'},
    {label: '14:00', value: '14'},
    {label: '15:00', value: '15'},
    {label: '16:00', value: '16'},
    {label: '17:00', value: '17'},
    {label: '18:00', value: '18'},
    {label: '19:00', value: '19'},
    {label: '20:00', value: '20'},
    {label: '21:00', value: '21'},
    {label: '22:00', value: '22'},
    {label: '23:00', value: '23'},
];
export const vfdParityOptions = [
    {label: 'Select parity', value: ''},
    {label: 'No parity', value: '0'},
    {label: 'Even parity', value: '1'},
    {label: 'Odd parity', value: '2'},
];
export const vfdClassOptions = [
    {label: 'Select class', value: ''},
    {label: 'VG+S5', value: '6'},
    {label: 'VG+S4', value: '0'},
    {label: 'VG+S3', value: '1'},
    {label: 'G+S5', value: '5'},
    {label: 'G+S4', value: '2'},
    {label: 'G+S3', value: '3'},
    {label: 'G+M', value: '4'},
];
export const wifiModeOptions = [
    {label: 'Select mode', value: ''},
    {label: 'AP', value: '1'},
    {label: 'Client', value: '2'},
];

export const wifiSecurityTypeOptions = [
    {label: 'Select security type', value: ''},
    {label: 'WPA PSK', value: '1'},
    {label: 'WPA2 PSK', value: '2'},
    {label: 'Mixed mode', value: '3'},
    {label: 'WPA3 PSK', value: '4'},
    {label: 'No security', value: '5'},
];
