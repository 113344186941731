import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Switch from 'components/Form/Switch';

export default function CustomerForm({dirty, isValid, isSubmitting, handleSubmit, isEditable, isLoading, onCloseClick}) {
    const isMounted = useRef(false);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Customer ID" type="text" name="customer_id" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Customer name" type="text" name="customer_name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
