import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';
import {loadCustomers, loadLocations, loadSites} from 'utils/Ui';
import CraneSystemDetails from './CraneSystemDetails';
import CraneSystemsList from './CraneSystemsList';

export default function CraneSystems(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [craneSystem, setCraneSystem] = useState({});
    const [customers, setCustomers] = useState({});
    const [sites, setSites] = useState({});
    const [locations, setLocations] = useState({});
    const location = props.location.state && props.location.state.location;
    const useCustomer = isSystemAdmin();
    const useSite = isSystemAdmin() || isCustomerAdmin();
    const useLocation = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin();

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/crane-systems');
        setPageTitle('Crane systems');
        if (useCustomer) loadCustomers(setCustomers, handleApiError);
        if (useSite) loadSites(setSites, handleApiError);
        if (useLocation) loadLocations(setLocations, handleApiError);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setCraneSystem({});
        setIsEditMode(true);
    };

    const handleEditClick = (craneSystem) => {
        setCraneSystem(craneSystem);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <CraneSystemDetails
          craneSystem={craneSystem}
          useCustomer={useCustomer}
          useSite={useSite}
          useLocation={useLocation}
          customers={customers}
          sites={sites}
          locations={locations}
          onCloseClick={handleCloseClick}
        />
    );
    return <CraneSystemsList location={location} onAddClick={handleAddClick} onEditClick={handleEditClick} />;
}
