import React, {useState} from 'react'
import axios from 'axios'
import { useHistory,Redirect } from 'react-router-dom'
import { Form, Button, Navbar, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from "react-toastify"

import LoginForms from './Login'

const GetPassword = (props) => {
    let history = useHistory()
    let emailObj = props.emailObj ? props.emailObj : ''
    let otpObj = props.otpObj ? props.otpObj : ''
    const [state, setState] = useState({
        email: emailObj,
        otp : otpObj,
        password:'',
        flag:false,
        rePassword:'',
        messageType: null,
        messageText: null
    })

    const style = {
        top: 'auto',
    }

    const createMessage = (type, text) => {
        setState({
            ...state,
            messageType: type,
            messageText: text,
        })
        setTimeout(() => {
            removeMessage()
        }, 3000)
    }

    const createToast = (text) =>{
        toast.error(text, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true
        })
    }

    const removeMessage = () => {
        setState({
            ...state,
            messageType: null,
            messageText: null,
        })
    }

    const passLengthValidation = (value) => {
        const re = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*#?&\.])[A-Za-z0-9@$!%*#?&\.]{8,32}$");
        return re.test(value);
    };

    const isPasswordValid = (password, passwordConfirmation) => {
        if (!password || !passwordConfirmation) return false;
        return password === passwordConfirmation;
    };

    return (
        <div>
            <Formik
              initialValues={{
                password: '',
                rePassword: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                    .required('Password is required.')
                    .min(6, "Password is too short - should be 6 characters minimum"),
                rePassword: Yup.string()
                    .required('Password is required.')
                    .min(6, "Password is too short - should be 6 characters minimum")
              })}
              onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                        if(passLengthValidation(values.password)){
                            if(isPasswordValid(values.password,values.rePassword)){
                                const postData = {}
                                postData.email = state.email
                                postData.otp = state.otp
                                postData.password = values.password
                                const url = `${window.REST_API_URL}/users/update/password/`
                                axios.post(url, postData)
                                    .then(function (response) {
                                            const confirmBox = window.confirm(response.data.message)
                                            if (confirmBox === true) {
                                                setSubmitting(true)
                                                setState({
                                                    email:postData.email,
                                                    password:postData.password,
                                                    flag:true
                                                })
                                            }else{
                                                setSubmitting(true)
                                                setState({
                                                    email:postData.email,
                                                    password:postData.password,
                                                    flag:true
                                                })
                                            }

                                    })
                                    .catch(function (error) {
                                        if(error.response){
                                            setSubmitting(false)
                                            createToast("Incorrect Password.")
                                        }
                                    })
                            }else{
                                setSubmitting(false)
                                createToast("Password Mismatch!!!")
                            }
                        }else{
                            setSubmitting(false)
                            createToast("Password is too short - should be 8 characters minimum.")
                        }
                  }, 500)
              }}
            >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;
                return (
                    <div className='content'>
                        {state.flag ?
                            <Redirect LoginForms />
                        :
                        <Form onSubmit={handleSubmit}>
                            <Alert variant={state.messageType}>
                                {state.messageText}
                            </Alert>
                            <p style={{textAlignVertical: "center",textAlign: "center",color: 'primary'}}>Your Mail ID is {state.email}</p>
                            <Form.Group size='lg' controlId='otp'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  name='password'
                                  id='input-password'
                                  className={
                                    errors.password && touched.password ? 'input error' : 'input'
                                  }
                                  type='password'
                                  placeholder='Enter a new password'
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.password && touched.password && (
                                    <Form.Text className="text-muted text-danger">{errors.password}</Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group size='lg' controlId='otp'>
                                <Form.Label>Re-Enter Password</Form.Label>
                                <Form.Control
                                  name='rePassword'
                                  id='input-rePassword'
                                  className={
                                    errors.rePassword && touched.rePassword ? 'input error' : 'input'
                                  }
                                  type='password'
                                  placeholder='Re-enter the new password'
                                  value={values.rePassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.rePassword && touched.rePassword && (
                                    <Form.Text className="text-muted text-danger">{errors.rePassword}</Form.Text>
                                )}
                            </Form.Group>
                            <Button block size='lg' type='submit' disabled={isSubmitting}>
                                Reset Password
                            </Button>
                            <ToastContainer />
                        </Form>}
                    </div>
                )
            }}
            </Formik>
        </div>
    )
}

export default GetPassword
