import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertLocation} from 'api/LocationsApi';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import LocationForm from './LocationForm';

const DEFAULT_INSTANCE = {
    id: '',
    location_id: '',
    location_name: '',
    active: ['1'],
};
const VALIDATION_RULES = {
    location_id: Yup.string().required('Location ID is required'),
    location_name: Yup.string().required('Location name is required'),
};

export default function LocationDetails({location, useCustomer, useSite, customers, sites, onCloseClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin();
    const validationRules = {...VALIDATION_RULES};
    if (useCustomer) validationRules.customer_uuid = Yup.string().required('Customer is required');
    if (useSite) validationRules.site_uuid = Yup.string().required('Site is required');
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(isEmpty(location) ? 'New location' : 'Location');
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = location.hasOwnProperty(key) ? location[key] : value;
                }
                if (useCustomer) newData.customer_uuid = location.customer ? location.customer.customer_uuid : '';
                if (useSite) newData.site_uuid = location.site ? location.site.site_uuid : '';
                newData.active = newData.active ? ['1'] : [];
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        if (!useCustomer) delete payload.customer_uuid;
        if (!useSite) delete payload.site_uuid;
        payload.active = Array.isArray(payload.active) && payload.active.length ? parseInt(payload.active[0]) === 1 : false;
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertLocation(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Location has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <LocationForm
                  {...props}
                  useCustomer={useCustomer}
                  useSite={useSite}
                  customers={customers}
                  sites={sites}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
