import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spinner, Table} from 'react-bootstrap';
import {deleteCraneSystem, getCraneSystemsList} from 'api/CraneSystemsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isLocationAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';

let craneSystemObject;

export default function CraneSystemsList({location, onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [craneSystems, setCraneSystems] = useState([]);
    const isEditable = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin() || isLocationAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Crane systems');
        loadCraneSystemsList();
        return () => isMounted.current = false;
    }, [location]);

    const loadCraneSystemsList = () => {
        setIsLoading(true);
        const onSuccess = response => setCraneSystems(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCraneSystemsList(
            location && location.customer.id, location && location.site.id, location && location.id,
            onSuccess, handleApiError, onDone
        );
    };

    const handleDeleteClick = (craneSystem) => {
        craneSystemObject = craneSystem;
        setDeleteDialogBody(<p>Do you really want to delete the crane system "<b>{craneSystem.crane_system_name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteCraneSystem(craneSystemObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Crane system has been successfully deleted');
        if (isMounted.current) loadCraneSystemsList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (craneSystems.length) {
        tableContent = craneSystems.map(craneSystem => {
            const cranesLink = (
                <Link to={{pathname: '/app/cranes', state: {craneSystem: craneSystem}}}>{craneSystem.total_cranes}</Link>
            );
            return (
                <tr key={craneSystem.id}>
                    <td>{craneSystem.crane_system_id}</td>
                    <td>{craneSystem.crane_system_name}</td>
                    <td>{craneSystem.customer.customer_name}</td>
                    <td>{craneSystem.site.site_name}</td>
                    <td>{craneSystem.location.location_name}</td>
                    <td className="text-center">{craneSystem.total_cranes ? <b>{cranesLink}</b> : craneSystem.total_cranes}</td>
                    <td className="text-center">{craneSystem.active ? 'Yes' : 'No'}</td>
                    {isEditable &&
                        <>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => onEditClick(craneSystem)}>Edit</span>
                            </td>
                            <td className="text-center">
                                <span className="text-primary text-link" onClick={() => handleDeleteClick(craneSystem)}>Delete</span>
                            </td>
                        </>}
                </tr>
            );
        });
    } else {
        tableContent = <tr><td colSpan={isEditable ? 9 : 7}>No crane systems found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting crane system" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Customer</th>
                        <th>Site</th>
                        <th>Location</th>
                        <th className="text-center">Total cranes</th>
                        <th className="text-center">Active</th>
                        {isEditable &&
                            <th colSpan="2" className="text-center">
                                <button className="btn btn-add" onClick={onAddClick}>Add</button>
                            </th>}
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
