import React from 'react';
import {getCraneSystemsList} from 'api/CraneSystemsApi';
import {getCustomersList} from 'api/CustomersApi';
import {getLocationsList} from 'api/LocationsApi';
import {getSitesList} from 'api/SitesApi';
import {APP_PARAMETERS} from 'constants/apiConstants';
import {
    DEFAULT_CRANE_SYSTEM_OPTION,
    DEFAULT_CUSTOMER_OPTION,
    DEFAULT_LOCATION_OPTION,
    DEFAULT_SITE_OPTION
} from 'constants/uiConstants';
import {arrayToObject} from './Utils';

export const mdash = <>&mdash;</>;

export const getDataFromLocalStorage = (key) => {
    const storedData = window.localStorage.getItem(APP_PARAMETERS) || '{}';
    const data = JSON.parse(storedData);
    return key ? data[key] : data;
};

export const saveDataToLocalStorage = (key, value) => {
    const currentData = getDataFromLocalStorage();
    localStorage.setItem(
        APP_PARAMETERS, JSON.stringify({...currentData, [key]: value})
    );
};

export const getBrand = (routes) => {
    let brandName = 'Smart Crane';
    routes.map(route => {
        if (window.location.href.indexOf(route.path) > -1) brandName = route.name;
    });
    return brandName;
};

export const loadCustomers = (setCustomers, onError) => {
    const onSuccess = response => setCustomers(arrayToObject(response.data));
    getCustomersList(onSuccess, onError);
};

export const loadSites = (setSites, onError) => {
    const onSuccess = response => setSites(arrayToObject(response.data));
    getSitesList(undefined, onSuccess, onError);
};

export const loadLocations = (setLocations, onError) => {
    const onSuccess = response => setLocations(arrayToObject(response.data));
    getLocationsList(undefined, undefined, onSuccess, onError);
};

export const loadCraneSystems = (setCraneSystems, onError) => {
    const onSuccess = response => setCraneSystems(arrayToObject(response.data));
    getCraneSystemsList(undefined, undefined, undefined, onSuccess, onError);
};

export const getCustomerOptions = (customers) => {
    const options = [DEFAULT_CUSTOMER_OPTION];
    Object.values(customers).map(
        customer => options.push({label: customer.customer_name, value: customer.customer_uuid})
    );
    return options;
};

export const getSiteOptions = (sites, useCustomer, customerUuid, customerUuidChanged, setFieldValue) => {
    const options = [DEFAULT_SITE_OPTION];
    if (customerUuid === '') setFieldValue('site_uuid', '', false);
    else {
        if (!useCustomer) Object.values(sites).map(
            site => options.push({label: site.site_name, value: site.site_uuid})
        );
        else {
            if (customerUuidChanged) setFieldValue('site_uuid', '', false);
            Object.values(sites).map(site => {
                if (site.customer.customer_uuid === customerUuid) options.push({label: site.site_name, value: site.site_uuid})
            });
        }
    }
    return options;
};

export const getLocationOptions = (locations, useSite, siteUuid, siteUuidChanged, setFieldValue) => {
    const options = [DEFAULT_LOCATION_OPTION];
    if (siteUuid === '') setFieldValue('location_uuid', '', false);
    else {
        if (!useSite) Object.values(locations).map(
            location => options.push({label: location.location_name, value: location.location_uuid})
        );
        else {
            if (siteUuidChanged) setFieldValue('location_uuid', '', false);
            Object.values(locations).map(location => {
                if (location.site.site_uuid === siteUuid) options.push({label: location.location_name, value: location.location_uuid})
            });
        }
    }
    return options;
};

export const getCraneSystemOptions = (craneSystems, useLocation, locationUuid, locationUuidChanged, setFieldValue) => {
    const options = [DEFAULT_CRANE_SYSTEM_OPTION];
    if (locationUuid === '') setFieldValue('crane_system_uuid', '', false);
    else {
        if (!useLocation) Object.values(craneSystems).map(
            craneSystem => options.push({label: craneSystem.crane_system_name, value: craneSystem.crane_system_uuid})
        );
        else {
            if (locationUuidChanged) setFieldValue('crane_system_uuid', '', false);
            Object.values(craneSystems).map(craneSystem => {
                if (craneSystem.location.location_uuid === locationUuid) options.push({label: craneSystem.crane_system_name, value: craneSystem.crane_system_uuid})
            });
        }
    }
    return options;
};
