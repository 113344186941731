import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import CustomerDetails from './CustomerDetails';
import CustomersList from './CustomersList';

export default function Customers() {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/customers');
        setPageTitle('Customers');
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setCustomer({});
        setIsEditMode(true);
    };

    const handleEditClick = (customer) => {
        setCustomer(customer);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return <CustomerDetails customer={customer} onCloseClick={handleCloseClick} />;
    return <CustomersList onAddClick={handleAddClick} onEditClick={handleEditClick} />;
}
