import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import Select from 'components/Form/Select';
import {alertTypeOptions} from 'constants/apiConstants';

export default function EventTemplateForm({
    dirty, isValid, isSubmitting, handleSubmit,
    parameterOptions, isDraft, isEditable, isLoading, onCloseClick,
}) {
    const isMounted = useRef(false);
    const isDisabled = !isEditable || !isDraft || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Alert type" name="alert_type" options={alertTypeOptions} disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Alert text" type="text" name="alert_text" required disabled={isDisabled} />
            </Row>
            <Row>
                <MultiCheckboxes label="Parameters" name="params" options={parameterOptions} disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && isDraft &&
                <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
