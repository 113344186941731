import React, {useEffect, useRef} from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {wifiModeOptions, wifiSecurityTypeOptions} from 'constants/apiConstants';

export default function EdgeConfigurationForm({
    initialValues, values, dirty, isValid, isSubmitting, handleSubmit,
    showDeviceId, editDeviceId, showAdminCredentials, editAdminCredentials, isEditable, isLoading,
}) {
    const isMounted= useRef(false);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const showNetworkSettings = parseInt(values.mode) === 1;

    return (
        <Form onSubmit={handleSubmit}>
            {showDeviceId &&
                <Row>
                    <Input label="Device ID" type="text" name="device_id" info="Device ID in IoT Hub" required disabled={isDisabled || !editDeviceId} />
                </Row>}
            <Row className="form-section"><Col><Form.Label>Wifi Config</Form.Label></Col></Row>
            <Row>
                <Select
                  label="Mode"
                  name="mode"
                  options={wifiModeOptions}
                  info="AP = Edge broadcasts its own wifi for users to connect to for Edge UI access.
                  Client = Edge connects to local wifi, users will connect to that same wifi for access to the Edge UI."
                  required disabled={isDisabled}
                />
                <Input label="SSID" type="text" name="ssid" required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Security type" name="security_type" options={wifiSecurityTypeOptions} required disabled={isDisabled} />
                <Input label="Pass phrase" type="password" name="passphrase" info="Enter wifi password only if you want to change it" disabled={isDisabled} />
            </Row>
            {showNetworkSettings &&
                <Row>
                    <Input label="IP address" type="text" name="ip_address" info="Enter IP address (e.g. 192.168.1.100)" required disabled={isDisabled} />
                    <Input label="Gateway" type="text" name="ip_gateway" info="Enter gateway (e.g. 192.168.1.1)" required disabled={isDisabled} />
                    <Input label="Subnet mask" type="text" name="ip_subnetmask" info="Enter subnet mask (e.g. 255.255.255.0)" required disabled={isDisabled} />
                </Row>}
            <Row className="form-section"><Col><Form.Label>Edge Event and Alert</Form.Label></Col></Row>
            <Row>
                <Input
                  label="Recipients' emails"
                  as="textarea"
                  type="text"
                  name="recipients_email"
                  info="Enter recipients' emails with comma(,) separator"
                  disabled={isDisabled}
                />
            </Row>
            <Row>
                <Input
                  label="Recipients' phone numbers"
                  as="textarea"
                  type="text"
                  name="recipients_sms"
                  info="Enter recipients' phone numbers with comma(,) separator"
                  disabled={isDisabled}
                />
            </Row>
            {showAdminCredentials && isEditable &&
                <>
                    <Row className="form-section"><Col><Form.Label>Edge Administrator</Form.Label></Col></Row>
                    <Row className="mb-2">
                        <Input
                          label="Administrator password"
                          type="password"
                          name="administrator_password"
                          info={
                            initialValues.has_administrator_password ?
                            'The administrator password has already been set. If you want to change it, enter new password below.' :
                            'The administrator password has not been set yet.'
                          }
                          disabled={isDisabled || !editAdminCredentials}
                        />
                    </Row>
                </>}
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
        </Form>
    )
};
