import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Spinner, Table} from 'react-bootstrap';
import {getMaintenanceItemsList} from 'api/MaintenanceItemsApi';
import {maintenanceItemStatuses} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';

export default function MaintenanceItemsList({crane, customReport, onEditClick}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [maintenanceItems, setMaintenanceItems] = useState([]);
    const isDraft = customReport.status === 1;
    const isEditable = isAdmin();

    useEffect(() => {
        isMounted.current = true;
        loadMaintenanceItemsList();
        return () => isMounted.current = false;
    }, []);

    const loadMaintenanceItemsList = () => {
        setIsLoading(true);
        const onSuccess = response => setMaintenanceItems(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getMaintenanceItemsList(crane.id, customReport.id, onSuccess, handleApiError, onDone);
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan="6" className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (maintenanceItems.length) {
        tableContent = maintenanceItems.map(maintenanceItem => {
            let serviceIntervalDays = '-';
            let serviceIntervalHours = '-';
            maintenanceItem.params.forEach(param => {
                if (param.param === 'calendar_time_in_operation') serviceIntervalDays = param.value;
                else if (param.param === 'operation_time') serviceIntervalHours = param.value;
            });
            return (
                <tr key={maintenanceItem.id}>
                    <td>{maintenanceItem.name}</td>
                    <td className="text-center">{serviceIntervalDays}</td>
                    <td className="text-center">{serviceIntervalHours}</td>
                    <td className="text-center" style={{fontWeight: 'bold'}}>
                        <Link to={{pathname: '/app/cranes-report-events', state: {crane: crane, customReport: customReport, maintenanceItem: maintenanceItem}}}>
                            {maintenanceItem.custom_events_count}
                        </Link>
                    </td>
                    <td className="text-center">{maintenanceItemStatuses[maintenanceItem.status]}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(maintenanceItem)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan="6">No maintenance items found</td></tr>;
    }

    return (
        <>
            <div>
                <span className="text-card-title">{customReport.name} ({customReport.motion.name}) / Maintenance items</span>
                <button className="btn btn-add float-right" onClick={() => history.goBack()}>Back</button>
            </div>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-center">Service interval (days)</th>
                        <th className="text-center">Service interval (hours)</th>
                        <th className="text-center">Events</th>
                        <th className="text-center">Status</th>
                        <th />
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
