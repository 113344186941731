import ApiClient from './ApiClient';

const apiClient = ApiClient('/maintenance');

export const getMaintenanceReportsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceReport = (craneId, customReportId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${customReportId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const createServiceRecord = (craneId, customReportId, maintenanceItemId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/${customReportId}/${maintenanceItemId}/service_records/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getServiceRecordsList = (craneId, customReportId, maintenanceItemId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/${customReportId}/${maintenanceItemId}/service_records/`).then(onSuccess).catch(onError).finally(onDone);
};

export const deleteServiceRecord = (craneId, customReportId, maintenanceItemId, serviceRecordId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/${customReportId}/${maintenanceItemId}/service_records/${serviceRecordId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceAlertsList = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/alerts/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getMaintenanceAlertsCount = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/alerts/count/', payload).then(onSuccess).catch(onError).finally(onDone);
};
