import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {getDataFromLocalStorage, saveDataToLocalStorage} from 'utils/Ui';
import CustomReportDetails from './CustomReportDetails';
import CustomReportsList from './CustomReportsList';

export default function CustomReports({location}) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [customReport, setCustomReport] = useState({});

    let crane = location.state && location.state.crane;
    if (crane) saveDataToLocalStorage('crane', crane);
    else crane = getDataFromLocalStorage('crane');

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/cranes');
        setPageTitle(crane ? crane.crane_name : 'Custom reports');
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setCustomReport({});
        setIsEditMode(true);
    };

    const handleEditClick = (customReport) => {
        setCustomReport(customReport);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return <CustomReportDetails crane={crane} customReport={customReport} onCloseClick={handleCloseClick} />;
    return <CustomReportsList crane={crane} onAddClick={handleAddClick} onEditClick={handleEditClick} />
}
