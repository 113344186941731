import ApiClient from './ApiClient';

const apiClient = ApiClient('/edges');

export const getEdgeDetails = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertEdge = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};
