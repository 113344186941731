import React, {useEffect, useRef, useState} from 'react';
import {Table, Spinner} from 'react-bootstrap';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';

export default function MotorsList({pageState, currentVersion, currentConfiguration, onDetailsClick, onAddButtonClick}) {
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [motors, setMotors] = useState([]);
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getMotors();
        return () => isMounted.current = false;
    }, [pageState, currentVersion, currentConfiguration]);

    const getMotors = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.crane_details)) {
                setMotors(currentConfiguration.crane_details.motor_config);
            }
            setIsLoading(false);
        }
    };

    let addButton;
    if (isEditable) addButton = <button className="btn btn-add" onClick={onAddButtonClick}>Add</button>;

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="6" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (motors.length === 0) tableContent = <tr><td colSpan="6">No motors found</td></tr>;
    else tableContent = motors.map(motor => (
        <tr key={motor.id}>
            <td>{motor.name}</td>
            <td>{motor.vfd_name}</td>
            <td className="text-center">{motor.horsepower}</td>
            <td className="text-center">{motor.motor_amps.rated}</td>
            <td className="text-center">{motor.motor_reduction_factor}</td>
            <td className="text-center">
                <span className="text-primary text-link" onClick={() => onDetailsClick(motor)}>{isEditable ? 'Edit' : 'View'}</span>
            </td>
        </tr>
    ));

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>VFD</th>
                    <th className="text-center">Horsepower</th>
                    <th className="text-center">Current (Amps) rated</th>
                    <th className="text-center">Motor / motion speed reduction factor</th>
                    <th className="text-center">{addButton}</th>
                </tr>
            </thead>
            <tbody>{tableContent}</tbody>
        </Table>
    );
}
