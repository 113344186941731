import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, Spinner, Table} from 'react-bootstrap';
import {deleteCrane, getCranesHealthCheckData, getCranesList} from 'api/CranesApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import HealthCheckIndicator from 'components/Other/HealthCheckIndicator';
import {configurationStatus} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin, isSystemAdmin, isSystemRole} from 'utils/Auth';

let craneObject;

export default function CranesList({customer, site, location, craneSystem, onAddClick}) {
    const isMounted = useRef(false);
    const craneNameInput = useRef(null);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [isCraneNameCorrect, setIsCraneNameCorrect] = useState(false);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [cranes, setCranes] = useState([]);
    const [healthCheckData, setHealthCheckData] = useState({});
    const showDeviceId = isAdmin() || isSystemRole();
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        loadHealthCheckData();
        const intervalId = setInterval(loadHealthCheckData, 10000);
        return () => {
            isMounted.current = false;
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        setPageTitle('Cranes');
        loadCranesList();
    }, [customer, site, location, craneSystem]);

    const loadHealthCheckData = () => {
        const onSuccess = response => isMounted.current && setHealthCheckData(response.data);
        getCranesHealthCheckData(onSuccess);
    };

    const loadCranesList = () => {
        setIsLoading(true);
        const onSuccess = response => setCranes(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCranesList(
            customer && customer.id, site && site.id,
            location && location.id, craneSystem && craneSystem.id,
            onSuccess, handleApiError, onDone
        );
    };

    const handleCraneNameChange = e => {
        setIsCraneNameCorrect(e.target.value === craneObject.crane_name);
    };

    const handleDeleteClick = crane => {
        craneObject = crane;
        setDeleteDialogBody(
            <>
                <p>Do you really want to delete the crane "<b>{crane.crane_name}</b>"?</p>
                <p>If so, enter its name below and press the "YES" button.</p>
                <Form.Control type="text" onChange={handleCraneNameChange} ref={craneNameInput} />
            </>
        );
        setShowDeleteDialog(true);
    };

    const handleModalOpen = () => {
        if (craneNameInput.current) craneNameInput.current.focus();
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteCrane(craneObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = response => {
        showMessage('success', 'Crane has been successfully deleted');
        if (isMounted.current) loadCranesList();
    };

    let tableContent;
    let colspan = 8;
    if (!showDeviceId) colspan -= 1;
    if (!isEditable) colspan -= 1;
    if (isLoading) tableContent = <tr><td colSpan={colspan} className="text-center"><Spinner animation="border" /></td></tr>;
    else if (cranes.length === 0) tableContent = <tr><td colSpan={colspan}>No cranes found</td></tr>;
    else {
        tableContent = cranes.map(crane => {
            const showLink = crane.version > 1 || crane.status >= 1;
            const craneLink = (
                <Link to={{pathname: '/app/cranes-details', state: {crane: crane}}}>{crane.crane_name}</Link>
            );
            const configurationLink = (
                <Link to={{pathname: '/app/cranes-configure', state: {crane: crane}}}>Configuration</Link>
            );
            const reportsLink = (
                <Link to={{pathname: '/app/cranes-custom-reports', state: {crane: crane}}}>Custom reports</Link>
            );
            return (
                <tr key={crane.crane_uuid}>
                    <td>
                        <HealthCheckIndicator data={healthCheckData[crane.crane_uuid]} size="2xs" />
                        {showLink ? <b>{craneLink}</b> : crane.crane_name}
                    </td>
                    {showDeviceId && <td>{crane.device_id}</td>}
                    <td className="text-center">{crane.version}</td>
                    <td>{configurationStatus[crane.status]}</td>
                    <td className="text-center">{crane.active ? 'Yes' : 'No'}</td>
                    <td className="text-center"><b>{configurationLink}</b></td>
                    <td className="text-center">{showLink ? <b>{reportsLink}</b> : null}</td>
                    {isEditable &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(crane)}>Delete</span>
                        </td>}
                </tr>
            );
        });
    }

    return (
        <>
            <ModalDialog
              title="Deleting crane"
              size="lg"
              body={deleteDialogBody}
              show={showDeleteDialog}
              onShow={handleModalOpen}
              onHide={handleModalClosed}
            >
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed} disabled={!isCraneNameCorrect}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{paddingLeft: '20px'}}>Name</th>
                        {showDeviceId && <th>Device ID</th>}
                        <th className="text-center">Version</th>
                        <th>Status</th>
                        <th className="text-center">Active</th>
                        <th colSpan={isEditable ? 3 : 2}>
                            {isEditable && <Button className="btn-add float-right" onClick={onAddClick}>Add</Button>}
                        </th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
};
