import React, {useEffect, useState} from 'react';
import {Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import {objectToOptions} from 'utils/Utils';

export default function ChartParametersForm({parametersMap, selectedParameters, setSelectedParameters}) {
    const [parameterOptions, setParameterOptions] = useState([]);

    useEffect(() => {
        setParameterOptions(objectToOptions(parametersMap));
    }, [parametersMap]);

    return (
        <Formik
          initialValues={{parameters: selectedParameters}}
          validationSchema={Yup.object().shape({})}
          onSubmit={() => {}}
        >
            {({values}) => {

                useEffect(() => {
                    setSelectedParameters(values.parameters);
                }, [values.parameters]);

                return (
                    <Form>
                        {parameterOptions.length > 0 &&
                            <Row>
                                <MultiCheckboxes label="Parameters" name="parameters" options={parameterOptions} />
                            </Row>}
                    </Form>
                );
            }}
        </Formik>
    );
}
