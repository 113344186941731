import ApiClient from './ApiClient';

const apiClient = ApiClient('/motions');

export const createMotion = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateMotion = (craneId, motionId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/${motionId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertMotion = (craneId, payload, onSuccess, onError, onDone) => {
    const motionId = payload.id;
    delete payload.id;
    if (motionId) updateMotion(craneId, motionId, payload, onSuccess, onError, onDone);
    else createMotion(craneId, payload, onSuccess, onError, onDone);
};

export const getMotionData = (craneId, motionId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/${motionId}/data/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getMotionsList = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};
