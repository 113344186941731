import React, {useEffect, useRef, useState} from 'react';
import {Button, Spinner, Table} from 'react-bootstrap';
import {saveAs} from 'file-saver';
import {deleteDocument, getDocumentsList} from 'api/DocumentsApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {useAppContext} from 'contexts/AppContext';

let documentObject;

export default function DocumentsList({crane, isEditable, onEditClick}) {
    const isMounted= useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteDialogTitle, setDeleteDialogTitle] = useState('');
    const [documentsList, setDocumentsList] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        loadDocumentsList();
        return () => isMounted.current = false;
    }, [crane]);

    const loadDocumentsList = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setDocumentsList(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getDocumentsList(crane.id, onSuccess, handleApiError, onDone);
    };

    const handleViewClick = document => window.open(document.file_download_url);

    const handleDownloadClick = document => saveAs(document.file_download_url, document.file_name);

    const handleDeleteClick = document => {
        documentObject = document;
        setDeleteDialogTitle(`Do you really want to delete document "${document.title}"?`);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteDocument(crane.id, documentObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = response => {
        showMessage('success', 'Document has been successfully deleted');
        if (isMounted.current) loadDocumentsList();
    };

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan={isEditable ? 6 : 4} className="text-center"><Spinner animation="border" /></td></tr>;
    else if (documentsList.length) {
        tableContent = (
            <>
                {documentsList.map(document => {
                    return (
                        <tr key={document.id}>
                            <td>{document.title}</td>
                            <td>{document.description}</td>
                            <td className="text-center"><span className="text-primary text-link" onClick={() => handleViewClick(document)}>View</span></td>
                            <td className="text-center"><span className="text-primary text-link" onClick={() => handleDownloadClick(document)}>Download</span></td>
                            {isEditable && <td className="text-center"><span className="text-primary text-link" onClick={() => onEditClick(document)}>Edit</span></td>}
                            {isEditable && <td className="text-center"><span className="text-primary text-link" onClick={() => handleDeleteClick(document)}>Delete</span></td>}
                        </tr>
                    );
                })}
            </>
        );
    }
    else tableContent = <tr><td colSpan={isEditable ? 6 : 4}>No documents found</td></tr>;

    return (
        <>
            <ModalDialog title={deleteDialogTitle} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th colSpan={isEditable ? 4 : 2} />
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>
        </>
    );
}
