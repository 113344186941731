import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Collapse, Navbar, NavItem, Nav, Container} from 'reactstrap';
import classnames from 'classnames';

export default function AuthNavbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [color, setColor] = useState('transparent');

    const toggle = () => {
        if (isOpen) setColor('transparent');
        else setColor('dark');
        setIsOpen(!isOpen);
    }

    return (
        <Navbar className={classnames('navbar-absolute fixed-top', color)} expand="lg">
            <Container>
                <button
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  type="button"
                  onClick={toggle}
                >
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </button>
                <Collapse isOpen={isOpen} className="justify-content-end" navbar>
                    <Nav navbar>
                        <NavItem />
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};
