import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import ReportTemplateDetails from './ReportTemplateDetails';
import ReportTemplatesList from './ReportTemplatesList';

export default function ReportTemplates(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [reportTemplate, setReportTemplate] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/report-templates');
        setPageTitle('Report templates');
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setReportTemplate({});
        setIsEditMode(true);
    };

    const handleEditClick = (reportTemplate) => {
        setReportTemplate(reportTemplate);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return <ReportTemplateDetails reportTemplate={reportTemplate} onCloseClick={handleCloseClick} />;
    return <ReportTemplatesList onAddClick={handleAddClick} onEditClick={handleEditClick} />
}
