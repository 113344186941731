import ApiClient from './ApiClient';

const apiClient = ApiClient('/cranes');

export const createCrane = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateCrane = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${craneId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertCrane = (payload, onSuccess, onError, onDone) => {
    const craneId = payload.id;
    delete payload.id;
    if (craneId) updateCrane(craneId, payload, onSuccess, onError, onDone);
    else createCrane(payload, onSuccess, onError, onDone);
};

export const deleteCrane = (craneId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${craneId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCranesList = (customerId, siteId, locationId, craneSystemId, onSuccess, onError, onDone) => {
    let url = '/';
    const params = [];
    if (customerId) params.push(`customer_id=${customerId}`);
    if (siteId) params.push(`site_id=${siteId}`);
    if (locationId) params.push(`location_id=${locationId}`);
    if (craneSystemId) params.push(`crane_system_id=${craneSystemId}`);
    if (params.length) url += '?' + params.join('&');
    apiClient.get(url).then(onSuccess).catch(onError).finally(onDone);
};

export const getCraneChartData = (craneId, payload, onSuccess, onError, onDone) => {
    apiClient.post(`/${craneId}/chart/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const getCraneConfiguration = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/config/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCraneParameters = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/params/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCraneStatusData = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/status/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getCranesHealthCheckData = (onSuccess, onError, onDone) => {
    apiClient.get('/hc/').then(onSuccess).catch(onError).finally(onDone);
};

export const getCraneHealthCheckData = (craneId, onSuccess, onError, onDone) => {
    apiClient.get(`/${craneId}/hc/`).then(onSuccess).catch(onError).finally(onDone);
};
