import React from 'react';
import {Col, Form, Pagination, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const filterStyle = {display: 'inline-block', marginRight: 10};

export default function NotificationsFilters({
    motionOptions, isLoading, isBackwardDisabled, isForwardDisabled, filters, setFilters
}) {
    const handlePageClick = direction => {
        setFilters(prevFilters => {
            let offset = prevFilters.offset;
            if (direction === 'forward') offset += prevFilters.limit;
            else offset -= prevFilters.limit;
            return {...prevFilters, offset: offset};
        });
    };

    const initialValues = {...filters};
    const page = filters.offset / filters.limit + 1;

    const form = (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => {}}
        >
            {({handleChange}) => {
                const handleInputChange = (e) => {
                    handleChange(e);
                    const filterName = e.target.name;
                    const filterValue = e.target.value;
                    setFilters(prevFilters => {
                        if (filterValue) {
                            return {...prevFilters, [filterName]: filterValue, offset: 0};
                        }
                        else {
                            const newFilters = {...prevFilters, offset: 0};
                            delete newFilters[filterName];
                            return newFilters;
                        }
                    });
                };

                return (
                    <Form>
                        <div style={filterStyle}>
                            <Select
                              label="Motion" name="motion_uuid" options={motionOptions} skipgroup="true"
                              onChange={handleInputChange} disabled={isLoading}
                            />
                        </div>
                        <div style={filterStyle}>
                            <Input
                              label="Date from" type="date" name="date_from" skipgroup="true"
                              onChange={handleInputChange} style={{maxWidth: 150}} disabled={isLoading}
                            />
                        </div>
                        <div style={filterStyle}>
                            <Input
                              label="Date to" type="date" name="date_to" skipgroup="true"
                              onChange={handleInputChange} style={{maxWidth: 150}} disabled={isLoading}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );

    return (
        <Row>
            <Col md="8">
                {form}
            </Col>
            <Col md="4">
                <Pagination className="pagination-sm ac-pagination">
                    <Pagination.Prev className="ac-page-link" onClick={() => handlePageClick('backward')} disabled={isBackwardDisabled} />
                    <Pagination.Item className="ac-page-item" active>{page}</Pagination.Item>
                    <Pagination.Next className="ac-page-link" onClick={() => handlePageClick('forward')} disabled={isForwardDisabled} />
                </Pagination>
            </Col>
        </Row>
    );
}
