import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Pagination, Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {getMaintenanceAlertsList} from 'api/MaintenanceApi';
import {alertTypes} from 'constants/dataConstants';
import {DATETIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemRole} from 'utils/Auth';

const newAlertStyle = {
    backgroundColor: '#ffda23',
    fontWeight: 'bold',
    fontSize: '0.7rem',
};

export default function MaintenanceAlertsList({onDetailsClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, loadMaintenanceAlertsCount, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [filters, setFilters] = useState({limit: 15, offset: 0});
    const [page, setPage] = useState(1);
    const showPrev = page >= 2;
    const showNext = page * filters.limit < recordsCount;
    const showCustomer = isSystemRole();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Maintenance alerts');
        return () => {
            isMounted.current = false;
            loadMaintenanceAlertsCount(true);
        }
    }, []);

    useEffect(() => {
        loadMaintenanceAlertsList();
        return () => {};
    }, [page]);

    const loadMaintenanceAlertsList = () => {
        setIsLoading(true);
        const onSuccess = response => {
            setAlerts(response.data.maintenance_alerts);
            setRecordsCount(response.data.count);
        };
        const onDone = () => isMounted.current && setIsLoading(false);
        getMaintenanceAlertsList(filters, onSuccess, handleApiError, onDone);
    };

    const handlePageClick = (direction) => {
        setFilters(prevFilters => {
            let page = prevFilters.offset / prevFilters.limit + 1;
            page += direction === 'forward' ? 1 : -1;
            setPage(page);
            const offset = (page - 1) * prevFilters.limit;
            return {...prevFilters, offset: offset};
        });
    };

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan={showCustomer ? 7 : 6} className="text-center"><Spinner animation="border" /></td></tr>;
    else if (!alerts.length) tableContent = <tr><td colSpan={showCustomer ? 7 : 6}>No maintenance alerts found</td></tr>;
    else tableContent = alerts.map(alert => {
        const alertTime = moment.utc(alert.created_date).local().format(DATETIME_FORMAT);
        const craneLink = {pathname: '/app/cranes-details', state: {crane: alert.crane}};
        return (
            <tr key={alert.id}>
                <td className="text-center">{alertTime}</td>
                <td className="text-center">
                    {alert.is_new && <div style={newAlertStyle}>NEW</div>}
                    {alertTypes[alert.alert_type]}
                </td>
                <td style={{fontWeight: 'bold'}}><Link to={craneLink}>{alert.crane.name}</Link></td>
                {showCustomer && <td>{alert.customer.customer_name}</td>}
                <td>{alert.report.name}</td>
                <td>
                    <span className="text-primary text-link" onClick={() => onDetailsClick(alert)}>
                        {alert.item.name}
                    </span>
                </td>
                <td>
                    <span className="text-primary text-link" onClick={() => onDetailsClick(alert)}>
                        {alert.content}
                    </span>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Pagination className="pagination-sm ac-pagination" style={{marginTop: 0, marginBottom: '15px'}}>
                <Pagination.Prev className="ac-page-link" onClick={() => handlePageClick('backward')} disabled={!showPrev} />
                <Pagination.Item className="ac-page-item" active>{page}</Pagination.Item>
                <Pagination.Next className="ac-page-link" onClick={() => handlePageClick('forward')} disabled={!showNext} />
            </Pagination>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th className="text-center">Alert date</th>
                        <th className="text-center">Type</th>
                        <th>Crane</th>
                        {showCustomer && <th>Customer</th>}
                        <th>Report</th>
                        <th>Maintenance item</th>
                        <th>Content</th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
