import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import MultiCheckboxes from 'components/Form/MultiCheckboxes';
import Select from 'components/Form/Select';
import {reportTemplateParameters} from 'constants/dataConstants';

const DEFAULT_TEMPLATE_OPTION = {label: '-- Select template --', value: ''};
const DEFAULT_MOTION_OPTION = {label: '-- Select motion --', value: ''};

export default function CustomReportForm({
    values, initialValues, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    templatesList, motionsList, isNew, isDraft, isEditable, isLoading, onCloseClick,
}) {
    const isMounted = useRef(false);
    const [motionTypes, setMotionTypes] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([DEFAULT_TEMPLATE_OPTION]);
    const [motionOptions, setMotionOptions] = useState([DEFAULT_MOTION_OPTION]);
    const isDisabled = !isEditable || !isDraft || isLoading;
    const parameterOptions = Object.entries(reportTemplateParameters).map(([k, v]) => ({label: v, value: k}));

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        if (!isMounted.current) return;
        setTemplateOptions([
            DEFAULT_TEMPLATE_OPTION,
            ...templatesList.map(item => ({label: item.name, value: item.id.toString()}))
        ]);
        updateMotionTypes(parseInt(initialValues.report_template_reference));
    }, [templatesList, initialValues]);

    useEffect(() => {
        if (!isMounted.current) return;
        setMotionOptions([
            DEFAULT_MOTION_OPTION,
            ...motionsList
                .filter(motion => motionTypes.includes(motion.motion_type))
                .map(motion => ({label: motion.motion_name, value: motion.id.toString()}))
        ]);
    }, [motionsList, motionTypes]);

    const updateMotionTypes = (templateId) => {
        if (isNaN(templateId)) setMotionTypes([]);
        else templatesList.forEach(template => {
            if (template.id === templateId) setMotionTypes(template.motion_types);
        });
    };

    const handleTemplateChange = (e) => {
        handleChange(e);
        const templateId = parseInt(e.target.value);
        updateMotionTypes(templateId);
        const motionId = parseInt(values.motion_reference);
        if (!isNaN(motionId)) {
            let resetMotion = true;
            motionsList.forEach(motion => {
                if (motion.id === motionId && motionTypes.includes(motion.motion_type)) resetMotion = false;
            });
            if (resetMotion) setFieldValue('motion_reference', '', false);
        }
        if (isNaN(templateId)) setFieldValue('params', [], false);
        else templatesList.forEach(template => {
            if (template.id === templateId) setFieldValue('params', template.params, false);
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="name" required disabled={isDisabled} />
            </Row>
            <Row>
                {isNew ?
                    <Select
                      label="Template" name="report_template_reference"
                      options={templateOptions}
                      onChange={handleTemplateChange}
                      required
                      disabled={isDisabled}
                    /> :
                    <Input label="Template" type="text" name="report_template_name" disabled />}
            </Row>
            <Row>
                {isNew ?
                    <Select
                      label="Motion" name="motion_reference"
                      options={motionOptions}
                      required
                      disabled={isDisabled}
                    /> :
                    <Input label="Motion" type="text" name="motion_name" disabled />}
            </Row>
            <Row>
                <MultiCheckboxes label="Parameters" name="params" options={parameterOptions} disabled />
            </Row>
            <HiddenInput name="id" />
            {isEditable && isDraft &&
                <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
