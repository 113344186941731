import ApiClient from './ApiClient';

const apiClient = ApiClient('/reports');

export const createReportTemplate = (payload, onSuccess, onError, onDone) => {
    apiClient.post('/', payload).then(onSuccess).catch(onError).finally(onDone);
};

export const updateReportTemplate = (reportTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.put(`/${reportTemplateId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const upsertReportTemplate = (payload, onSuccess, onError, onDone) => {
    const reportTemplateId = payload.id;
    delete payload.id;
    if (reportTemplateId) updateReportTemplate(reportTemplateId, payload, onSuccess, onError, onDone);
    else createReportTemplate(payload, onSuccess, onError, onDone);
};

export const patchReportTemplate = (reportTemplateId, payload, onSuccess, onError, onDone) => {
    apiClient.patch(`/${reportTemplateId}/`, payload).then(onSuccess).catch(onError).finally(onDone);
};

export const deleteReportTemplate = (reportTemplateId, onSuccess, onError, onDone) => {
    apiClient.delete(`/${reportTemplateId}/`).then(onSuccess).catch(onError).finally(onDone);
};

export const getReportTemplatesList = (status, onSuccess, onError, onDone) => {
    let url = '/';
    if (status) url += `?status=${status}`
    apiClient.get(url).then(onSuccess).catch(onError).finally(onDone);
};
