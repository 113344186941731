import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {updateCustomEvent} from 'api/CustomEventsApi';
import {useAppContext} from 'contexts/AppContext';
import {isAdmin} from 'utils/Auth';
import CustomEventForm from './CustomEventForm';

const DEFAULT_INSTANCE = {
    id: '',
    name: '',
    alert_type: '',
    alert_text: '',
    calendar_time_value: '',
    operation_time_value: '',
};

export default function CustomEventDetails({crane, customReport, maintenanceItem, customEvent, onCloseClick}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isCalendarTime = customReport.params.includes('calendar_time_in_operation');
    const isOperationTime = customReport.params.includes('operation_time');
    const isDraft = customReport.status === 1;
    const isEditable = isAdmin();

    let validationRules = {
        name: Yup.string().required('Name is required'),
        alert_text: Yup.string().required('Alert text is required'),
    };
    if (isCalendarTime) validationRules = {
        ...validationRules,
        calendar_time_value: Yup.number('It must be a number').positive().integer()
            .min(1, 'The percentage should be between 1 to 100')
            .max(100, 'The percentage should be between 1 to 100')
            .required('Calendar time percentage to trigger alert is required')
    }
    if (isOperationTime) validationRules = {
        ...validationRules,
        operation_time_value: Yup.number('It must be a number').positive().integer()
            .min(1, 'The percentage should be between 1 to 100')
            .max(100, 'The percentage should be between 1 to 100')
            .required('Operation time percentage to trigger alert is required')
    }
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = customEvent.hasOwnProperty(key) ? customEvent[key] : value;
                }
                customEvent.params.forEach(param => {
                    if (param.param === 'calendar_time_in_operation') newData.calendar_time_value = param.value;
                    else if (param.param === 'operation_time') newData.operation_time_value = param.value;
                });
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            name: values.name,
            alert_type: values.alert_type,
            alert_text: values.alert_text,
            params: [],
        };
        if (isCalendarTime) payload.params.push({
            param: 'calendar_time_in_operation',
            value: parseInt(values.calendar_time_value),
        });
        if (isOperationTime) payload.params.push({
            param: 'operation_time',
            value: parseInt(values.operation_time_value),
        });
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        updateCustomEvent(
            crane.id, customReport.id, maintenanceItem.id, customEvent.id,
            payload, handleSuccessResponse, onError, onDone
        );
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Report event has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <>
            <div>
                <span className="text-card-title">{customReport.name} ({customReport.motion.name}) / {maintenanceItem.name} / Report event</span>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
                {(props) => (
                    <CustomEventForm
                      {...props}
                      isCalendarTime={isCalendarTime}
                      isOperationTime={isOperationTime}
                      isDraft={isDraft}
                      isEditable={isEditable}
                      isLoading={isLoading}
                      onCloseClick={onCloseClick}
                    />
                )}
            </Formik>
        </>
    );
}
