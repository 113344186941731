import React, {useEffect, useRef} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'perfect-scrollbar';
import appRoutes from 'routes/appRoutes';
import {useAppContext} from 'contexts/AppContext';
import {getUserRole, isAuthenticated} from 'utils/Auth';
import logo from 'smart.png';

const backgroundColor = 'white';
const activeColor = 'primary';
const maintenanceAlertsCountStyle = {
    float: 'right',
    marginRight: '10px',
    marginTop: '10px',
    borderRadius: '10px',
    padding: '5px',
    minWidth: '30px',
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    backgroundColor: '#ffda23',
    color: '#084d93',
};
let ps;

export default function Sidebar() {
    const isMounted = useRef(false);
    const sidebar = useRef(null);
    const {menuItem, maintenanceAlertsCount} = useAppContext();
    const location = useLocation();
    const userRole = getUserRole();

    useEffect(() => {
        isMounted.current = true;
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(sidebar.current, {suppressScrollX: true, suppressScrollY: false});
        }
        return () => {
            isMounted.current = false;
            if (navigator.platform.indexOf('Win') > -1) ps.destroy();
        };
    }, []);

    const isActiveRoute = route => {
        return location.pathname.indexOf(route.path) > -1 || route.path === menuItem ? 'active' : '';
    }

    return (
        <div className="sidebar" data-color={backgroundColor} data-active-color={activeColor}>
            <div className="logo">
                <a href="/" className="simple-text logo-normal">
                    <div className="logo-img">
                        <img src={logo} alt="Smart Crane System" />
                    </div>
                </a>
            </div>
            <div className="sidebar-wrapper" ref={sidebar}>
                <Nav>
                    {appRoutes.map(route => {
                        if (route.showInMenu && route.roles.includes(userRole)) {
                            let counter;
                            if (route.name === 'Maintenance alerts' && maintenanceAlertsCount) counter = <p style={maintenanceAlertsCountStyle}>{maintenanceAlertsCount}</p>;
                            return (
                                <li key={route.path.slice(1)} className={isActiveRoute(route)}>
                                    {counter}
                                    <NavLink
                                      to={isAuthenticated() ? route.path : '/auth/login'}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                        <p><FontAwesomeIcon icon={route.icon} size="xl" /> {route.name}</p>
                                    </NavLink>
                                </li>
                            );
                        }
                    })}
                </Nav>
            </div>
        </div>
    )
};
