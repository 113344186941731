import React, {Children} from 'react';
import {Modal} from 'react-bootstrap';  // https://react-bootstrap-v4.netlify.app/components/modal/

export default function ModalDialog({title, body, children, ...props}) {
    const dialogHeader = title ? <Modal.Header closeButton><Modal.Title as="div">{title}</Modal.Title></Modal.Header> : null;
    const dialogBody = body ? <Modal.Body>{body}</Modal.Body> : null;
    return (
        <Modal {...props} animation={false}>
            {dialogHeader}
            {dialogBody}
            <Modal.Footer>{Children.map(children, child => <span>{child}</span>)}</Modal.Footer>
        </Modal>
    )
}
