import React, {useEffect, useRef, useState} from 'react';
import {Table, Spinner} from 'react-bootstrap';
import {vfdClasses} from 'constants/dataConstants';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';

export default function VfdsList({
    pageState, currentVersion, currentConfiguration, motions, motors, onDetailsClick, onAddButtonClick
}) {
    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const [vfds, setVfds] = useState([]);
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getVfds();
        return () => isMounted.current = false;
    }, [pageState, currentVersion, currentConfiguration]);

    const getVfds = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.crane_details)) {
                setVfds(currentConfiguration.crane_details.vfd_config);
            }
            setIsLoading(false);
        }
    };

    let addButton;
    if (isEditable) addButton = <button className="btn btn-add" onClick={onAddButtonClick}>Add</button>;

    let tableContent;
    if (isLoading) tableContent = <tr><td colSpan="8" className="text-center"><Spinner animation="border" /></td></tr>;
    else if (vfds.length === 0) tableContent = <tr><td colSpan="8">No VFDs found</td></tr>;
    else {
        tableContent = vfds.map(vfd => {
            let vfdMotors = vfd.vfd_motor_mapping ? vfd.vfd_motor_mapping.map(item => motors[item].name).join(', ') : '-';
            return (
                <tr key={vfd.id}>
                    <td>{vfd.vfd_name}</td>
                    <td>{motions[vfd.motion_reference].motion_name}</td>
                    <td>{vfdClasses[vfd.vfd_class]}</td>
                    <td className="text-center">{vfd.network ? vfd.network.address : '-'}</td>
                    <td className="text-center">{vfd.current_rated}</td>
                    <td>{vfdMotors}</td>
                    <td className="text-center">{vfd.active ? 'Yes' : 'No'}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onDetailsClick(vfd)}>{isEditable ? 'Edit' : 'View'}</span>
                    </td>
                </tr>
            )
        });
    }

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Motion</th>
                    <th>Class</th>
                    <th className="text-center">Address</th>
                    <th className="text-center">Current rated</th>
                    <th>Motors</th>
                    <th className="text-center">Active</th>
                    <th className="text-center">{addButton}</th>
                </tr>
            </thead>
            <tbody>{tableContent}</tbody>
        </Table>
    );
}
