import React, {useState} from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Form, Button, Navbar, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from "react-toastify"

import GetOtp from './GetOtp'

const ForgotPassword = (props) => {
    let history = useHistory()
    let emailObj= props.emailObj ? props.emailObj : ''
    const [state, setState] = useState({
        email : emailObj,
        flag: false,
        loginFlag: false,
        messageType: null,
        messageText: null
    })


    const style = {
        top: 'auto',
    }

    const setFlag = () =>{
        this.setState({ loginFlag: true })
    }

    const createMessage = (type, text) => {
        setState({
            ...state,
            messageType: type,
            messageText: text,
        })
        setTimeout(() => {
            removeMessage()
        }, 3000)
    }

    const createToast = (text) =>{
        toast.error(text, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true
        })
    }

    const removeMessage = () => {
        setState({
            ...state,
            messageType: null,
            messageText: null,
        })
    }

    return (
        <div>
            <Formik
              initialValues={{
                email: emailObj,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Enter a valid email Id')
                    .required('Email is required.')
              })}
              onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                      const url = `${window.REST_API_URL}/users/forgot/otp/`
                      axios.post(url, values)
                          .then(function (response) {
                                setSubmitting(true)
                                setState({
                                    email:values.email,
                                    flag:true
                                })

                          })
                          .catch(function (error) {
                              if(error.response){
                                setSubmitting(false)
                                createMessage('warning', 'Incorrect email Id. Kindly check')
                                createToast("Incorrect email Id. Kindly check")
                              }
                          })

                  }, 500)
              }}
            >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;
                return (
                    <div className='content'>
                        {state.flag ?
                            <GetOtp emailObj={state.email} />
                        :
                        <Form onSubmit={handleSubmit}>
                            <Alert variant={state.messageType}>
                                {state.messageText}
                            </Alert>
                            <Form.Group size='lg'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  name='email'
                                  id='input-email'
                                  className={
                                    errors.email && touched.email ? 'input error' : 'input'
                                  }
                                  type='email'
                                  placeholder='Enter your email ID'
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.email && touched.email && (
                                    <Form.Text className="text-muted text-danger">{errors.email}</Form.Text>
                                )}
                            </Form.Group>
                            <Button block size='lg' type='submit' disabled={isSubmitting}>
                                Request Password Reset
                            </Button>
                            <ToastContainer />
                        </Form>}
                    </div>
                )
            }}
            </Formik>
        </div>
    )
}

export default ForgotPassword
