import React from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';

export default function DocumentForm({
    dirty, isValid, isSubmitting, handleChange, handleSubmit,
    isEditable, isFileRequired, existingFileName, setSelectedFile
}) {
    const isDisabled = !isEditable || isSubmitting;

    const handleFileChange = e => {
        handleChange(e);
        setSelectedFile(e.target.files[0]);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Title" type="text" name="title" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Description" type="text" name="description" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input
                  label="File"
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  info="Supported file formats are &quot;doc&quot;, &quot;docx&quot;, &quot;pdf&quot;, &quot;txt&quot; and maximum file size is 100 MB"
                  required={isFileRequired}
                  disabled={isDisabled}
                />
            </Row>
            {existingFileName &&
                <Row>
                    <Form.Group as={Col} size="lg">
                        <Form.Label>Current file: {existingFileName}</Form.Label>
                    </Form.Group>
                </Row>}
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting}>Save</Button>}
        </Form>
    )
};
