import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isSystemAdmin} from 'utils/Auth';
import {loadCustomers, loadSites} from 'utils/Ui';
import LocationDetails from './LocationDetails';
import LocationsList from './LocationsList';

export default function Locations(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [location, setLocation] = useState({});
    const [customers, setCustomers] = useState({});
    const [sites, setSites] = useState({});
    const site = props.location.state && props.location.state.site;
    const useCustomer = isSystemAdmin();
    const useSite = isSystemAdmin() || isCustomerAdmin();

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/locations');
        setPageTitle('Locations');
        if (useCustomer) loadCustomers(setCustomers, handleApiError);
        if (useSite) loadSites(setSites, handleApiError);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setLocation({});
        setIsEditMode(true);
    };

    const handleEditClick = (location) => {
        setLocation(location);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <LocationDetails
          location={location}
          useCustomer={useCustomer}
          useSite={useSite}
          customers={customers}
          sites={sites}
          onCloseClick={handleCloseClick}
        />
    );
    return <LocationsList site={site} onAddClick={handleAddClick} onEditClick={handleEditClick} />;
}
