import React from 'react';
import {Table} from 'react-bootstrap';
import {convertSecondsToTimeString} from 'utils/Utils';

export default function OdometerTable({odometerData}) {

    const cmmaValue = odometerData.cmaa?.v?.current || '-';
    const multiplyResult = odometerData.list.map(row => row.run_time * row.motor_speed_avg).reduce((total, value) => total + value, 0);
    const runTimeSum = odometerData.list.reduce((total, row) => total + row.run_time, 0);
    const speedAvg = runTimeSum !== 0 ? (multiplyResult / runTimeSum).toFixed(2) : '';
    const loadCyclesSum = odometerData.list.reduce((total, row) => total + row.load_cycle_count, 0);

    return (
        <Table striped bordered hover size="sm" style={{marginTop: '20px'}}>
            <thead className="thead-centered">
                <tr>
                    <th>Load (% of motion rated capacity)</th>
                    <th>Operating time (HH:MM:SS)</th>
                    <th>Avg operating speed (RPM)</th>
                    <th>Load cycles</th>
                </tr>
            </thead>
            <tbody>
                {odometerData.list.map(row => (
                    <tr key={row.k}>
                        <td className="text-center">{row.k} ({row.desc})</td>
                        <td className="text-center">{convertSecondsToTimeString(row.run_time)}</td>
                        <td className="text-center">{row.motor_speed_avg.toFixed(2)}</td>
                        <td className="text-center">{row.load_cycle_count}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot className="tfoot-centered">
                <tr>
                    <td>CMAA Class: <b>{cmmaValue}</b></td>
                    <td>Total operating time: <b>{convertSecondsToTimeString(runTimeSum)}</b></td>
                    <td>Avg operating speed: <b>{speedAvg}</b></td>
                    <td>Total load cycles: <b>{loadCyclesSum}</b></td>
                </tr>
            </tfoot>
        </Table>
    );
};
