import React, {useEffect, useRef, useState} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {
    isCustomerAdmin,
    isLocationAdmin,
    isSiteAdmin,
    isSystemAdmin,
} from 'utils/Auth';
import {loadCraneSystems, loadCustomers, loadLocations, loadSites} from 'utils/Ui';
import UserDetails from './UserDetails';
import UsersList from './UsersList';

export default function Users(props) {
    const isMounted = useRef(false);
    const {setMenuItem, setPageTitle, handleApiError} = useAppContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = useState({});
    const [customers, setCustomers] = useState({});
    const [sites, setSites] = useState({});
    const [locations, setLocations] = useState({});
    const [craneSystems, setCraneSystems] = useState({});
    const useCustomer = isSystemAdmin();
    const useSite = isSystemAdmin() || isCustomerAdmin();
    const useLocation = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin();
    const useCraneSystem = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin() || isLocationAdmin();

    useEffect(() => {
        isMounted.current = true;
        setMenuItem('/app/users');
        setPageTitle('Users');
        if (useCustomer) loadCustomers(setCustomers, handleApiError);
        if (useSite) loadSites(setSites, handleApiError);
        if (useLocation) loadLocations(setLocations, handleApiError);
        if (useCraneSystem) loadCraneSystems(setCraneSystems, handleApiError);
        return () => isMounted.current = false;
    }, []);

    const handleAddClick = () => {
        setUser({});
        setIsEditMode(true);
    };

    const handleEditClick = (user) => {
        setUser(user);
        setIsEditMode(true);
    };

    const handleCloseClick = () => setIsEditMode(false);

    if (isEditMode) return (
        <UserDetails
          user={user}
          useCustomer={useCustomer}
          useSite={useSite}
          useLocation={useLocation}
          useCraneSystem={useCraneSystem}
          customers={customers}
          sites={sites}
          locations={locations}
          craneSystems={craneSystems}
          onCloseClick={handleCloseClick}
        />
    );
    return <UsersList onAddClick={handleAddClick} onEditClick={handleEditClick} />;
}
