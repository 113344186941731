import React, {useEffect, useRef, useState} from 'react';
import {arrayToObject, isEmpty} from 'utils/Utils';
import MotorDetails from './MotorDetails';
import MotorsList from './MotorsList';

export default function MotorsConfig({currentConfiguration, ...props}) {
    const isMounted = useRef(false);
    const [showDetails, setShowDetails] = useState(false);
    const [motor, setMotor] = useState({});
    const [vfds, setVfds] = useState({});

    useEffect(() => {
        isMounted.current = true;
        getVfds();
        return () => isMounted.current = false;
    }, [currentConfiguration]);

    const getVfds = () => {
        if (
            !isEmpty(currentConfiguration) &&
            !isEmpty(currentConfiguration.crane_details) &&
            !isEmpty(currentConfiguration.crane_details.vfd_config)
        ) setVfds(arrayToObject(currentConfiguration.crane_details.vfd_config));
        else setVfds({});
    };

    const handleAddButtonClick = () => {
        setMotor({});
        setShowDetails(true);
    };

    const handleDetailsClick = (motor) => {
        setMotor(motor);
        setShowDetails(true);
    };

    const handleCloseButtonClick = () => {
        setShowDetails(false);
    };

    if (showDetails) return (
        <MotorDetails
          {...props}
          vfds={vfds}
          motor={motor}
          onCloseButtonClick={handleCloseButtonClick}
        />
    );

    return (
        <MotorsList
          {...props}
          currentConfiguration={currentConfiguration}
          onDetailsClick={handleDetailsClick}
          onAddButtonClick={handleAddButtonClick}
        />
    );
}
