import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, OverlayTrigger, Spinner, Table, Tooltip} from 'react-bootstrap';
import {deleteReportTemplate, getReportTemplatesList, patchReportTemplate} from 'api/ReportTemplatesApi';
import ModalDialog from 'components/Dialogs/ModalDialog';
import {motionTypes, reportTemplateParameters, reportTemplateStatuses} from 'constants/dataConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';

let reportTemplateObject;

export default function ReportTemplatesList({onAddClick, onEditClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogBody, setDeleteDialogBody] = useState(<></>);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [reportTemplates, setReportTemplates] = useState([]);
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('Report templates');
        loadReportTemplatesList();
        return () => isMounted.current = false;
    }, []);

    const loadReportTemplatesList = () => {
        setIsLoading(true);
        const onSuccess = response => setReportTemplates(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getReportTemplatesList(null, onSuccess, handleApiError, onDone);
    };

    const handleStatusClick = (reportTemplate) => {
        const payload = {status: reportTemplate.status === 1 ? 2 : 1};
        const onSuccess = response => {
            setReportTemplates(oldData => oldData.map(item => item.id === response.data.id ? response.data : item));
        };
        patchReportTemplate(reportTemplate.id, payload, onSuccess, handleApiError);
    };

    const handleDeleteClick = (reportTemplate) => {
        reportTemplateObject = reportTemplate;
        setDeleteDialogBody(<p>Do you really want to delete the report template "<b>{reportTemplate.name}</b>"?</p>);
        setShowDeleteDialog(true);
    };

    const handleModalClosed = () => {
        setShowDeleteDialog(false);
    };

    const handleModalConfirmed = () => {
        setIsLoading(true);
        const onDone = () => isMounted.current && setIsLoading(false);
        deleteReportTemplate(reportTemplateObject.id, handleSuccessResponse, handleApiError, onDone);
        handleModalClosed();
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Report template has been successfully deleted');
        if (isMounted.current) loadReportTemplatesList();
    };

    let tableContent;
    if (isLoading) {
        tableContent = <tr><td colSpan={isEditable ? 7 : 6} className="text-center"><Spinner animation="border" /></td></tr>;
    }
    else if (reportTemplates.length) {
        tableContent = reportTemplates.map(reportTemplate => {
            const motions = reportTemplate.motion_types.map(item => motionTypes[item]);
            const parameters = reportTemplate.params.map(item => reportTemplateParameters[item]);
            const isDraft = reportTemplate.status === 1;
            const status = isEditable ? (
                <OverlayTrigger
                  key={`overlay-${reportTemplate.id}`}
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${reportTemplate.id}`}>Change to {isDraft ? 'published' : 'draft'}</Tooltip>}
                >
                    <span className="text-primary text-link" onClick={() => handleStatusClick(reportTemplate)}>
                        {reportTemplateStatuses[reportTemplate.status]}
                    </span>
                </OverlayTrigger>
            ) : reportTemplateStatuses[reportTemplate.status];
            return (
                <tr key={reportTemplate.id}>
                    <td>{reportTemplate.name}</td>
                    <td>{motions.join(', ')}</td>
                    <td>{parameters.join(', ')}</td>
                    <td className="text-center" style={{fontWeight: 'bold'}}>
                        <Link to={{pathname: '/app/maintenance-item-templates', state: {reportTemplate: reportTemplate}}}>
                            {reportTemplate.maintenance_item_templates_count}
                        </Link>
                    </td>
                    <td className="text-center">{status}</td>
                    <td className="text-center">
                        <span className="text-primary text-link" onClick={() => onEditClick(reportTemplate)}>{isDraft && isEditable ? 'Edit' : 'View'}</span>
                    </td>
                    {isEditable &&
                        <td className="text-center">
                            <span className="text-primary text-link" onClick={() => handleDeleteClick(reportTemplate)}>Delete</span>
                        </td>}
                </tr>
            );
        });
    }
    else {
        tableContent = <tr><td colSpan={isEditable ? 7 : 6}>No report templates found</td></tr>;
    }

    return (
        <>
            <ModalDialog title="Deleting report template" body={deleteDialogBody} show={showDeleteDialog} onHide={handleModalClosed} size="lg">
                <Button variant="secondary" onClick={handleModalClosed}>No</Button>
                <Button variant="danger" onClick={handleModalConfirmed}>Yes</Button>
            </ModalDialog>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Component type</th>
                        <th>Parameters</th>
                        <th className="text-center">Maintenance item templates</th>
                        <th className="text-center">Status</th>
                        <th colSpan={isEditable ? 2 : 1} className="text-center">
                            {isEditable && <button className="btn btn-add" onClick={onAddClick}>Add</button>}
                        </th>
                    </tr>
                </thead>
                <tbody>{tableContent}</tbody>
            </Table>
        </>
    );
}
